import { Component, Injector, OnInit } from '@angular/core';

import { UserInfo } from '../common/userinfo.data';
import { JWTTokenService } from 'src/app/services/jwtToken.services';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { ProcurementPlanWorkflowService } from 'src/app/services/dataServices/procurement-plan-workflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthenticationService as auth } from 'src/app/services/authenticationService/authentication.service';
import { LoggedInUserData } from 'src/app/components/models/LoggedInUserData';
import { EmailLinkServiceService } from 'src/app/services/emailLinkService/email-link-service.service';

@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
})
export class LoginTestComponent implements OnInit {
  loggenUserInfo = new LoggedInUserData();
  user = '';
  usersData: any;
  router: any;
  _service: any;
  _procservice: any;
  _authservice: any;
  route: any;
  _jwtToken: any;
  authenticationService: any;
  emailLinkService: any;
  KeyVault: any;
  constructor(private injector: Injector) {
    this.router = injector.get<Router>(Router);
    this._service = injector.get<ProcurementService>(ProcurementService);
    this._procservice = injector.get<ProcurementPlanWorkflowService>(
      ProcurementPlanWorkflowService
    );
    this._authservice = injector.get<AuthenticationService>(
      AuthenticationService
    );
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
    this._jwtToken = injector.get<JWTTokenService>(JWTTokenService);
    this.authenticationService = injector.get<auth>(auth);
    this.emailLinkService = injector.get<EmailLinkServiceService>(
      EmailLinkServiceService
    );
  }

  ngOnInit(): void {
    this.authenticationService.resetUserObj();
    this.loadData();
  }
  loadData() {
    this._service.getUsers().subscribe((response: any) => {
      if (!response['IsError']) {
        this.usersData = response.response;
      } else {
        this._procservice.displayToastr(response);
      }
    });
  }
  loginbtn() {
    this._procservice
      .getUserDetailsById(this.user)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.usersData = response[0];
          if (this.usersData) {
            localStorage.setItem('loginUser', this.usersData.FirstName);
            localStorage.setItem('loginuseremail', this.usersData.Email);
            localStorage.setItem('UserToken', this.usersData);
            localStorage.setItem('userId', this.usersData.UserId);
            localStorage.setItem('BASFUserId', this.usersData.BASFUserId);
            localStorage.setItem('FirstName', this.usersData.FirstName);
            localStorage.setItem('LastName', this.usersData.LastName);
            localStorage.setItem('loginuseremail', this.usersData.Email);
            localStorage.setItem('isAdmin', this.usersData.IsAdmin);
            localStorage.setItem('loginId', this.usersData.BASFUserId);
            localStorage.setItem('isPPM', this.usersData.IsPPM);
            let data: UserInfo = new UserInfo();
            data.LoginName = this.usersData.BASFUserId;
            data.Email = this.usersData.Email;
            data.FirstName = this.usersData.FirstName;
            data.LastName = this.usersData.LastName;
            this.authenticationService.setUserObject();
            this._jwtToken.RetrieveJWtToken(data).subscribe({
              next: (result: any) => {
                localStorage.setItem('TokenInfo', JSON.stringify(result));
                if (this.emailLinkService.checkEmailLInkURL() != '') {
                  this.router.navigateByUrl(
                    this.emailLinkService
                      .checkEmailLInkURL()
                      .split('emaillink')[1]
                  );
                  this.emailLinkService.resetEmailLinkURL();
                } else {
                  this.router.navigate(['/home']);
                }
              },
              error: (error: any) => {
                this.router.navigate(['/login']);
                console.error(error);
              },
            });

          } else {
            this.router.navigate(['error']);
          }
        } else {
          this._procservice.displayToastr(response);
        }
      });
  }

}
