import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserRegistrationComponent implements OnChanges {
  @Input() formData: any;
  @Input() formType: any;
  @Output() reLoadGrid = new EventEmitter();

  userRegistrationForm = new UntypedFormGroup({
    basfUserId: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    lastName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    isAdmin: new UntypedFormControl(false),
    isPpm: new UntypedFormControl(false),
  });

  apiRequest = {
    CountryId: 0,
    LoginName: '',
    FirstName: '',
    LastName: '',
    Email: '',
    IsActive: true,
    CreatedBy: '',
    ModifiedBy: '',
    Id: 0,
    IsAdmin: false,
    IsPPM: false,
    country: [],
    CountryName: '',
  };
  country = [
    { id: 1, value: 'US' },
    { id: 2, value: 'Canada' },
  ];
  selectedCountry: any;

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {}
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnChanges(): void {
    if (this.formType !== 'New') {
      this.selectedCountry = [];
      if (this.formData['CountryName'] != null) {
        let tBUnits = this.formData['CountryName'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.country.find((c) => c.value == t.trim());
          if (fBUnit) {
            this.selectedCountry.push(fBUnit);
          }
        });
        this.userRegistrationForm.controls['country'].setValue(
          this.selectedCountry
        );
      }
      this.userRegistrationForm.controls['basfUserId'].setValue(
        this.formData.LoginName
      );
      this.userRegistrationForm.controls['firstName'].setValue(
        this.formData.FirstName
      );
      this.userRegistrationForm.controls['lastName'].setValue(
        this.formData.LastName
      );
      this.userRegistrationForm.controls['emailAddress'].setValue(
        this.formData.Email
      );
      this.userRegistrationForm.controls['isAdmin'].setValue(
        this.formData.IsAdmin
      );
      this.userRegistrationForm.controls['isPpm'].setValue(this.formData.IsPPM);
    }
  }

  submitUser() {
    if (this.userRegistrationForm.invalid) {
      this.toastr.error('Please fill all the fields');
      return false;
    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['CreatedBy'] = localStorage.getItem('UserId') || '';
    } else {
      this.apiRequest['CreatedBy'] = this.formData.created_by;
      this.apiRequest['Id'] = this.formData.Id;
    }
    this.apiRequest['LoginName'] = this.userRegistrationForm.value.basfUserId;
    this.apiRequest['FirstName'] = this.userRegistrationForm.value.firstName;
    this.apiRequest['LastName'] = this.userRegistrationForm.value.lastName;
    this.apiRequest['Email'] = this.userRegistrationForm.value.emailAddress;
    this.apiRequest['ModifiedBy'] = localStorage.getItem('UserId') || '';
    this.apiRequest['CountryId'] = 0;
    this.apiRequest['CountryName'] = '';
    this.apiRequest['country'] = this.userRegistrationForm
      .get('country')
      ?.value?.map((a: any) => {
        return {
          Id: a.id,
          UserId: parseInt(this.authenticationService.userObj.userId),
          CountryId: a.id,
          IsActive: true,
        };
      });
    this.apiRequest['IsAdmin'] = this.userRegistrationForm.value.isAdmin;
    this.apiRequest['IsActive'] = true;
    this.apiRequest['IsPPM'] = this.userRegistrationForm.value.isPpm;

    this._service.postUser(this.apiRequest).subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.toastr.success('User added successfully');
        this.reLoadGrid.emit(true);
      }
    });
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
