import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { UserService } from 'src/app/services/dataServices/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  homePageLinks: any = [];
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.homePageLinks['Create New Procurement Plan'] = true;
    this.homePageLinks['View Dashboards'] = true;
    this.homePageLinks['View and Edit Package'] = true;
    this.homePageLinks['View and Edit Existing Procuremnt'] = true;
  }

  ngOnInit(): void {
    if (this.authenticationService.userObj.userId > 0) {
      this.userService
        .MenuDetailsByUserId(this.authenticationService.userObj.userId)
        .subscribe((_data: any) => {
          this.homePageLinks = [];
          let tempHomePageLinks = _data.response.homePageLinks;
          tempHomePageLinks.map((item: any) => {
            this.homePageLinks[item.SectionName] = item.IsView;
          });
        });
    }
  }
}
