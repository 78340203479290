<div id="dynamicGrid">
  <p-table
    #dt
    [columns]="orgCols"
    [value]="gridData"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 15, 20]"
    [autoLayout]="true"
    [dataKey]="dataKey"
    responsiveLayout="scroll"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    columnResizeMode="expand"
    [(selection)]="selectedRows"
    (onRowSelect)="checkSelectedRow($event)"
    (onRowUnselect)="checkSelectedRow($event)"
    styleClass="p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          [style.display]="!col.is_show ? 'none' : 'table-column'"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          id="checkbox_1"
          *ngIf="checkboxShow"
          style="width: 3em"
          class="checkbox-column"
        >
          <p-tableHeaderCheckbox
            (click)="checkSelectedRow($event)"
          ></p-tableHeaderCheckbox>
        </th>
        <th
          [pSortableColumn]="col.field"
          [title]="col.header"
          *ngFor="let col of columns"
          [hidden]="!col.is_show"
          style="min-width: 150px"
          pResizableColumn
          pReorderableColumn
          id="{{ col.field }}"
        >
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th
          id="edit_1"
          *ngIf="
            showEdit ||
            showClone ||
            showView ||
            showDelete ||
            showActive ||
            showEmail ||
            showReview ||
            adminAction
          "
          style="width: 4em"
          class="edit-column"
        >
          <span *ngIf="showActive">Status</span>
        </th>
      </tr>
      <tr *ngIf="showFilter">
        <th id="checkbox2" *ngIf="checkboxShow"></th>
        <th
          *ngFor="let col of columns; let i = index"
          id="col.field"
          [hidden]="!col.is_show"
        >
          <input
            *ngIf="!checkDateField(col.field)"
            type="text"
            class="form-control"
            (input)="
              dt.filter($any($event.target).value, col.field, 'contains');
              sendFilterData($any($event.target).value, col.field)
            "
          />
          <input
            *ngIf="checkDateField(col.field)"
            type="date"
            class="form-control form-control-sm"
            name="filterDate"
            (change)="buildFilter($any($event), col.field)"
            style="border-color: black"
          />
        </th>
        <th
          id="edit_3"
          *ngIf="
            showEdit ||
            showClone ||
            showView ||
            showDelete ||
            showActive ||
            showEmail ||
            showReview ||
            adminAction
          "
          style="width: 4em"
          class="edit-column"
        ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td
          *ngIf="checkboxShow"
          class="checkbox-column"
          style="background-color: white"
        >
          <p-tableCheckbox
            id="{{ 'trcheckbox_' + rowData.Id }}"
            [value]="rowData"
          ></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" [hidden]="!col.is_show">
          <p style="overflow-wrap: break-word; text-align: left">
            <a
              *ngIf="col.isHyperlink"
              style="color: #0366d6; cursor: pointer; text-decoration: none"
              (click)="onSelectRow(rowData, 'hyperlink')"
              >{{ rowData[col.field] }}</a
            >
            <span *ngIf="!col.isHyperlink">{{ rowData[col.field] }}</span>
          </p>
        </td>
        <td
          *ngIf="
            showEdit ||
            showClone ||
            showView ||
            showDelete ||
            showEmail ||
            showActive ||
            showReview ||
            adminAction
          "
          style="text-align: center; background-color: white"
          class="edit-column"
        >
          <div style="display: flex">
            <img
              alt="view"
              *ngIf="
                showView &&
                (rowData.IsView == undefined ? true : rowData.IsView)
              "
              class="accordion-img-icon pointer-cursor"
              title="View"
              (click)="onSelectRow(rowData, 'view')"
              src="./assets/images/icons/visibility.png"
            />
            <img
              *ngIf="
                showEdit &&
                (rowData.IsEdit == undefined ? true : rowData.IsEdit)
              "
              alt="edit"
              class="accordion-img-icon pointer-cursor"
              title="Edit"
              (click)="onSelectRow(rowData, 'edit')"
              src="./assets/images/icons/edit_icon.png"
            />
            <img
              alt="clone"
              *ngIf="showClone"
              class="accordion-img-icon pointer-cursor"
              title="Clone"
              (click)="onSelectRow(rowData, 'clone')"
              src="./assets/images/icons/clone_icon.png"
            />
            <img
              alt="delete"
              *ngIf="showDelete"
              class="accordion-img-icon pointer-cursor"
              title="Delete"
              width="30px"
              height="30px"
              (click)="onDeleteRow(rowData, 'edit')"
              src="./assets/images/icons/delete.png"
            />
            <img
              *ngIf="
                adminAction &&
                bu === 'EM' &&
                rowData['sbu_name'] === '' &&
                !isDistributor
              "
              alt="review"
              class="accordion-img-icon pointer-cursor"
              title="Review"
              (click)="onSelectRow(rowData, 'edit')"
              src="./assets/images/icons/edit_icon.png"
            />
            <img
              *ngIf="
                showReview &&
                bu === 'PM' &&
                rowData['status_id'] === 6 &&
                rowData['sales_rep_email'] === accountManager
              "
              alt="review"
              class="accordion-img-icon pointer-cursor"
              title="Review"
              (click)="onSelectRow(rowData, 'edit')"
              src="./assets/images/icons/edit_icon.png"
            />
            <img
              alt="email"
              *ngIf="showEmail && rowData['isExpireReminder'] && !isDistributor"
              class="accordion-img-icon pointer-cursor"
              title="Email"
              width="30px"
              height="30px"
              (click)="onSelectRow(rowData, 'email')"
              src="./assets/images/icons/email.png"
              style="opacity: 60%"
            />
            <div *ngIf="showActive">
              <p-inputSwitch
                [(ngModel)]="rowData['Is_active']"
                (onChange)="onSelectRow(rowData, 'status')"
              >
              </p-inputSwitch>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
