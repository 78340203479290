import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-contract-requirement',
  templateUrl: './contract-requirement.component.html',
  styleUrls: ['./contract-requirement.component.css'],
})
export class ContractRequirementComponent implements OnInit {
  cols: any;
  contractData: any;
  selectedRow: any;
  formType: any;
  showForm = false;

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.generateHeaders();
    this.loadData(0);
  }
  generateHeaders() {
    this.cols = [
      {
        header_id: 1,
        field: 'ContractName',
        header: 'Contract Name',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
    ];
  }

  loadData(contractId: any) {
    this.spinner.show();
    this._service
      .RetriveContactdataDetails(contractId)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.contractData = response.response;
        }
      });
  }

  onSelectRow(rowObject: any) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    this.contractName = this.selectedRow.ContractName;
    this.contractId = this.selectedRow.Id;
    this.formType = 'Edit';
    this.showForm = true;
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  addNewContract() {
    this.selectedRow = {
      ContractName: '',
    };
    this.formType = 'New';
    this.showForm = true;
  }
  contractName = '';
  contractId = 0;
  refreshContract(eventvalue: any) {
    if (eventvalue) {
      this.loadData(0);
    }
    this.showForm = false;
  }
  submitUser() {
    let ApiRequest = {};
    if (this.formType == 'New') {
      ApiRequest = {
        Id: 0,
        ContractName: this.contractName,
        Created_by: this.auenticationService.userObj.userId.toString(),
      };
    } else {
      let contract = this.contractData.filter((item: any) => {
        return item.Id == this.contractId;
      });
      ApiRequest = {
        Id: contract[0].Id,
        ContractName: this.contractName,
        Created_by: this.auenticationService.userObj.userId.toString(),
      };
    }

    this._service
      .InsertContactdataDetails(ApiRequest)
      .subscribe((response: any) => {
        if (!response['IsError']) {
          this.toastr.success('Contract added successfully');
          this.loadData(0);
        }
      });
    return true;
  }

  cancel() {
    this.loadData(0);
    this.showForm = false;
    this.contractName = '';
    this.contractId = 0;
  }
}
