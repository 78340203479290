import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryBuyerService {
  constructor(private _http: HttpClient) { }

  RetrieveCategorypackageDetailsById(planId: any): Observable<any> {
    const headers = new HttpHeaders().set('PackageId', planId.toString());
    return this._http
      .get(`${environment.RetrieveCategorypackageDetailsById}?PackageId=${planId}`,{headers:headers})
      .pipe(map((response) => response));
  }
}
