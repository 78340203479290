<form id="comments" class="form" #commentForm="ngForm">
  <div class="row">
    <div class="offset-md-1 col-md-10">
      <label style="padding: 10px 0px" for="external_comments"
        >What is wrong in the procurement plan</label
      >
      <textarea
        class="form-control"
        id="external_comments"
        rows="3"
        name="inaccurateComments"
        style="border-color: black"
        [(ngModel)]="comments"
      ></textarea>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center" style="padding: 10px 0px">
    <div class="p-2">
      <p-button
        [disabled]="commentForm.invalid == true"
        (click)="commentForm.invalid ? toastrAlert() : ''"
        type="submit"
        styleClass="primeng-button Save"
        label="Send Email"
        (click)="submit()"
      ></p-button>
    </div>
  </div>
</form>
