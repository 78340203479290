import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryManagementServices {
  constructor(private _http: HttpClient) {}
  RetrieveCategoryMaster(loginId: string) {
    let link: string = environment.RetrieveCategoryMaster.replace(
      '{loginName}',
      loginId
    );
    return this._http.get(link).pipe(map((response) => response));
  }

  RetrieveCategoryMasterForPurchasingGroup(
    loginId: string,
    purchasingGroupId: number
  ) {
    let link: string =
      environment.RetrieveCategoryMasterForPurchasingGroup.replace(
        '{loginName}',
        loginId
      ).replace('{purchasingGroupId}', purchasingGroupId.toString());

    return this._http.get(link).pipe(map((response) => response));
  }

  RetrieveCategory(loginId: string, categoryId: number) {
    let link: string = environment.RetrieveCategory.replace(
      '{loginName}',
      loginId
    ).replace('{categoryId}', categoryId.toString());

    return this._http.get(link).pipe(map((response) => response));
  }
  SaveCategory(data: any) {
    let link: string = environment.SaveCategory;
    return this._http.post(link, data).pipe(map((response) => response));
  }
  RetrieveCategoryCollection(loginId: string) {
    let link: string = environment.RetrieveCategoryCollection.replace(
      '{loginName}',
      loginId
    );
    return this._http.get(link).pipe(map((response) => response));
  }
}
