<div id="planner-component">
  <form (ngSubmit)="onSave()" #f="ngForm">
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="requiredOnsiteDate"
          >Required Onsite Date<sup
            ><span style="color: red">*</span></sup
          ></label
        >
      </div>
      <div class="col-md-6">
        <input
          [required]="true"
          [(ngModel)]="required_onsite_date"
          name="required_onsite_date"
          class="form-input"
          pInputText
          type="date"
          (input)="setPlannerDates()"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="estimatedFirstDrawingReceiptAro"
          >Estimated First Drawing Receipt ARO<sup
            ><span style="color: red">*</span></sup
          ></label
        >
      </div>
      <div class="col-md-6">
        <input
          [required]="true"
          type="number"
          [(ngModel)]="estimated_first_drawing_receipt_aro"
          name="estimated_first_drawing_receipt_aro"
          class="form-input"
          pInputText
          placeholder="Enter Weeks"
          (input)="setPlannerDates()"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="estimatedBasfApproval"
          >Estimated BASF Approval<sup
            ><span style="color: red">*</span></sup
          ></label
        >
      </div>
      <div class="col-md-6">
        <input
          [required]="true"
          type="number"
          [(ngModel)]="estimated_basf_approval"
          name="estimated_basf_approval"
          class="form-input"
          pInputText
          placeholder="Enter Weeks"
          (input)="setPlannerDates()"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="estimatedFabricationTimeArad"
          >Estimated Fabrication Time ARAD<sup
            ><span style="color: red">*</span></sup
          ></label
        >
      </div>
      <div class="col-md-6">
        <input
          [required]="true"
          type="number"
          [(ngModel)]="estimated_fabrication_time_arad"
          name="estimated_fabrication_time_arad"
          class="form-input"
          pInputText
          placeholder="Enter Weeks"
          (input)="setPlannerDates()"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="poNeedByDate"
          >PO Need by Date<sup><span style="color: red">*</span></sup></label
        >
      </div>
      <div class="col-md-6">
        <input
          [disabled]="true"
          [required]="true"
          [(ngModel)]="po_need_by_date"
          name="po_need_by_date"
          class="form-input"
          pInputText
          type="date"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="rfqNeedByDate"
          >RFQ Need by Date<sup><span style="color: red">*</span></sup></label
        >
      </div>
      <div class="col-md-6">
        <input
          [disabled]="true"
          [required]="true"
          [(ngModel)]="rfq_need_by_date"
          name="rfq_need_by_date"
          class="form-input"
          pInputText
          type="date"
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="plannedDeliveryDate"
          >Planned Delivery Date<sup
            ><span style="color: red">*</span></sup
          ></label
        >
      </div>
      <div class="col-md-6">
        <input
          [required]="true"
          [(ngModel)]="planned_delivery_date"
          name="planned_delivery_date"
          class="form-input"
          pInputText
          type="date"
        />
      </div>
    </div>

    <div class="row" style="text-align: center">
      <p-button
        type="submit"
        styleClass="primeng-button accept"
        label="Save"
        (click)="f.invalid ? toastrAlert() : ''"
        [disabled]="f.invalid == true"
      ></p-button>
    </div>
  </form>
</div>
