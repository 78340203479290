<div id="create_procurement" class="container resetFormStyles">
  <div class="row" *ngIf="!editable">
    <div class="w-100">
      <div>
        <h4>Create New Procurement Plan</h4>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="{ editable: editable }">
    <p-tabView id="adminPanel" [(activeIndex)]="activeIndex">
      <p-tabPanel header="Procurement">
        <form
          class="form"
          (ngSubmit)="saveProcurement(f)"
          class="form"
          #f="ngForm"
        >
          <div class="row">
            <div class="w-100">
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Is This a Large Capital Project (>$25M)?<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div
                    class="form-field"
                    style="width: 120px; justify-content: space-between"
                  >
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        [required]="true"
                        name="isCapitalProject"
                        value="yes"
                        [(ngModel)]="isCapitalProject"
                      ></p-radioButton>
                      <label for="isCapitalProject">Yes</label>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        name="isCapitalProject"
                        value="no"
                        [(ngModel)]="isCapitalProject"
                      ></p-radioButton>
                      <label for="isCapitalProject">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Status<sup><span style="color: red">*</span></sup></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div
                    class="form-field"
                    style="width: 163px; justify-content: space-between"
                  >
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        [required]="true"
                        name="status"
                        value="active"
                        [(ngModel)]="status"
                        inputId="status"
                      ></p-radioButton>
                      <label for="status">Active</label>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        name="status"
                        value="complete"
                        [(ngModel)]="status"
                        inputId="status"
                      >
                      </p-radioButton>
                      <label for="status">Complete</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Project Number<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <input
                      [required]="true"
                      name="project_number"
                      [(ngModel)]="procurement.project_number"
                      type="text"
                      pInputText
                      class="p-inputtext-sm prime-ng-inputbox primeng-input"
                      placeholder=""
                      appOnlyaplhanumeric
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Plant Code<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-dropdown
                      [required]="true"
                      name="plant_code"
                      [(ngModel)]="procurement.plant_code.Id"
                      styleClass="primeng-dropdown"
                      class="p-dropdown-sm"
                      [options]="PlantDetails"
                      optionLabel="Name"
                      optionValue="Id"
                      placeholder="Select Plantcode"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Project Name<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <input
                      [required]="true"
                      name="project_name"
                      [(ngModel)]="procurement.project_name"
                      type="text"
                      pInputText
                      class="p-inputtext-sm prime-ng-inputbox primeng-input"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >TIC ($)<sup><span style="color: red">*</span></sup>
                    </label>
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-inputNumber
                      [required]="true"
                      name="tic"
                      [(ngModel)]="procurement.tic"
                      type="text"
                      pInputText
                      class="p-inputtext-sm prime-ng-inputbox primeng-inputnumber"
                      placeholder=""
                      inputId="locale-us"
                      mode="decimal"
                      locale="en-US"
                      [minFractionDigits]="2"
                    >
                    </p-inputNumber>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Project Manager<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-dropdown
                      [required]="true"
                      name="project_manager"
                      [(ngModel)]="procurement.project_manager.Id"
                      styleClass="primeng-dropdown"
                      class="p-dropdown-sm"
                      [options]="UserList"
                      optionLabel="FirstName"
                      optionValue="Id"
                      placeholder="Select Project Manager"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Enigneering Partner Project Manager<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-dropdown
                            [required]="
                              engg_partner_project_manager_checkbox == true
                                ? false
                                : true
                            "
                            name="engg_partner_project_manager"
                            [(ngModel)]="
                              procurement.engg_partner_project_manager.Id
                            "
                            styleClass="primeng-dropdown"
                            class="p-dropdown-sm"
                            [options]="UserList"
                            optionLabel="FirstName"
                            optionValue="Id"
                            placeholder="Select Engineering partner"
                            [disabled]="
                              engg_partner_project_manager_checkbox == true
                            "
                          ></p-dropdown>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="engg_partner_project_manager_checkbox"
                          [(ngModel)]="engg_partner_project_manager_checkbox"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="
                            procurement.engg_partner_project_manager.Id != null
                          "
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Man Rep<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-dropdown
                      [required]="true"
                      name="man_rep"
                      [(ngModel)]="procurement.man_rep.Id"
                      styleClass="primeng-dropdown"
                      class="p-dropdown-sm"
                      [options]="UserList"
                      optionLabel="FirstName"
                      optionValue="Id"
                      placeholder="Select Man Rep"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >PPM<sup><span style="color: red">*</span></sup></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-dropdown
                      [required]="true"
                      name="ppm"
                      [(ngModel)]="procurement.ppm.Id"
                      styleClass="primeng-dropdown"
                      class="p-dropdown-sm"
                      [options]="UserList"
                      optionLabel="FirstName"
                      optionValue="Id"
                      placeholder="Select PPM"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >Planner/Scheduler<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <p-dropdown
                      [required]="true"
                      name="planner_or_scheduler"
                      [(ngModel)]="procurement.planner_or_scheduler.Id"
                      styleClass="primeng-dropdown"
                      class="p-dropdown-sm"
                      [options]="UserList"
                      optionLabel="FirstName"
                      optionValue="Id"
                      placeholder="Select Planner/Scheduler"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 26px 0px 8px 0px">
                <div class="col-5" style="width: 352px; padding-right: 0px">
                  <div class="form-label" style="text-align: left">
                    <label
                      for="inputPassword"
                      class="w-20 col-form-label"
                      style="font-size: 14px; color: black"
                      >Procurement Plan Package Category Discipline Leads</label
                    >
                  </div>
                </div>
                <div class="col-7" style="padding-left: 0px">
                  <div class="form-field">
                    <label
                      style="
                        font-size: 10px;
                        color: black;
                        font-weight: 700;
                        font-style: italic;
                      "
                      >(Can add more than one lead per category and can also
                      enter manual emails for non-BASF people.)</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >1000's Equipment<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              eqipment_checkbox_1000 == true ? false : true
                            "
                            name="equipment_1000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="equipment_1000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="eqipment_checkbox_1000 == true"
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="eqipment_checkbox_1000"
                          [(ngModel)]="eqipment_checkbox_1000"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="equipment_1000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >2000's Electrical<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              euipment_checkbox_2000 == true ? false : true
                            "
                            name="equipment_2000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="equipment_2000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="euipment_checkbox_2000 == true"
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="euipment_checkbox_2000"
                          [(ngModel)]="euipment_checkbox_2000"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="equipment_2000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >3000's Process Control & Instrumentation<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              process_instrument_checkbox_3000 == true
                                ? false
                                : true
                            "
                            name="instrument_3000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="instrument_3000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="
                              process_instrument_checkbox_3000 == true
                            "
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="process_instrument_checkbox_3000"
                          [(ngModel)]="process_instrument_checkbox_3000"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="instrument_3000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >4000's Piping<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              piping_checkbox_4000 == true ? false : true
                            "
                            name="piping_4000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="piping_4000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="piping_checkbox_4000 == true"
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="piping_checkbox_4000"
                          [(ngModel)]="piping_checkbox_4000"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="piping_4000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >5000's Structural Steel<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              structural_steel_checkbox_5000 == true
                                ? false
                                : true
                            "
                            name="steel_5000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="steel_5000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="structural_steel_checkbox_5000 == true"
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="structural_steel_checkbox_5000"
                          [(ngModel)]="structural_steel_checkbox_5000"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="steel_5000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-50">
                  <div class="form-label">
                    <label for="inputPassword" class="w-20 col-form-label"
                      >6000's Services<sup
                        ><span style="color: red">*</span></sup
                      ></label
                    >
                  </div>
                </div>
                <div class="w-50">
                  <div class="row">
                    <div class="flex-div">
                      <div class="w-33">
                        <div class="form-field">
                          <p-multiSelect
                            [required]="
                              checkbox_6000_services == true ? false : true
                            "
                            name="service_6000"
                            placeholder="Select Users"
                            [options]="UserList"
                            [(ngModel)]="service_6000"
                            styleClass="primeng-dropdown"
                            optionLabel="FirstName"
                            optionValue="Id"
                            [disabled]="checkbox_6000_services == true"
                          ></p-multiSelect>
                        </div>
                      </div>
                      <div class="w-33 form-field">
                        <p-checkbox
                          name="checkbox_6000_services"
                          [(ngModel)]="checkbox_6000_services"
                          styleClass="primeng-checkbox"
                          [binary]="true"
                          inputId="binary"
                          [disabled]="service_6000.length > 0"
                        ></p-checkbox>
                      </div>
                      <div class="w-33 form-field">
                        <label for="binary">n/a</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row button-group">
                <div class="w-25">
                  <div class="button-note">
                    <span style="color: red">*</span
                    ><span style="font-style: italic"
                      >Fields are mandatory</span
                    >
                  </div>
                </div>
                <div class="w-75">
                  <div class="flex-div">
                    <p-button
                      styleClass="primeng-button cancel"
                      label="Cancel"
                      [routerLink]="['/procurementgrid']"
                    ></p-button>
                    <p-button
                      type="submit"
                      styleClass="primeng-button accept"
                      [label]="editable ? 'Submit' : 'Next'"
                      [disabled]="f.invalid == true"
                      (click)="f.invalid ? toastrAlert() : ''"
                    ></p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-tabPanel>
      <p-tabPanel header="Approvers" [disabled]="true" *ngIf="!editable">
        <div>
          <app-approval-strategy
            [ProcurementPlanId]="ProcurementPlanId"
          ></app-approval-strategy>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
