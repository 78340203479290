import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  islogin: boolean = false;
  logout: any;
  constructor() { }

  ngOnInit(): void {
    if (sessionStorage.getItem("logout")) {
      this.logout = JSON.parse(sessionStorage.getItem("logout") + '');
    }
  }
}
