<div id="approval_strategy">
  <form
    class="form"
    (ngSubmit)="saveApproval(f, $event)"
    class="form"
    #f="ngForm"
  >
    <div class="row">
      <div class="w-50">
        <div class="form-label">
          <label for="inputPassword" class="w-20 col-form-label"
            >Approval Type<sup><span style="color: red">*</span></sup></label
          >
        </div>
      </div>
      <div class="w-50">
        <div
          class="form-field"
          style="width: 184px; justify-content: space-between"
        >
          <div class="p-field-radiobutton p-label-active">
            <p-radioButton
              [required]="true"
              name="approval_type"
              value="1"
              [(ngModel)]="approval_type"
            ></p-radioButton>
            <label for="approval_type">Sequential</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="approval_type"
              value="2"
              [(ngModel)]="approval_type"
            ></p-radioButton>
            <label for="approval_type">Parallel</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let approver of approversArray; let i = index">
      <div class="w-50">
        <div class="form-label">
          <label for="inputPassword" class="w-20 col-form-label"
            >Approval {{ approver.id
            }}<sup *ngIf="i == 0"><span style="color: red">*</span></sup></label
          >
        </div>
      </div>
      <div class="w-50">
        <div class="form-field">
          <p-dropdown
            [options]="approversListArray"
            [(ngModel)]="approve['approval' + approver.id]"
            [name]="'approval' + approver.id"
            placeholder="Select Approver"
            [required]="i == 0 && packageBudgetValue > 30000"
            styleClass="primeng-dropdown"
            class="p-dropdown-sm"
            optionLabel="FirstName"
            optionValue="Id"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="approversArray.length != 10">
      <div class="w-50"></div>
      <div class="w-50">
        <div class="prime-Ng-Icon-Plus" (click)="addApprover()">
          <em class="pi pi-plus"></em>
        </div>
      </div>
    </div>
    <div class="row button-group">
      <div class="w-25">
        <div class="button-note">
          <span style="color: red">*</span>
          <span style="font-style: italic">Fields are mandatory</span
          ><br /><br />
          <span style="font-style: italic">Can add upto 10 approvers</span>
        </div>
      </div>
      <div class="w-75">
        <div class="flex-div">
          <p-button
            styleClass="primeng-button cancel"
            label="Cancel"
            [routerLink]="['/procurementgrid']"
          ></p-button>
          <p-button
            type="submit"
            styleClass="primeng-button accept"
            label="Submit"
            [disabled]="f.invalid == true"
            (click)="f.invalid ? toastrAlert() : ''"
          ></p-button>
        </div>
      </div>
    </div>
  </form>
</div>
<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
