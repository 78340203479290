<div
  id="info_tab"
  class="container-fluid resetFormStyles"
  style="margin-top: 30px; margin-bottom: 30px"
>
  <div class="row">
    <div class="w-100">
      <div>
        <h4>My Packages</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <p-tabView [(activeIndex)]="tabIndex">
      <p-tabPanel header="Package Info">
        <app-packageinfo
          [editable]="(edit || editable) && packageInfoSectionObj.packageInfo"
          [packageId]="packageId"
          [procurementId]="procurementId"
          [packageType]="packageType"
        ></app-packageinfo>
      </p-tabPanel>
      <p-tabPanel header="History">
        <app-histroy-tab
          [packageId]="packageId"
          [procurementId]="procurementId"
          [packageType]="packageType"
        >
        </app-histroy-tab>
      </p-tabPanel>
      <p-tabPanel header="Category Buyer">
        <app-category-buyer-tab
          [editable]="(edit || editable) && packageInfoSectionObj.categoryBuyer"
          [packageId]="packageId"
          [procurementId]="procurementId"
          [packageType]="packageType"
        ></app-category-buyer-tab>
      </p-tabPanel>
      <p-tabPanel header="Schedulers">
        <app-scheduler-grid
          [editable]="(edit || editable) && packageInfoSectionObj.schedulers"
          [packageId]="packageId"
          [procurementId]="procurementId"
          [packageType]="packageType"
        ></app-scheduler-grid>
      </p-tabPanel>
      <p-tabPanel header="Comments">
        <app-comment
          [editable]="(edit || editable) && packageInfoSectionObj.comments"
          [packageId]="packageId"
        ></app-comment>
      </p-tabPanel>
      <p-tabPanel header="PR Details">
        <app-pr-details
          [editable]="(edit || editable) && packageInfoSectionObj.prDetails"
          [packageId]="packageId"
          [prdetailsflag]="false"
        ></app-pr-details>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
