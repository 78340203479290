import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-procurement1',
  templateUrl: './createprocurement.component.html',
  styleUrls: ['./createprocurement.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateProcurementComponent implements OnInit {
  ProcurementPlanId = '0';
  isCapitalProject: string = 'yes';
  activeIndex = 0;
  status: string = 'active';
  UserList: any = [];
  engg_partner_project_manager_checkbox: boolean = false;
  checkbox_6000_services: boolean = false;
  service_6000: any = [];
  structural_steel_checkbox_5000: boolean = false;
  steel_5000: any = [];
  piping_checkbox_4000: boolean = false;
  piping_4000: any = [];
  process_instrument_checkbox_3000: boolean = false;
  instrument_3000: any = [];
  euipment_checkbox_2000: boolean = false;
  equipment_2000: any = [];
  eqipment_checkbox_1000: boolean = false;
  equipment_1000: any = [];
  PlantDetails: any = [];
  formDataOnEdit: any;
  @Input() editable: any;
  @Input() procurementId: any;
  constructor(
    private _service: ProcurementService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.procurement = {
      Id: 0,
      project_number: '',
      plant_code: { Id: null, Name: '' },
      project_name: '',

      tic: '',
      project_manager: { Id: null, FirstName: '' },
      engg_partner_project_manager: { Id: null, FirstName: '' },
      man_rep: { Id: null, FirstName: '' },
      ppm: { Id: null, FirstName: '' },
      planner_or_scheduler: { Id: null, FirstName: '' },
    };
  }

  procurement: any = {};
  formData: any;

  ngOnInit() {
    if (this.editable) this.editForm();
    this._service.RetrivePlantCodeDetails().subscribe((data) => {
      this.PlantDetails = data.response;
    });
    this._service.getallusers().subscribe((data) => {
      this.UserList = data.response;
    });
  }

  editForm() {
    this._service
      .getProcurementDetails(this.procurementId)
      .subscribe((response: any) => {
        this.spinner.hide();

        let data = response.response;
        this.formDataOnEdit = data;
        this.procurement.Id = data.Id;
        this.isCapitalProject = data.IsLargeCapital ? 'yes' : 'no';
        this.status = data.Status ? 'active' : 'complete';
        this.procurement.project_number = data.ProjectNumber;
        if (data.PlantCodeId)
          this.procurement.plant_code = { Id: data.PlantCodeId, Name: '' };
        this.procurement.project_name = data.ProjectName;
        this.procurement.tic = data.Tics;
        this.procurement.project_manager = {
          FirstName: data.ProjectManager.Name,
          Id: data.ProjectManager.Id,
        };
        this.procurement.engg_partner_project_manager = {
          FirstName: data.PartnerprojectManager.Name,
          Id: data.PartnerprojectManager.Id,
        };
        this.procurement.man_rep = {
          FirstName: data.ProjectManager.Manrepo,
          Id: data.Manrepo.Id,
        };
        this.procurement.ppm = { FirstName: data.Ppm.Name, Id: data.Ppm.Id };
        this.procurement.planner_or_scheduler = {
          FirstName: data.Planner.Name,
          Id: data.Planner.Id,
        };
        this.equipment_1000 = data.Equipment.map((x: any) => x.Id);
        this.equipment_2000 = data.Electrical.map((x: any) => x.Id);
        this.instrument_3000 = data.Instrumentation.map((x: any) => x.Id);
        this.piping_4000 = data.Piping.map((x: any) => x.Id);
        this.steel_5000 = data.StructuralSteel.map((x: any) => x.Id);
        this.service_6000 = data.Services.map((x: any) => x.Id);
        this.checkbox_6000_services = this.service_6000.length ? false : true;
        this.structural_steel_checkbox_5000 = this.steel_5000.length
          ? false
          : true;
        this.piping_checkbox_4000 = this.piping_4000.length ? false : true;
        this.process_instrument_checkbox_3000 = this.instrument_3000.length
          ? false
          : true;
        this.euipment_checkbox_2000 = this.equipment_2000.length ? false : true;
        this.eqipment_checkbox_1000 = this.equipment_1000.length ? false : true;
      });
  }
  getFormValuesOnEdit(formValue: any, field: any) {
    let ID: number = 0;
    if (formValue != '' && field != '') {
      if (
        this.formDataOnEdit != undefined &&
        this.formDataOnEdit[field] != undefined
      ) {
        let tempFormValue = this.formDataOnEdit[field].filter((item: any) => {
          return item.Id == formValue;
        });

        if (tempFormValue.length > 0) {
          if (tempFormValue[0].DiscipleLeadId != undefined) {
            ID = tempFormValue[0].DiscipleLeadId;
          }
        }
      }
    }
    return ID;
  }
  getFormValues(
    field: string,
    formArrayValue: any,
    pkgtype: number,
    valueType: string
  ) {
    let tempFormArray = [];
    if (formArrayValue != undefined && formArrayValue.length > 0) {
      for (const formValue of formArrayValue) {
        tempFormArray.push({
          DiscipleLeadId: this.getFormValuesOnEdit(formValue, field),
          field: field,
          value: formValue,
          PckgType: pkgtype,
          valueType: valueType,
        });
      }
      return tempFormArray;
    } else {
      return [
        {
          DiscipleLeadId: this.getFormValuesOnEdit('', ''),
          field: field,
          value: undefined,
          PckgType: pkgtype,
          valueType: valueType,
        },
      ];
    }
  }
  getTheProcurementPlanObj(_procurementPlanObj: any, selectedUserArray: any) {
    for (const item of selectedUserArray) {
      let tempUser = this.UserList.filter(
        (_item: any) => _item.Id == item.value
      );
      this.setProcurementPlanObj(tempUser, _procurementPlanObj, item);
    }
  }
  setProcurementPlanObj(tempUser: any, procurementPlanObj: any, item: any) {
    if (tempUser.length > 0) {
      let obj = {
        Id: tempUser[0].Id,
        Name: tempUser[0].FirstName,
        Email: tempUser[0].Email,
        PckgType: item.PckgType,
        DiscipleLeadId: item.DiscipleLeadId,
      };
      if (item.valueType == 'single') {
        procurementPlanObj[item.field] = obj;
      } else {
        if (procurementPlanObj[item.field] == undefined) {
          procurementPlanObj[item.field] = [obj];
        } else {
          procurementPlanObj[item.field].push(obj);
        }
      }
    } else {
      procurementPlanObj[item.field] =
        item.valueType == 'single'
          ? { Id: 0, Name: '', Email: '', PckgType: 0, DiscipleLeadId: 0 }
          : [];
    }
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  saveProcurement(form: NgForm) {
    this.spinner.show();
    let procurementPlanObj: any = {};
    procurementPlanObj['Id'] = this.procurement.Id;
    procurementPlanObj['IsLargeCapital'] =
      form.value.isCapitalProject == 'yes' ? true : false;
    procurementPlanObj['IsActive'] =
      form.value.status == 'active' ? true : false;
    procurementPlanObj['ProjectNumber'] = form.value.project_number.toString();
    procurementPlanObj['PlantCodeId'] = form.value.plant_code;
    procurementPlanObj['ProjectName'] = form.value.project_name;
    procurementPlanObj['Tics'] = form.value.tic.toString();
    procurementPlanObj['CreatedBy'] = localStorage.getItem('userId');
    procurementPlanObj['Status'] = '';

    let selectedUserArray = [
      {
        field: 'ProjectManager',
        value: form.value.project_manager,
        PckgType: 0,
        valueType: 'single',
        DiscipleLeadId: 0,
      },
      {
        field: 'PartnerprojectManager',
        value: form.value.engg_partner_project_manager,
        PckgType: 0,
        valueType: 'single',
        DiscipleLeadId: 0,
      },
      {
        field: 'Manrepo',
        value: form.value.man_rep,
        PckgType: 0,
        valueType: 'single',
        DiscipleLeadId: 0,
      },
      {
        field: 'Ppm',
        value: form.value.ppm,
        PckgType: 0,
        valueType: 'single',
        DiscipleLeadId: 0,
      },
      {
        field: 'Planner',
        value: form.value.planner_or_scheduler,
        PckgType: 0,
        valueType: 'single',
        DiscipleLeadId: 0,
      },
      ...this.getFormValues('Equipment', form.value.equipment_1000, 1, 'multi'),
      ...this.getFormValues(
        'Electrical',
        form.value.equipment_2000,
        2,
        'multi'
      ),
      ...this.getFormValues(
        'Instrumentation',
        form.value.instrument_3000,
        3,
        'multi'
      ),
      ...this.getFormValues('Piping', form.value.piping_4000, 4, 'multi'),
      ...this.getFormValues(
        'StructuralSteel',
        form.value.steel_5000,
        5,
        'multi'
      ),
      ...this.getFormValues('Services', form.value.service_6000, 6, 'multi'),
    ];
    this.getTheProcurementPlanObj(procurementPlanObj, selectedUserArray);

    this._service
      .insertProcurementDetails(procurementPlanObj)
      .subscribe((_data) => {
        this.spinner.hide();

        this.ProcurementPlanId = _data.response;
        if (this.ProcurementPlanId == '0') {
          this.toastr.warning(
            'This project number already exists, please try different project number.'
          );
        } else {
          this.toastr.success(
            'Procurement plan info is saved, now please submit the approvers.'
          );
          this.activeIndex = 1;
        }

        if (this.editable) this.editForm();
      });
  }
}
