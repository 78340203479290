import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  constructor(private client: HttpClient) {}
  retrieveJwtService(loggedinuser: any) {
    return this.client
      .post(environment.retrieveJwt, loggedinuser)
      .pipe(map((response) => response));
  }
  getDecryptedUserDetails(data: any) {
    return this.client
      .post(environment.decryptUserDetails, data)
      .pipe(map((response) => response));
  }
  getSAMLService() {
    return this.client.get(`${environment.getSamlURL}`).pipe(map((response) => response));
  }
}
