<div
  id="procurementGrid"
  class="container-fluid"
  style="margin-top: 30px; margin-bottom: 30px"
>
  <div class="row">
    <div class="col-md-12">
      <h4 style="font-size: 24px">Procurement Grid</h4>
    </div>
  </div>
  <div
    class="row"
    style="padding-top: 20px; display: flex; align-content: center"
  >
    <div
      class="col-md-12 buttonStyle"
      style="display: flex; justify-content: end; align-content: center"
    >
      <p-multiSelect
        [options]="selectedCols"
        [(ngModel)]="selectedColsMode"
        name="colsDD"
        ngDefaultControl
        (onChange)="onChangeDDValue($event)"
        optionLabel="header"
        defaultLabel="Show Items in Grid"
        [displaySelectedLabel]="false"
      ></p-multiSelect>
      <div>
        <em
          class="pi pi-file-excel excelIcon customButtonPackages"
          title="Export as Excel"
          (click)="exportAsXLSX()"
        ></em>
      </div>
      <button
        (click)="addPackages()"
        class="customButtonPackages"
        [disabled]="addPackageButton"
      >
        Add Packages
      </button>
      <button
        (click)="updatePlan()"
        class="customButtonPackages"
        [disabled]="closePlanButton"
      >
        Close
      </button>
      <button
        (click)="updatePlan()"
        class="customButtonPackages"
        [disabled]="reopenPlanButton"
      >
        Re-Open
      </button>
    </div>
  </div>

  <div class="row topSpacing" style="padding-top: 20px">
    <app-dynamic-grid
      [gridCols]="cols"
      [gridData]="selectedColsMode.length == 0 ? [] : procurementData"
      [showFilter]="true"
      [checkboxShow]="true"
      [dataKey]="dataKey"
      (sendSelectedRows)="receiveSelectedRows($event)"
      [showView]="true"
      [showEdit]="true"
      (selectRow)="selectedInfo($event)"
    >
    </app-dynamic-grid>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
