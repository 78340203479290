import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';
import { TagsService } from 'src/app/services/dataServices/tags.service';

@Component({
  selector: 'app-manual-tags',
  templateUrl: './manual-tags.component.html',
  styleUrls: ['./manual-tags.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualTagsComponent implements OnInit {
  packageId: string = '';
  tagsForm!: FormGroup;
  risqRatingArray = [];
  constructor(
    private route: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
    private _tagService: TagsService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.tagsForm = this.fb.group({
      tags: this.fb.array([]),
    });
    this._tagService.RetriveRisqdataDetails().subscribe({
      next: (_data:any) => {
        this.risqRatingArray = _data.response;
      },
    });
    this.packageId = this.sharedDataService.packageID;
    this.tags.push(this.newTag());
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  onCancel() {
    this.route.navigate(['/tags']);
  }

  onSubmit() {
    let tagArray = [];
    let tag = this.tagsForm.get(`tags.${this.tags.length - 1}`)?.value;
    if (
      tag.tagNumber == '' &&
      tag.budget == '' &&
      tag.quantity == '' &&
      tag.equipmentType == ''
    ) {
      this.toastr.error('Please Fill Empty Record First');
    } else {
      for (let index = 0; index < this.tags.length; index++) {
        tag = this.tagsForm.get(`tags.${index}`)?.value;
        let tempTagObj = {
          PackageId: parseInt(this.packageId),
          TagNumber: tag.tagNumber,
          Budget: parseInt(tag.budget),
          Quantity: parseInt(tag.quantity),
          EquipmentType: tag.equipmentType,
          Risk: '',
          RisqRating: parseInt(tag.risqRating),
          Area: tag.area,
          CreatedBy: localStorage.getItem('userId'),
        };
        tagArray.push(tempTagObj);
      }
    }

    this._tagService.inserttagdetails(tagArray).subscribe({
      next: (_data:any) => {
        this.toastr.success('Tags Saved');
        this.route.navigateByUrl('packages');
      },
    });
  }

  get tags(): FormArray {
    return this.tagsForm.get('tags') as FormArray;
  }

  newTag(): FormGroup {
    return this.fb.group({
      tagNumber: '',
      budget: '',
      quantity: '',
      equipmentType: '',

      risqRating: '',
      area: '',
      refId: 0,
    });
  }

  addTag() {
    let tag = this.tagsForm.get(`tags.${this.tags.length - 1}`)?.value;

    if (
      tag.tagNumber == '' &&
      tag.budget == '' &&
      tag.quantity == '' &&
      tag.equipmentType == ''
    ) {
      this.toastr.error('Please Fill Empty Record First');
    } else {
      this.tags.push(this.newTag());
    }
  }

  minusTag(i: number) {
    if (i == 0) return;
    this.tags.removeAt(i);
  }
}
