import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { CategoryManagementServices } from 'src/app/services/categorymanagement.services';

import { CategoryBuyerPopupComponent } from './category-buyer-popup/category-buyer-popup.component';
import { CategoryData } from './category.data';
import { PurchasingGroupPopupComponent } from './purchasing-group-popup/purchasing-group-popup.component';

@Component({
  selector: 'app-category-navigation',
  templateUrl: './category-navigation.component.html',
  styleUrls: ['./category-navigation.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class CategoryNavigationComponent implements OnInit {
  cols: any;
  categoryData: CategoryData[] = [];
  selectedRow: any;
  loginName: any = '';

  constructor(
    private _service: CategoryManagementServices,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.generateHeaders();
    this.loginName = localStorage.getItem('loginId');
    this.loadData();
  }

  generateHeaders() {
    this.cols = [
      {
        header_id: 1,
        field: 'PurchasingGroup',
        header: 'Purchasing Group',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
      {
        header_id: 2,
        field: 'PurchasingGroupDescription',
        header: 'Purchasing Group Description',
        is_show: true,
        is_editable: false,
        serial_no: 2,
      },
      {
        header_id: 3,
        field: 'CategoryName',
        header: 'Category',
        is_show: true,
        is_editable: false,
        serial_no: 3,
      },
      {
        header_id: 4,
        field: 'Buyer',
        header: 'Buyer',
        is_show: true,
        is_editable: false,
        serial_no: 4,
      },
      {
        header_id: 5,
        field: 'Email',
        header: 'Buyer Email',
        is_show: true,
        is_editable: false,
        serial_no: 5,
      },
      {
        header_id: 6,
        field: 'Id',
        header: 'Id',
        is_show: false,
        is_editable: false,
        serial_no: 6,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this._service.RetrieveCategoryCollection(this.loginName).subscribe({
      next: (response) => {
        this.spinner.hide();
        let CategoryCollection: any = response;
        this.categoryData = CategoryCollection;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.addCategoryBuyer(this.selectedRow.Id);
  }

  addCategoryBuyer(id: any) {
    this.dialogService.open(CategoryBuyerPopupComponent, {
      header: 'Category Buyer',
      width: '60%',
      data: { categoryId: id },
    });
  }

  addPurchasingGroup() {
    this.dialogService.open(PurchasingGroupPopupComponent, {
      header: 'Purchasing Group',
      width: '60%',
    });
  }
}
