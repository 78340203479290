<div class="container-fluid" style="margin-top: 30px; margin-bottom: 88px">
  <div class="row">
    <div class="col-md-12">
      <p-tabView id="adminPanel">
        <p-tabPanel header="Users">
          <div>
            <app-user-grid></app-user-grid>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Category Navigation">
          <div>
            <app-category-navigation></app-category-navigation>
          </div>
        </p-tabPanel>
        <p-tabPanel header="PR Details">
          <div>
            <app-pr-number></app-pr-number>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Change Log">
          <div>
            <app-change-log></app-change-log>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Contract Requirements">
          <div>
            <app-contract-requirement></app-contract-requirement>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Plant Code">
          <div>
            <app-plant-code-tab></app-plant-code-tab>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
