import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { UserService } from 'src/app/services/dataServices/user.service';

@Component({
  selector: 'app-procurement-grid',
  templateUrl: './procurement-grid.component.html',
  styleUrls: ['./procurement-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class ProcurementGridComponent implements OnInit {
  cols: any;
  selectedCols: any;
  selectedColsMode: any;
  procurementData: any;
  selectedRow: any = [];
  dataKey = 'Id';
  addPackageButton = true;
  closePlanButton = true;
  reopenPlanButton = true;

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.generateHeader();
    this.getData();
  }

  generateHeader() {
    this.cols = [
      {
        header_id: 0,
        field: 'IsLargeCapital',
        header: 'Is Large Capital',
        is_show: true,
        is_editable: false,
        serial_no: 0,
        is_grid_display: true,
      },
      {
        header_id: 1,
        field: 'ProjectName',
        header: 'Project Name',
        is_show: true,
        is_editable: false,
        serial_no: 1,
        is_grid_display: true,
      },
      {
        header_id: 2,
        field: 'ProjectNumber',
        header: 'Project Number',
        is_show: true,
        is_editable: false,
        serial_no: 2,
        is_grid_display: true,
      },
      {
        header_id: 3,
        field: 'PlantCode',
        header: 'Plant Code Id',
        is_show: true,
        is_editable: false,
        serial_no: 3,
        is_grid_display: true,
      },
      {
        header_id: 4,
        field: 'PPM',
        header: 'PPM',
        is_show: true,
        is_editable: false,
        serial_no: 4,
        is_grid_display: true,
      },
      {
        header_id: 5,
        field: 'ProjectManager',
        header: 'Project Manager',
        is_show: true,
        is_editable: false,
        serial_no: 5,
        is_grid_display: true,
      },
      {
        header_id: 6,
        field: 'PartnerprojectManager',
        header: 'Partner Project Manager',
        is_show: true,
        is_editable: false,
        serial_no: 6,
        is_grid_display: true,
      },
      {
        header_id: 7,
        field: 'Manrepo',
        header: 'Man Rep',
        is_show: true,
        is_editable: false,
        serial_no: 7,
        is_grid_display: true,
      },

      {
        header_id: 8,
        field: 'Id',
        header: 'Id',
        is_show: false,
        is_editable: false,
        serial_no: 8,
        is_grid_display: true,
      },
      {
        header_id: 9,
        field: 'Status',
        header: 'Status',
        is_show: true,
        is_editable: false,
        serial_no: 9,
        is_grid_display: true,
      },
      {
        header_id: 10,
        field: 'Planner',
        header: 'Planner',
        is_show: true,
        is_editable: false,
        serial_no: 10,
        is_grid_display: true,
      },
    ];
    this.selectedCols = this.selectedColsMode = this.cols;
  }
  onChangeDDValue(event: any) {
    this.sortColumns(event);
  }

  sortColumns(event: any) {
    if (event.hasOwnProperty('itemValue')) {
      this.selectedCols.filter(
        (h: any) => h.serial_no == event.itemValue.serial_no
      )[0].is_grid_display = !event.itemValue.is_grid_display;
    } else {
      if (event.value.length == 0) {
        this.selectedCols = this.selectedCols.map(function (h: any) {
          return { ...h, is_grid_display: false };
        });
      } else {
        this.selectedColsMode = this.selectedCols = this.selectedCols.map(
          function (h: any) {
            return { ...h, is_grid_display: true };
          }
        );
      }
    }

    this.cols = this.selectedCols.filter((h: any) => h.is_grid_display);
    this.cols = this.cols.sort((a: any, b: any) => {
      return a.serial_no - b.serial_no;
    });
  }
  getData() {
    this.spinner.show();
    this._service
      .getProcurementGridDetails(this.authenticationService.userObj.userId)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.procurementData = response.response;
        }
      });
  }

  selectedInfo(value: any) {
    if (value.type == 'view') {
      this.router.navigate(['details'], {
        queryParams: { ProcurementId: btoa(value.rowData?.Id) },
      });
    }
    if (value.type == 'edit') {
      this.router.navigate(['details'], {
        queryParams: { ProcurementId: btoa(value.rowData?.Id), edit: true },
      });
    }
  }

  exportAsXLSX(): void {
    const fieldValues: any[] = this.cols.map(
      (field: { field: any }) => field.field
    );
    const headerValues: string[] = this.cols.map(
      (header: { header: any }) => header.header
    );
    const exportJson = JSON.parse(JSON.stringify(this.procurementData));
    const displayedJson: any[] = [];
    for (let originalValue of exportJson) {
      let jsonObject: any = {};
      for (let i = 0; i <= fieldValues.length; i++) {
        jsonObject[headerValues[i]] = originalValue[fieldValues[i]];
      }
      let jsonString = JSON.stringify(jsonObject);
      displayedJson.push(JSON.parse(jsonString));
    }

    this._service.exportAsExcelFile(displayedJson, 'Procurement');
    this.toastr.success('File downloaded successfully');
  }

  receiveSelectedRows(event: any) {
    this.selectedRow = event;
    if (this.selectedRow.length == 1) {
      this.getSectionDetails();
    } else {
      this.addPackageButton = true;
      this.reopenPlanButton = true;
      this.closePlanButton = true;
    }
  }
  getSectionDetails() {
    this.userService
      .RetrieveProcurementSectionDetails(
        this.authenticationService.userObj.userId,
        this.selectedRow[0].Id,
        2
      )
      .subscribe((_data: any) => {
        _data.response.map((_item: any) => {
          if (_item.SectionName == 'Add Package') {
            this.addPackageButton = !_item.IsEnable;
          }
          if (
            _item.SectionName == 'Close' &&
            (this.selectedRow[0].Status == 'Created' ||
              this.selectedRow[0].Status == 'ReOpen')
          ) {
            this.closePlanButton = !_item.IsEnable;
          }
          if (
            _item.SectionName == 'Reopen' &&
            this.selectedRow[0].Status == 'Closed'
          ) {
            this.reopenPlanButton = !_item.IsEnable;
          }
        });
      });
  }
  addPackages() {
    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else if (this.selectedRow.length >= 2)
      this.toastr.warning('Please select a single row');
    else {
      this.router.navigate(['package'], {
        queryParams: { ProcurementId: btoa(this.selectedRow[0]?.Id) },
      });
    }
  }
  updatePlan() {
    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else if (this.selectedRow.length >= 2)
      this.toastr.warning('Please select a single row');
    else {
      if (
        this.selectedRow[0]?.Status == 'Closed' ||
        this.selectedRow[0]?.Status == 'ReOpen' ||
        this.selectedRow[0]?.Status == 'Created'
      ) {
        this._service
          .UpdatePlanstatus(
            this.selectedRow[0]?.Id,
            this.selectedRow[0]?.Status == 'Closed' ? 3 : 2,
            parseInt(this.authenticationService.userObj.userId)
          )
          .subscribe({
            next: () => {
              this.toastr.success('Status Updated.');
              this.getData();
            },
          });
      } else {
        this.toastr.warning('can not continue, plan not in correct status.');
      }
    }
  }
}
