import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkType'
})
export class CheckTypePipe implements PipeTransform {

  transform(value: any,): any {
    if (value == null)
      return ''
    else if (typeof value == 'number' || typeof value == 'string' || typeof value == 'boolean')
      return value
    
    else {
      if (Array.isArray(value))
        return value.map(x => x.Name).join(', ')
      else return `${value.Name} (${value.Email})`
    }
  }

}
