<div class="form-wraper" *ngIf="!islogin">
  <div class="form-container card">
    <div class="form-header">
      <a class="navbar-brand nav-img p-0 m-0" href="#">
        <div class="header-logo" style="display: inline-flex">
          <div>
            <img class="header-Logo-Basf"
                 src="../../assets/images/BASFo_wh100tr.png"
                 alt="BASF logo"
                 loading="lazy" />
          </div>
          <div>
            <div style="display: inline-flex">
              <p class="header-Logo-Dash">|</p>
              <img class="header-Logo-Hand"
                   src="../../assets/images/sales.png"
                   alt=""
                   loading="lazy" />
              <p class="header-logo-text">
                Procurement
                <br />
                Plan Workflow
              </p>
            </div>
          </div>
        </div>        
      </a>
    </div>
    <div class="form-body">
      <div class="flex items-center justify-between" style="text-align: -webkit-center">
        <button [routerLink]="['/home']" class="btn submit-btn" style="background-color: #004896;">
          Log in
        </button>
        <p *ngIf="logout">
          For completing your logout please close the browser window.
        </p>
      </div>
    </div>
  </div>
</div>
