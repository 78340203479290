import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

import { TagsGridPopupComponent } from './tags-grid-popup/tags-grid-popup.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  providers: [DialogService],
})
export class TagsComponent {
  packageId: string = '';

  constructor(private route: Router, private dialogService: DialogService) {}

  goToManualTags() {
    this.route.navigateByUrl(`/manual-tag`);
  }

  goToUploadTags() {
    this.route.navigateByUrl(`/upload-tags`);
  }

  show() {
    this.dialogService.open(TagsGridPopupComponent, {
      header: 'Tags',
      width: '80%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
    });
  }
}
