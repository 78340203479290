import { Component, OnInit } from '@angular/core';
import { TagsService } from 'src/app/services/dataServices/tags.service';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';

@Component({
  selector: 'app-tags-grid-popup',
  templateUrl: './tags-grid-popup.component.html',
  styleUrls: ['./tags-grid-popup.component.css'],
})
export class TagsGridPopupComponent implements OnInit {
  ngOnInit(): void {
    this.RetrieveTagList(this.sharedService.packageID);
  }
  constructor(
    private tagService: TagsService,
    private sharedService: SharedDataService
  ) {}
  tags = [];

  RetrieveTagList(packageId: any) {
    this.tagService.RetrieveTagDetails(packageId).subscribe({
      next: (_data: any) => {
        this.tags = _data.response;
      },
    });
  }
}
