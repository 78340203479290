import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';
import { CommentsComponent } from '../procurement-details/comments/comments.component';
import { PlannerInfoPopupComponent } from './planner-info-popup/planner-info-popup.component';
import { PackageService } from 'src/app/services/dataServices/package.service';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { forkJoin } from 'rxjs';
import { ApprovalService } from 'src/app/services/dataServices/approval.service';
import { UserService } from 'src/app/services/dataServices/user.service';

@Component({
  selector: 'app-my-packages',
  templateUrl: './my-packages.component.html',
  styleUrls: ['./my-packages.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class MyPackagesComponent implements OnInit, AfterViewInit {
  [x: string]: {};
  displayBasic2 = false;
  cols: any;
  selectedCols: any;
  selectedColsMode: any;
  packageData: any;
  packageDataCopy: any;
  selectedRow: any = [];
  dataKey = 'Id';
  showForm = false;
  projectName = [{ name: 'Lorem Ipsum' }, { name: 'Lprem Ipsum' }];
  selectedProject!: any;
  packageFilter = [
    { name: 'All', value: 1 },
    { name: 'Active', value: 2 },
    { name: 'Close', value: 3 },
  ];
  filter = 2;
  categoryBuyer!: boolean;
  scheduler!: boolean;
  packageLead!: boolean;

  needInfo: boolean = false;
  showPackageGrid: boolean = true;
  _service: any;
  _packageService: any;
  spinner: any;
  toastr: any;
  dialogService: any;
  activatedRoute: any;
  router: any;
  sharedService: any;
  authenticationService: any;
  _serviceApproval: any;
  userService: any;
  constructor(private injector: Injector) {
    this._service = injector.get<ProcurementService>(ProcurementService);
    this._packageService = injector.get<PackageService>(PackageService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.toastr = injector.get<ToastrService>(ToastrService);
    this.dialogService = injector.get<DialogService>(DialogService);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = injector.get<Router>(Router);
    this.sharedService = injector.get<SharedDataService>(SharedDataService);
    this.authenticationService = injector.get<AuthenticationService>(
      AuthenticationService
    );
    this._serviceApproval = injector.get<ApprovalService>(ApprovalService);
    this.userService = injector.get<UserService>(UserService);
  }
  ngAfterViewInit(): void {
    this.sharedService.isPackageEditable = false;
    let PackageIds =
      this.activatedRoute.snapshot.queryParams['PackageIds'] != undefined
        ? atob(this.activatedRoute.snapshot.queryParams['PackageIds'])
        : '0';

    let IsScheduler =
      this.activatedRoute.snapshot.queryParams['IsScheduler'] != undefined
        ? this.activatedRoute.snapshot.queryParams['IsScheduler']
        : false;
    let Inaccurate =
      this.activatedRoute.snapshot.queryParams['Inaccurate'] != undefined
        ? this.activatedRoute.snapshot.queryParams['Inaccurate']
        : false;
    if (IsScheduler) {
      this.openSchedulerFrom(PackageIds);
    } else if (Inaccurate) {
      this.openComments();
    }
  }
  ngOnInit(): void {
    this.generateHeader();

    this.categoryBuyer = false;
    this.packageLead = false;
    this.scheduler = true;

    this.getData(this.filter);
    this.packageDataCopy = this.packageData?.map((x: any) => {
      return { ...x };
    });
  }
  onChangeDDValue(event: any) {
    this.sortColumns(event);
  }

  sortColumns(event: any) {
    if (event.hasOwnProperty('itemValue')) {
      this.selectedCols.filter(
        (h: any) => h.serial_no == event.itemValue.serial_no
      )[0].is_grid_display = !event.itemValue.is_grid_display;
    } else {
      if (event.value.length == 0) {
        this.selectedCols = this.selectedCols.map(function (h: any) {
          return { ...h, is_grid_display: false };
        });
      } else {
        this.selectedColsMode = this.selectedCols = this.selectedCols.map(
          function (h: any) {
            return { ...h, is_grid_display: true };
          }
        );
      }
    }

    this.cols = this.selectedCols.filter((h: any) => h.is_grid_display);
    this.cols = this.cols.sort((a: any, b: any) => {
      return a.serial_no - b.serial_no;
    });
  }
  generateHeader() {
    this.cols = [
      {
        header_id: 0,
        field: 'Status',
        header: 'Package Status',
        is_show: true,
        is_editable: false,
        serial_no: 0,
        is_grid_display: true,
      },
      {
        header_id: 1,
        field: 'WBS',
        header: 'WBS',
        is_show: true,
        is_editable: false,
        serial_no: 1,
        is_grid_display: true,
      },
      {
        header_id: 2,
        field: 'ProjectName',
        header: 'Project Name',
        isHyperlink: true,
        is_show: true,
        is_editable: false,
        serial_no: 2,
        is_grid_display: true,
      },
      {
        header_id: 3,
        field: 'PackageDiscipline',
        header: 'Package Discipline',
        is_show: true,
        is_editable: false,
        serial_no: 3,
        is_grid_display: true,
      },
      {
        header_id: 4,
        field: 'PackageName',
        header: 'Package Name',
        is_show: true,
        is_editable: false,
        serial_no: 4,
        is_grid_display: true,
      },
      {
        header_id: 5,
        field: 'ConfidentialyClasification',
        header: 'Confidentiality Classification',
        is_show: true,
        is_editable: false,
        serial_no: 5,
        is_grid_display: true,
      },
      {
        header_id: 6,
        field: 'EquipmentType',
        header: 'Equipment Type',
        is_show: true,
        is_editable: false,
        serial_no: 6,
        is_grid_display: true,
      },
      {
        header_id: 7,
        field: 'Description',
        header: 'Description',
        is_show: true,
        is_editable: false,
        serial_no: 7,
        is_grid_display: true,
      },
      {
        header_id: 8,
        field: 'CategoryBuyer',
        header: 'Category Buyer',
        is_show: true,
        is_editable: false,
        serial_no: 8,
        is_grid_display: true,
      },
      {
        header_id: 9,
        field: 'CategoryName',
        header: 'category Name',
        is_show: true,
        is_editable: false,
        serial_no: 9,
        is_grid_display: true,
      },

      {
        header_id: 10,
        field: 'BudgetValue',
        header: 'Budget Value',
        is_show: true,
        is_editable: false,
        serial_no: 10,
        is_grid_display: true,
      },
      {
        header_id: 11,
        field: 'Id',
        header: 'Id',
        is_show: false,
        is_editable: false,
        serial_no: 11,
        is_grid_display: true,
      },
      {
        header_id: 12,
        field: 'NumberOfTags',
        header: 'Number Of Tags',
        is_show: true,
        is_editable: false,
        serial_no: 12,
        is_grid_display: true,
      },
      {
        header_id: 13,
        field: 'BiddersList',
        header: 'Bidders List',
        is_show: true,
        is_editable: false,
        serial_no: 13,
        is_grid_display: true,
      },
      {
        header_id: 14,
        field: 'Sequencenumber',
        header: 'Sequence Number',
        is_show: true,
        is_editable: false,
        serial_no: 14,
        is_grid_display: true,
      },
    ];
    this.selectedCols = this.selectedColsMode = this.cols;
  }

  displayPage(page: string) {
    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else if (this.selectedRow.length >= 2)
      this.toastr.warning('Please select a single row');
    else if (page == 'needInfo') {
      this.needInfo = true;
      this.showPackageGrid = false;
    }
  }

  verifyAndSendPackages() {
    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else {
      let apiRequest = [];
      let observObj: any = {};

      this.spinner.show();
      let packagesCanBeMovedtoNextStep = this.selectedRow.filter(
        (item: any) => {
          return item.StatusId == 2; //Submit
        }
      );
      let packagesCanBeMovedtoApprovers = this.selectedRow.filter(
        (item: any) => {
          return item.StatusId == 4; //completed sche/cb
        }
      );
      let packagesCanNotBeMoved = this.selectedRow.filter((item: any) => {
        return item.StatusId == 3 || item.StatusId == 1; //pending for sche/cb and draft
      });

      if (packagesCanBeMovedtoNextStep.length > 0) {
        apiRequest = packagesCanBeMovedtoNextStep.map((item: any) => {
          return {
            ProcurementId: item.ProcurementplanId,
            PackageId: item.Id,
            PackageName: item.PackageName,
            ProjectName: item.ProjectName,
            ProjectNumber: item.WBS,
            CategoryBuyerName: item.CategoryBuyer,
            CategoryBuyerEmail: item.CategoryBuyerEmail,
            SchedulerName: item.Scheduler,
            SchedulerEmail: item.SchedulerEmail,
            PackageType: item.PackageDiscipline,
          };
        });
        observObj['sourceOne'] =
          this._service.sendPackageStatusChange(apiRequest);
      }
      if (packagesCanBeMovedtoApprovers.length > 0) {
        apiRequest = packagesCanBeMovedtoApprovers.map((item: any) => {
          return parseInt(item.Id);
        });
        observObj['sourceTwo'] = this._packageService.SendEmailtoApprovers(
          apiRequest,
          parseInt(this.authenticationService.userObj.userId)
        );
      }
      if (packagesCanNotBeMoved.length > 0) {
        this.toastr.warning(
          'This operation cannot be performed as Package has not reached the correct status.'
        );
      }
      observObj['sourceThree'] = this._service.getPackageDetails(
        this.authenticationService.userObj.userId
      );
      this.verifyAndSendPackageForkCall(observObj);
      this.spinner.hide();
    }
  }
  verifyAndSendPackageForkCall(observObj: any) {
    forkJoin(observObj).subscribe({
      next: (_data: any) => {
        if (_data.sourceOne !== undefined) {
          this.sendPackageSourceOne(_data);
        }
        if (_data.sourceTwo !== undefined) {
          this.sendPackageSourceTwo(_data);
        }
        if (_data.sourceThree !== undefined) {
          this.sendPackageSourceThree(_data);
        }
      },
      complete: () => {
        this.getData(this.filter);
      },
    });
  }
  sendPackageSourceOne(_data: any) {
    let response = _data.sourceOne.response;
    this.spinner.hide();
    if (!response['IsError']) {
      this.toastr.success('Packages send to next stage');
      this.unCheckCheckboxes();
      this.selectedRow = [];
    }
  }
  sendPackageSourceTwo(_data: any) {
    let response = _data.sourceTwo.response;
    this.spinner.hide();
    if (!response['IsError']) {
      this.toastr.success('Packages sent for approval.');
      this.unCheckCheckboxes();
      this.selectedRow = [];
    }
  }
  sendPackageSourceThree(_data: any) {
    let response = _data.sourceThree.response;
    this.spinner.hide();
    if (!response['IsError']) {
      this.packageData = response;
    }
  }

  getData(filter: any) {
    this.spinner.show();
    this._service
      .getPackageDetails(this.authenticationService.userObj.userId, filter)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.packageData = response.response;
          this.unCheckCheckboxes();
        }
      });
  }

  refreshUsers(_eventvalue: any) {
    this.showPackageGrid = true;
    this.needInfo = false;
    this.selectedRow = [];
  }

  categoryBuyerUrl = '';
  receiveSelectedRows(event: any) {
    this.selectedRow = event;
    if (event != undefined && event.length == 1) {
      this.categoryBuyerUrl = `/procurement?packageId=${btoa(event[0].Id)}`;
    } else {
      this.categoryBuyerUrl = '';
    }
    if (this.selectedRow.length > 0) {
      this.RetrievePackageDetailsSection();
    } else {
      this.packageButtonObj = { IsEnable: false };
    }
  }
  RetrievePackageDetailsSection() {
    this.userService
      .RetrievePackageDetailsSection(
        this.authenticationService.userObj.userId,
        this.selectedRow[0].Id,
        4
      )
      .subscribe((_data: any) => {
        _data.response.map((_item: any) => {
          let flag = _item.IsEnable;
          if (_item.SectionName == 'Approve Packages') {
            flag = _item.IsEnable && this.selectedRow[0].StatusId == 5;
          }
          if (_item.SectionName == 'Send Selected packages to the next step') {
            flag =
              _item.IsEnable &&
              (this.selectedRow[0].StatusId == 2 ||
                this.selectedRow[0].StatusId == 4);
          }
          if (_item.SectionName == 'Enter Scheduler/Planner Info') {
            flag =
              _item.IsEnable &&
              (this.selectedRow[0].StatusId == 3 ||
                this.selectedRow[0].StatusId == 8);
          }
          if (_item.SectionName == 'Enter Category Buyer Info') {
            flag =
              _item.IsEnable &&
              (this.selectedRow[0].StatusId == 3 ||
                this.selectedRow[0].StatusId == 9);
          }
          this.packageButtonObj = {
            ...this.packageButtonObj,
            [_item.SectionName]: flag,
          };
        });
      });
  }
  packageButtonObj: any = {};
  filterProject() {
    if (this.selectedProject)
      this.packageData = this.packageDataCopy.filter(
        (x: any) => x.projectName == this.selectedProject.name
      );
    else this.packageData = this.packageDataCopy;
  }

  exportAsXLSX(): void {
    const fieldValues: any[] = this.cols.map(
      (field: { field: any }) => field.field
    );
    const headerValues: string[] = this.cols.map(
      (header: { header: any }) => header.header
    );
    const exportJson = JSON.parse(JSON.stringify(this.packageData));
    const displayedJson: any[] = [];
    for (let originalValue of exportJson) {
      let jsonObject: any = {};
      for (let i = 0; i <= fieldValues.length; i++) {
        jsonObject[headerValues[i]] = originalValue[fieldValues[i]];
      }
      let jsonString = JSON.stringify(jsonObject);
      displayedJson.push(JSON.parse(jsonString));
    }

    this._service.exportAsExcelFile(displayedJson, 'My Packages');
    this.toastr.success('File downloaded successfully');
  }

  goToPlannerInfo() {
    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else {
      const dialogeRef = this.dialogService.open(PlannerInfoPopupComponent, {
        header: 'Scheduler/Planner Package Info',
        width: '60%',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        baseZIndex: 10000,
        data: { selectedRow: this.selectedRow },
      });
      dialogeRef.onClose.subscribe({
        next: () => {
          this.getData(this.filter);
        },
      });
    }
  }

  goToCategoryBuyerInfo(url: any) {
    if (!this.selectedRow.length) {
      this.toastr.warning('Please select a row');
      return;
    }
    window.open(`#${url}`, '_blank');
  }

  packageType(value: string) {
    if (value == 'Equipment') return 1;
    else if (value == 'Electrical') return 2;
    else if (value == 'Instrumentation') return 3;
    else if (value == 'Piping') return 4;
    else if (value == 'StructuralSteel') return 5;
    else if (value == 'Service') return 6;
    else return 0;
  }

  selectedInfo(value: any) {
    if (value.type == 'view') {
      this.router.navigate(['packageDetails'], {
        queryParams: {
          procurementId: btoa(value.rowData.ProcurementplanId),
          packageId: btoa(value.rowData.Id),
          pkgtypeId: btoa(
            this.packageType(value.rowData.PackageDiscipline).toString()
          ),
          edit: false,
        },
      });
    }
    if (value.type == 'hyperlink') {
      this.router.navigate(['details'], {
        queryParams: { ProcurementId: btoa(value.rowData?.ProcurementplanId) },
      });
    }
    if (value.type == 'edit') {
      this.router.navigate(['packageDetails'], {
        queryParams: {
          procurementId: btoa(value.rowData.ProcurementplanId),
          packageId: btoa(value.rowData.Id),
          pkgtypeId: btoa(
            this.packageType(value.rowData.PackageDiscipline).toString()
          ),
          edit: true,
        },
      });
    }
  }

  openSchedulerFrom(_PackageIds: string) {
    //slelect packages on loop
    this._service
      .getPackageDetails(this.authenticationService.userObj.userId)
      .subscribe((response: any) => {
        let row = response.response.filter((_data: any) => {
          return _data.Id == _PackageIds;
        });
        if (row.length > 0) {
          let a: any = document.getElementById(`trcheckbox_${row[0].Id}`);
          a.getElementsByTagName('input')[0].click();
          this.goToPlannerInfo();
        }
      });
  }
  openComments() {
    this.dialogService.open(CommentsComponent, {
      header: 'Comments',
      width: '60%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
    });
  }
  approvePackages() {
    if (!this.selectedRow.length) {
      this.toastr.warning('Please select a row');
      return;
    }
    let userId: any = this.authenticationService.userObj.userId
      ? this.authenticationService.userObj.userId
      : 0;
    let packageList: any = [];

    let obsrvCheck: any = {};
    let packageApprovalobsrvArray: any = {};
    for (let index = 0; index < this.selectedRow.length; index++) {
      obsrvCheck[`package_${index}`] = this._service.RetrievePackageDetailsbyId(
        this.selectedRow[index].Id
      );
    }
    this.approvePackageCheckFork(
      obsrvCheck,
      packageApprovalobsrvArray,
      userId,
      packageList
    );
  }
  ssjOrssFlag = false;
  approvePackageCheckFork(
    obsrvArray: any,
    packageApprovalobsrvArray: any,
    userId: any,
    packageList: any
  ) {
    forkJoin(obsrvArray).subscribe({
      next: (response: any) => {
        let obsrvArray: any = {};

        for (let i = 0; i < Object.keys(response).length; i++) {
          let tempData = response[`package_${i}`];
          if (tempData.response != undefined && tempData.response.length != 0) {
            if (
              tempData.response.Item1.SsjStatus == 'yes' &&
              tempData.response.Item1.ApprovedSuggestedStratgy
            ) {
              obsrvArray[`package_${i}`] = this._serviceApproval.GetApprovers(
                this.selectedRow[i].ProcurementplanId,
                this.selectedRow[i].Id
              );
            } else {
              this.toastr.warning(
                'SSJ Or Sugested Strategy needs to be updated.'
              );
            }
          }
        }
        this.approvePackageFork(
          obsrvArray,
          packageApprovalobsrvArray,
          userId,
          packageList
        );
      },
    });
  }
  approvePackageFork(
    obsrvArray: any,
    packageApprovalobsrvArray: any,
    userId: any,
    packageList: any
  ) {
    forkJoin(obsrvArray).subscribe((data: any) => {
      let Approver: any = [];
      let approvalAllowed = { flag: false };
      let needApprovalFlag = { flag: true };
      for (let i = 0; i < Object.keys(obsrvArray).length; i++) {
        let tempData = data[`package_${i}`];
        if (tempData.response != undefined && tempData.response.length != 0) {
          tempData.response.sort((a: any, b: any) => {
            return a.level - b.level;
          });

          Approver = this.getapprover(tempData.response);
          this.getApprovalFlags(
            Approver,
            needApprovalFlag,
            approvalAllowed,
            tempData
          );
        } else {
          Approver = [];
        }

        if (approvalAllowed.flag && Approver.length != 0) {
          let tempObj = {
            Id: Approver[0].Id,
            ProcurementplanId: parseInt(this.selectedRow[i].ProcurementplanId),
            PackageId: parseInt(this.selectedRow[i].Id),
            level: Approver[0].level,
            Approvers: parseInt(userId),
            ApprovalType: parseInt(Approver[0].ApprovalType),
            Status: 1,
            Comments: 'bulk approval',
            ModifiedBy: userId,
            IsActive: true,
            PackageType: parseInt(this.selectedRow[i].PackageDisciplineId),
            IsExternalUser: true,
          };
          packageList.push(tempObj);
          packageApprovalobsrvArray[`package_${i}`] =
            this._service.ApproveOrRejectProcurement(tempObj);
        } else {
          this.toastr.warning(
            'approval of few packages is not allowed by this user.'
          );
        }
      }

      if (packageList.length > 0) {
        this.bulkPackageApproval(packageApprovalobsrvArray);
      }
    });
  }
  getApprovalFlags(
    Approver: any,
    needApprovalFlag: any,
    approvalAllowed: any,
    tempData: any
  ) {
    if (Approver.length > 0) {
      if (Approver[0].ApprovalType == 1) {
        if (this.checkForApprovers(Approver)) {
          needApprovalFlag.flag = true;
        } else if (Approver[0].level > 1) {
          let index = tempData.response.findIndex((item: any) => {
            return item.Approvers == this.authenticationService.userObj.userId;
          });

          if (this.checkForPreviousApprover(tempData, index)) {
            needApprovalFlag.flag = false;
          }
        }
      }

      if (this.checkForApproversFlag(Approver, needApprovalFlag)) {
        approvalAllowed.flag = false;
      } else {
        approvalAllowed.flag = true;
      }
    }
  }
  getapprover(approvers: any) {
    return approvers.filter((item: any) => {
      return item.Approvers == this.authenticationService.userObj.userId;
    });
  }
  checkForApprovers(Approver: any) {
    return (
      Approver[0].level == 1 &&
      (Approver[0].Status == 0 || Approver[0].Status == 3)
    );
  }
  checkForPreviousApprover(response: any, index: any) {
    return (
      index > 0 &&
      (response.response[index - 1].Status == 0 ||
        response.response[index - 1].Status == 3)
    );
  }
  checkForApproversFlag(Approver: any, needApprovalFlag: any) {
    return (
      Approver[0].Status == 1 ||
      Approver[0].Status == 2 ||
      !needApprovalFlag.flag
    );
  }
  bulkPackageApproval(obsrvArray: any) {
    forkJoin(obsrvArray).subscribe({
      next: (data: any) => {
        let tempData: any;
        for (let index = 0; index < Object.keys(obsrvArray).length; index++) {
          tempData = data['package_' + index];
          this.toastr.success(tempData.response);
        }
        this.getData(this.filter);
      },
    });
  }
  unCheckCheckboxes() {
    for (const iterator of this.selectedRow) {
      let a: any = document.getElementById(`trcheckbox_${iterator.Id}`);
      let element = a.getElementsByTagName('input');
      let span = a.getElementsByTagName('span');
      $(span).trigger('click');
      if (element[0].checked) element[0].checked = false;
    }
  }
}
