<div id="enterPRNumber">
  <form [formGroup]="prDetailsForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-2">
        <label style="font-weight: 600"
          >PR Number<sup
            ><strong><span style="color: red">*</span></strong></sup
          ></label
        >
      </div>
      <div class="col-md-3">
        <input style="width: 97%" formControlName="prNumber" type="text" />
      </div>
      <div class="col-md-2">
        <label style="font-weight: 600"
          >Item Number<sup
            ><strong><span style="color: red">*</span></strong></sup
          ></label
        >
      </div>
      <div class="col-md-3">
        <input style="width: 97%" formControlName="liNumber" type="text" />
      </div>
      <div class="col-md-2" style="text-align: end; padding: 10px">
        <p-button
          (click)="searchPRNumber()"
          styleClass="primeng-button accept"
          label="Search"
        ></p-button>
      </div>
    </div>

    <div *ngIf="showDetails" formArrayName="details" style="padding-top: 20px">
      <div
        *ngIf="noDetails"
        style="text-align: center; padding: 10px; font-size: 12px; color: red"
      >
        Provided PR Number is not interfaced from SAP
      </div>
      <div *ngFor="let data of details.controls; let i = index">
        <div [formGroupName]="i">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                      <label class="cust-form-label" for="vendorName"
                        >PR Vendor Name<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                      <input type="text" formControlName="vendorName" />
                    </div>
                    <div
                      *ngIf="
                        data.get('vendorName')?.invalid &&
                        data.get('vendorName')?.touched
                      "
                      class="text-danger"
                    >
                      <div *ngIf="data.get('vendorName')?.hasError('required')">
                        Vendor Name is required.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                      <label class="cust-form-label" for="poNumber"
                        >PO Number<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                      <input type="text" formControlName="poNumber" />
                    </div>
                    <div
                      *ngIf="
                        data.get('poNumber')?.invalid &&
                        data.get('poNumber')?.touched
                      "
                      class="text-danger"
                    >
                      <div *ngIf="data.get('poNumber')?.hasError('required')">
                        PO Number is required.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                      <label class="cust-form-label" for="poValue"
                        >PO Value<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                      <input type="text" formControlName="poValue" />
                    </div>
                    <div
                      *ngIf="
                        data.get('poValue')?.invalid &&
                        data.get('poValue')?.touched
                      "
                      class="text-danger"
                    >
                      <div *ngIf="data.get('poValue')?.hasError('required')">
                        PO Value is required.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                      <label class="cust-form-label" for="date"
                        >Delivery Date<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                      <input
                        style="width: 100%"
                        type="date"
                        formControlName="date"
                      />
                    </div>
                    <div
                      *ngIf="
                        data.get('date')?.invalid && data.get('date')?.touched
                      "
                      class="text-danger"
                    >
                      <div *ngIf="data.get('date')?.hasError('required')">
                        Date is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-1"
                *ngIf="prDetailsForm.value.details[i].showUpdate"
              >
                <div class="row" style="margin-top: 10px">
                  <div
                    (click)="
                      update(
                        prDetailsForm.value.details[i],
                        prDetailsForm.value.prNumber,
                        prDetailsForm.value.liNumber
                      )
                    "
                  >
                    <em
                      style="
                        background: #004896;
                        color: white;
                        padding: 2px;
                        border-radius: 2px;
                      "
                      class="pi pi-check"
                    ></em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showDetails" style="text-align: center; padding: 30px 0px">
      <p-button
        styleClass="primeng-button add"
        [disabled]="prDetailsForm.invalid"
        (click)="prDetailsForm.invalid ? toastrAlert() : ''"
        (click)="addDetails()"
        label="Add PR Details"
      >
      </p-button>
      <p-button
        type="reset"
        styleClass="primeng-button reject"
        (click)="reset()"
        label="Cancel"
      >
      </p-button>
      <p-button
        type="submit"
        [disabled]="
          prDetailsForm.invalid || !prDetailsForm.value.details.length
        "
        (click)="prDetailsForm.invalid ? toastrAlert() : ''"
        styleClass="primeng-button accept"
        label="Submit"
      ></p-button>
    </div>
  </form>
</div>
