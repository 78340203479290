import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PurchasingGroupData } from '../components/admin/category-navigation/purchasing-group-popup/purchasing-group.data';

@Injectable({
  providedIn: 'root',
})
export class PurchasingGroupServices {
  constructor(private _http: HttpClient) {}

  savePurchasingGroup(data: PurchasingGroupData) {
    return this._http
      .post(environment.SavePurchasingGroup, data)
      .pipe(map((response) => response));
  }
  retrieveGroupServices(groupId: number, loginId: string) {
    const headers = new HttpHeaders().set('groupId', groupId.toString());
    let link: string = environment.RetrievePurchasingGroup.replace(
      '{loginName}',
      loginId
    ).replace('{purchasingGroupId}', groupId.toString());
    return this._http
      .get(link, { headers: headers })
      .pipe(map((response) => response));
  }
}
