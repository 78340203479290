import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.css'],
})
export class UserGridComponent implements OnInit {
  cols: any;
  usersData: any;
  selectedRow: any;
  formType: any;
  showForm = false;

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.generateHeaders();
    this.loadData();
  }

  generateHeaders() {
    this.cols = [
      {
        header_id: 1,
        field: 'LoginName',
        header: 'BASF UserId',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
      {
        header_id: 2,
        field: 'FirstName',
        header: 'First Name',
        is_show: true,
        is_editable: false,
        serial_no: 2,
      },
      {
        header_id: 3,
        field: 'LastName',
        header: 'Last Name',
        is_show: true,
        is_editable: false,
        serial_no: 3,
      },
      {
        header_id: 4,
        field: 'Email',
        header: 'Email',
        is_show: true,
        is_editable: false,
        serial_no: 4,
      },
      {
        header_id: 5,
        field: 'IsAdmin',
        header: 'Admin',
        is_show: true,
        is_editable: false,
        serial_no: 5,
      },
      {
        header_id: 5,
        field: 'IsPPM',
        header: 'PPM',
        is_show: true,
        is_editable: false,
        serial_no: 5,
      },
      {
        header_id: 6,
        field: 'CountryName',
        header: 'Country',
        is_show: true,
        is_editable: false,
        serial_no: 6,
      },
      {
        header_id: 7,
        field: 'CreatedBy',
        header: 'Created By',
        is_show: false,
        is_editable: false,
        serial_no: 7,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this._service.getUsers().subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.usersData = response.response;
      }
    });
  }

  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
  }

  addNewUser() {
    this.selectedRow = {
      basfUserId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      isAdmin: false,
      country: '',
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }
}
