import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe } from '@angular/common';

import { InputNumberModule } from 'primeng/inputnumber';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { CreateProcurementComponent } from './components/createprocurement/createprocurement.component';
import { ApprovalStrategyComponent } from './components/approval-strategy/approval-strategy.component';
import { PackageCreationComponent } from './components/package-creation/package-creation.component';

import { TagsComponent } from './components/tags/tags.component';
import { ManualTagsComponent } from './components/tags/manual-tags/manual-tags.component';
import { TagsGridPopupComponent } from './components/tags/tags-grid-popup/tags-grid-popup.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DividerModule } from 'primeng/divider';

import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { MyPackagesComponent } from './components/my-packages/my-packages.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminComponent } from './components/admin/admin.component';
import { UserGridComponent } from './components/admin/user/user-grid/user-grid.component';
import { UserRegistrationComponent } from './components/admin/user/user-registration/user-registration.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProcurementComponent } from './components/procurement/procurement.component';
import { UploadTagsComponent } from './components/tags/upload-tags/upload-tags.component';
import { PlannerInfoPopupComponent } from './components/my-packages/planner-info-popup/planner-info-popup.component';
import { RfiComponent } from './components/my-packages/rfi/rfi.component';
import { HttpconfigInterceptor } from './_helpers/interceptor/httpconfig.interceptors';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PackageinfoComponent } from './components/packageinfo/packageinfo.component';
import { ProcurementDetailsComponent } from './components/procurement-details/procurement-details.component';
import { CheckTypePipe } from './pipes/check-type.pipe';
import { CheckPackagePipe } from './pipes/check-package.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { SchedulerGridComponent } from './components/my-packages/scheduler-grid/scheduler-grid.component';
import { CommentsComponent } from './components/procurement-details/comments/comments.component';
import { CommentComponent } from './components/comment/comment.component';
import { HistroyTabComponent } from './components/histroy-tab/histroy-tab.component';
import { InfoTabsComponent } from './components/info-tabs/info-tabs.component';
import { CheckApprovalTypePipe } from './pipes/check-approval-type.pipe';
import { CategoryBuyerTabComponent } from './components/category-buyer-tab/category-buyer-tab.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmationService } from 'primeng/api';
import { PlanCommentsComponent } from './components/procurement-details/plan-comments/plan-comments.component';
import { ProcurementGridComponent } from './components/procurement-grid/procurement-grid.component';
import { CategoryNavigationComponent } from './components/admin/category-navigation/category-navigation.component';
import { CategoryBuyerPopupComponent } from './components/admin/category-navigation/category-buyer-popup/category-buyer-popup.component';
import { PurchasingGroupPopupComponent } from './components/admin/category-navigation/purchasing-group-popup/purchasing-group-popup.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { PrDetailsComponent } from './components/info-tabs/pr-details/pr-details.component';
import { PrNumberComponent } from './components/admin/pr-number/pr-number.component';
import { EnterPrNumberComponent } from './components/admin/pr-number/enter-pr-number/enter-pr-number.component';
import { PurchasingGroupServices } from './services/purchasing-group.service';
import { CategoryManagementServices } from './services/categorymanagement.services';
import { UserManagementServices } from './services/user-management.services';
import { JWTTokenService } from './services/jwtToken.services';
import { OnlyaplhanumericDirective } from './directives/authorization/attribute/onlyaplhanumeric.directive';
import { ContractRequirementComponent } from './components/admin/contract-requirement/contract-requirement.component';
import { PlantCodeTabComponent } from './components/admin/plant-code-tab/plant-code-tab.component';
import { LoginTestComponent } from './components/login-test/login-test.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    CreateProcurementComponent,
    ApprovalStrategyComponent,
    TagsComponent,
    ManualTagsComponent,
    TagsGridPopupComponent,
    PackageCreationComponent,
    DynamicGridComponent,
    MyPackagesComponent,
    AdminComponent,
    UserGridComponent,
    UserRegistrationComponent,
    ProcurementComponent,
    UploadTagsComponent,
    PlannerInfoPopupComponent,
    RfiComponent,

    PackageinfoComponent,
    ProcurementDetailsComponent,
    CheckTypePipe,
    CheckPackagePipe,
    SchedulerGridComponent,
    CommentsComponent,
    HistroyTabComponent,
    InfoTabsComponent,
    CommentComponent,
    CheckApprovalTypePipe,
    CategoryBuyerTabComponent,
    PlanCommentsComponent,
    ProcurementGridComponent,
    CategoryNavigationComponent,
    CategoryBuyerPopupComponent,
    PurchasingGroupPopupComponent,
    LoginComponent,
    ChangeLogComponent,
    PrDetailsComponent,
    PrNumberComponent,
    EnterPrNumberComponent,
    OnlyaplhanumericDirective,
    ContractRequirementComponent,
    PlantCodeTabComponent,
    LoginTestComponent
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    FieldsetModule,
    DividerModule,
    InputTextModule,
    PanelMenuModule,
    RadioButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    TabViewModule,
    MultiSelectModule,
    InputSwitchModule,
    NgxSpinnerModule,
    InputNumberModule,
    CalendarModule,
    KeyFilterModule,
    DialogModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    DynamicDialogModule,
    TableModule,
    ButtonModule,
    TooltipModule,
    ConfirmDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
    },
    ConfirmationService,
    PurchasingGroupServices,
    CategoryManagementServices,
    UserManagementServices,
    JWTTokenService,
    DatePipe,
    PurchasingGroupServices,
    CategoryManagementServices,
    UserManagementServices,
    JWTTokenService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
