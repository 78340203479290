import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../components/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private _http: HttpClient) {}

  inserttagdetails(details: any) {
    const headers = new HttpHeaders().set('PackageId', details[0].PackageId.toString());
    return this._http
      .post<ApiResponse>(`${environment.inserttagdetails}`, details,{headers: headers})
      .pipe(map((response) => response));
  }
  RetriveRisqdataDetails() {
    return this._http
      .get<ApiResponse>(`${environment.RetriveRisqdataDetails}`)
      .pipe(map((response) => response));
  }
  Uploadtagdetails(excel: any) {
    const headers = new HttpHeaders().set('PackageId', excel.PackageId.toString());
    return this._http
      .post<ApiResponse>(`${environment.Uploadtagdetails}`, excel,{headers: headers})
      .pipe(map((response) => response));
  }
  RetrieveTagDetails(packageId: any) {
    const headers = new HttpHeaders().set('packageid', packageId.toString());
    return this._http
      .get<ApiResponse>(
        `${environment.RetrieveTagDetails}?packageid=${packageId}`,
        {
          headers: headers,
        }
      )
      .pipe(map((response) => response));
  }
}
