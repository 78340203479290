<div id="pr-details">
  <p *ngIf="!prDetails.length" style="text-align: center; padding-top: 30px">
    No associated PR Details to show
  </p>
  <div>
    <div *ngIf="editable && prDetails.length">
      <app-pr-number
        (reload)="refresh($event)"
        [editable]="true"
        [packageId]="this.packageInfoDetails['packageId']"
        [prdetailsflag]="prdetailsflag"
      ></app-pr-number>
    </div>
    <div *ngIf="prDetails.length">
      <app-dynamic-grid
        [gridCols]="cols"
        [gridData]="prDetails"
        [showFilter]="true"
        [dataKey]="dataKey"
        [showEdit]="editable"
        (selectRow)="selectedInfo($event)"
      >
      </app-dynamic-grid>
      <p-dialog
        header="Edit PR Details"
        [(visible)]="display"
        [style]="{ width: '60%' }"
        [contentStyle]="{ 'max-height': '500px', overflow: 'auto' }"
      >
        <form id="editPRdetails" (ngSubmit)="onSave()" #prUpdateForm="ngForm">
          <div>
            <div
              *ngIf="!isEditable"
              style="text-align: center; padding: 10px; font-size: 12px"
            >
              Provided PR details is interfaced from SAP and cannot be edited
            </div>
            <div *ngIf="isEditable">
              <div class="row form-field">
                <div class="col-md-5">
                  <label class="float-right">PR Number</label>
                </div>
                <div class="col-md-7">
                  {{ PRNumber }}
                </div>
              </div>

              <div class="row form-field">
                <div class="col-md-5">
                  <label class="float-right">PR Vendor Name</label>
                </div>
                <div class="col-md-7">
                  <input
                    name="prVendor"
                    [(ngModel)]="prVendor"
                    class="form-input"
                    type="text"
                    [required]="true"
                  />
                </div>
              </div>
              <div class="row form-field">
                <div class="col-md-5">
                  <label class="float-right">PO Number</label>
                </div>
                <div class="col-md-7">
                  <input
                    name="poNumber"
                    [(ngModel)]="poNumber"
                    class="form-input"
                    type="text"
                    [required]="true"
                  />
                </div>
              </div>
              <div class="row form-field">
                <div class="col-md-5">
                  <label class="float-right">PO Value</label>
                </div>
                <div class="col-md-7">
                  <input
                    name="poValue"
                    [(ngModel)]="poValue"
                    class="form-input"
                    type="text"
                    [required]="true"
                  />
                </div>
              </div>
              <div class="row form-field">
                <div class="col-md-5">
                  <label class="float-right">Delivery Date</label>
                </div>
                <div class="col-md-7">
                  <input
                    name="date"
                    [(ngModel)]="date"
                    class="form-input"
                    type="date"
                    [required]="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEditable" style="text-align: center; padding: 10px">
            <p-button
              type="button"
              (click)="display = false"
              styleClass="primeng-button reject"
              label="Cancel"
            ></p-button>
            <p-button
              type="submit"
              [disabled]="prUpdateForm.invalid == true"
              (click)="prUpdateForm.invalid ? toastrAlert() : ''"
              styleClass="primeng-button accept"
              label="Submit"
            ></p-button>
          </div>
        </form>
      </p-dialog>
    </div>
  </div>
</div>
