import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FilterService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicGridComponent implements OnInit {
  @Input() gridCols: any;
  @Input() gridData: any;
  @Input() checkboxShow: any;
  @Input() infoIconShow: any;
  @Input() showEdit: any;
  @Input() showDelete: any;
  @Input() showView: any;
  @Input() showClone: any;
  @Input() showFilter: any;
  @Input() showActive: any;
  @Input() showEmail: any;
  @Input() showReview: any;
  @Input() dataKey: any;
  @Input() adminAction: any;

  @Output() filterData = new EventEmitter();
  @Output() selectRow = new EventEmitter();
  @Output() sendSelectedRows = new EventEmitter();

  cupsRequestData: any;
  apiResp: any;
  defaultWidth!: '100%';
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  title = '';
  selectedRows: any;
  accountManager: any;
  bu: any;
  filters: any = [];
  orgGridData: any;
  orgCols: any;
  isDistributor: boolean = false;

  constructor(private filterService: FilterService) {}

  ngOnInit() {
    if (this.gridData) {
      this.orgGridData = JSON.parse(JSON.stringify(this.gridData));
      this.accountManager = localStorage.getItem('loginuseremail');
  

      this.filterService.register(
        'custom',
        (
          value: number | null | undefined,
          filter: string | null | undefined
        ): boolean => {
          if (filter === undefined || filter === null || filter.trim() === '') {
            return true;
          }

          if (value === undefined || value === null) {
            return false;
          }

          return parseInt(filter) > value;
        }
      );
      this.orgCols = this.sortSelectedColumns(this.gridCols);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gridCols'])
      this.orgCols = this.sortSelectedColumns(
        changes['gridCols']['currentValue']
      );
  }

  sortSelectedColumns(columsnData: any[]) {
    return columsnData.sort(
      (a: { serial_no: number }, b: { serial_no: number }) => {
        return a.serial_no - b.serial_no;
      }
    );
  }

  sendFilterData(value: any, filter: any) {
    let filterObj = {
      value,
      filter,
    };
    this.filterData.emit(filterObj);
  }

  onSelectRow(rowData: any, type: any) {
    let rowObject = { rowData, type };
    this.selectRow.emit(rowObject);
  }

  onDeleteRow(rowData: any, type: any) {
    let rowObject = { rowData, type };
    this.selectRow.emit(rowObject);
  }

  checkSelectedRow(_event: any) {
    this.sendSelectedRows.emit(this.selectedRows);
  }

  checkDateField(fieldName: string | string[]) {
    if (fieldName.includes('Date')) {
      return true;
    }
    return false;
  }

  buildFilter(event: { target: { value: any } }, fieldName: any) {
    let filterObj = {
      fieldName: fieldName,
      value: event.target.value,
    };
    let fFieldName = this.filters.find(
      (f: { fieldName: any }) => f.fieldName === fieldName
    );
    if (!fFieldName) {
      if (filterObj.value != '') {
        this.filters.push(filterObj);
      } else {
        this.filters = this.filters.filter(
          (f: { fieldName: any }) => f.fieldName === fieldName
        );
      }
    } else {
      if (filterObj.value != '') {
        this.filters.forEach((f1: { fieldName: any; value: any }) => {
          if (f1.fieldName === fieldName) {
            f1.value = event.target.value;
          }
        });
      } else {
        this.filters = this.filters.filter(
          (f: { fieldName: any }) => f.fieldName !== fieldName
        );
      }
    }
    this.sortGridData();
  }

  sortGridData() {
    let tempOrgGridData = JSON.parse(JSON.stringify(this.orgGridData));

    this.filters.forEach(
      (filter: { fieldName: string | number; value: moment.MomentInput }) => {
        tempOrgGridData = tempOrgGridData.filter(
          (f: { [x: string]: moment.MomentInput }) =>
            moment(f[filter.fieldName]).format('L') ===
            moment(filter.value).format('L')
        );
      }
    );
    this.gridData = JSON.parse(JSON.stringify(tempOrgGridData));
  }
}
