import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../components/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  constructor(private _http: HttpClient) {}
  CreateApprovers(obj: any): Observable<any> {
    const headers = new HttpHeaders().set('PPlanId', obj[0].ProcurementplanId.toString());
    return this._http
      .post(`${environment.CreateApprovers}`, obj,{headers:headers})
      .pipe(map((response) => response));
  }
  GetApprovers(ProcuremenPlanId: any, PackageId: any): Observable<any> {
    const headers = new HttpHeaders().set('PackageId', PackageId.toString());
    return this._http
      .get(
        `${environment.GetApprovers}?procurementId=${ProcuremenPlanId}&PackageId=${PackageId}`,
        {
          headers: headers,
        }
      )
      .pipe(map((response) => response));
  }
  ApproveOrRejectProcurement(obj: any): Observable<any> {
    
    const headers = new HttpHeaders().set('PPlanId', obj.ProcurementplanId.toString());
    return this._http.post(`${environment.ApproveOrRejectProcurement}`, obj,{headers:headers})
      .pipe(map((response) => response))
  }
  getallusers() {
    return this._http
      .get<ApiResponse>(`${environment.getallusers}`)
      .pipe(map((response) => response));
  }
}
