<div id="procurementDetails">
  <div class="container-fluid" style="margin-top: 30px; margin-bottom: 88px">
    <div class="row">
      <div class="col-md-12">
        <p-button
          id="raiseConcern"
          *ngIf="procurementDetails['popUp']"
          type="submit"
          styleClass="primeng-button Save"
          (click)="goToComments()"
          label="Raise a concern"
        ></p-button>

        <p-tabView id="detailsPanel">
          <p-tabPanel header="Plan Info">
            <div
              *ngIf="
                !procurementDetails['edit'] || !planInfoSectionObj.planInfo
              "
              class="col-md-12"
            >
              <div class="row" *ngFor="let label of labelData; let i = index">
                <div class="w-50">
                  <div class="form-label">
                    <label class="w-20 col-form-label">{{
                      label.header
                    }}</label>
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-field">
                    <span *ngIf="formData && label.field != 'Tics'">
                      {{ formData[label.field] | checkType }}</span
                    >
                    <span *ngIf="formData && label.field == 'Tics'">
                      {{ formData[label.field] | checkType | currency }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="row button-group">
                  <div class="w-100">
                    <div class="flex-div">
                      <p-button
                        (click)="updatePlan()"
                        type="button"
                        styleClass="primeng-button cancel"
                        label="Close"
                        *ngIf="this.closePlanButton"
                      ></p-button>
                      <p-button
                        (click)="updatePlan()"
                        type="button"
                        styleClass="primeng-button accept"
                        label="Re-Open"
                        *ngIf="this.reopenPlanButton"
                      ></p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-procurement1
              *ngIf="procurementDetails['edit'] && planInfoSectionObj.planInfo"
              [editable]="true"
              [procurementId]="procurementDetails['procurementId']"
            ></app-procurement1>
          </p-tabPanel>
          <p-tabPanel header="Approvers">
            <div
              *ngIf="
                !procurementDetails['edit'] ||
                !planInfoSectionObj.approvers ||
                (approverData != undefined && approverData.length > 0)
              "
            >
              <div class="col-md-12" *ngIf="approverData">
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label">Approval Type</label>
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <span>{{
                        approverData[0]?.Approvaltype | checkApprovalType
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  *ngFor="let data of approverData; let i = index"
                >
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >Approver {{ i + 1 }}</label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <span> {{ data.Name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 emptyMessage" *ngIf="!approverData">
                No approvers added
              </div>
            </div>
            <app-approval-strategy
              *ngIf="
                procurementDetails['edit'] &&
                planInfoSectionObj.approvers &&
                (approverData == undefined || approverData.length == 0)
              "
              [editable]="true"
              [ProcurementPlanId]="procurementDetails['procurementId']"
            >
            </app-approval-strategy>
          </p-tabPanel>
          <p-tabPanel header="Packages">
            <div class="col-md-12" style="margin-top: 15px">
              <div class="row">
                <p-accordion [multiple]="true">
                  <p-accordionTab
                    *ngFor="let data of accordionHeader"
                    header="{{
                      data.PackageDiscipline | checkPackage: data.PackageName
                    }}"
                  >
                    <ng-template pTemplate="content">
                      <div class="row">
                        <div>
                          <app-info-tabs
                            *ngIf="!procurementDetails['edit']"
                            [packageId]="convertBtoA(data.Id)"
                            [procurementId]="
                              convertBtoA(procurementDetails['procurementId'])
                            "
                            [packageType]="convertBtoA(1)"
                          >
                          </app-info-tabs>
                          <app-info-tabs
                            [editable]="true"
                            *ngIf="procurementDetails['edit']"
                            [packageId]="convertBtoA(data.Id)"
                            [procurementId]="
                              convertBtoA(procurementDetails['procurementId'])
                            "
                            [packageType]="convertBtoA(1)"
                          ></app-info-tabs>
                        </div>
                      </div>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
            <div class="col-md-12 emptyMessage" *ngIf="!accordionHeader.length">
              No packages added
            </div>
          </p-tabPanel>
          <p-tabPanel header="Comments">
            <app-plan-comments
              [editable]="planInfoSectionObj.comments"
              [hideNewComment]="true"
            ></app-plan-comments>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
