import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-pr-details',
  templateUrl: './pr-details.component.html',
  styleUrls: ['./pr-details.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PrDetailsComponent implements OnInit {
  @Input() packageId: any;
  @Input() editable: any;
  @Input() prdetailsflag: any = true;
  constructor(
    private datePipe: DatePipe,
    private _service: ProcurementService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  dataKey = 'ID';
  prDetails: any = [];
  packageInfoDetails: any;
  cols: any;
  selectedRow: any;
  prVendor!: any;
  poNumber!: any;
  poValue!: any;
  PRNumber!: any;
  ItemNumber!: any;
  date!: any;
  display: any;
  isEditable = false;
  ngOnInit(): void {
    this.packageInfoDetails = {
      procurementId: this.route.snapshot.params['procurementId'],
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'],
    };
    this.packageId = parseInt(this.packageInfoDetails['packageId']);

    this.generateHeader();
    this.getData();
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  generateHeader() {
    this.cols = [
      {
        header_id: 1,
        field: 'PurchaseNumber',
        header: 'PR Number',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
      {
        header_id: 2,
        field: 'VendorName',
        header: 'Vendor Name',
        isHyperlink: true,
        is_show: true,
        is_editable: false,
        serial_no: 2,
      },
      {
        header_id: 3,
        field: 'POValue',
        header: 'PO Value',
        is_show: true,
        is_editable: false,
        serial_no: 3,
      },
      {
        header_id: 4,
        field: 'PONumber',
        header: 'PO Number',
        is_show: true,
        is_editable: false,
        serial_no: 4,
      },
      {
        header_id: 5,
        field: 'Date',
        header: 'Delivery Date',
        is_show: true,
        is_editable: false,
        serial_no: 5,
      },
      {
        header_id: 6,
        field: 'ID',
        header: 'Id',
        is_show: false,
        is_editable: false,
        serial_no: 1,
      },
    ];
  }

  selectedInfo(value: any) {
    this.selectedRow = value.rowData;
    this.display = true;
    this.PRNumber = parseInt(this.selectedRow.PurchaseNumber);
    this.prVendor = this.selectedRow.VendorName;
    this.poNumber = this.selectedRow.PONumber;
    this.poValue = this.selectedRow.POValue;
    this.isEditable = this.selectedRow.IsEditable;
    this.date = formatDate(
      new Date(this.selectedRow.Date),
      'yyyy-MM-dd',
      'en-US'
    );
  }

  onSave() {
    let apiRequest: any = {};
    apiRequest['Date'] = this.date;
    apiRequest['POValue'] = this.poValue;
    apiRequest['VendorName'] = this.prVendor;
    apiRequest['PONumber'] = this.poNumber;
    apiRequest['PurchaseNumber'] = this.PRNumber.toString();
    apiRequest['ID'] = this.selectedRow.ID;
    apiRequest['IsEditable'] = this.selectedRow.IsEditable;
    this._service
      .updatePurchaseNumberDetails(apiRequest)
      .subscribe((res: any) => {
        if (!res['IsError']) {
          this.toastr.success('PR details updated successfully');
          this.refresh(true);
          this.display = false;
        }
      });
  }

  getData() {
    this._service
      .getPurchaseDetailsByPackage(this.packageId)
      .subscribe((response: any) => {
        if (!response['IsError']) {
          this.prDetails = response.response.map((item: any) => {
            return {
              ...item,
              Date: this.datePipe.transform(item.Date, 'shortDate'),
            };
          });
        }
      });
  }

  refresh(value: any) {
    if (value) {
      this.getData();
    }
  }
}
