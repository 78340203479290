import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-plan-comments',
  templateUrl: './plan-comments.component.html',
  styleUrls: ['./plan-comments.component.css'],
})
export class PlanCommentsComponent implements OnInit {
  userId = this.authenticationService.userObj.userEmail;
  ParentId: any;

  newParentComment: string = '';
  reqBody: any;
  apiResp: any;
  @Input() planId: any;
  @Input() comments: any;
  @Input() hideNewComment: boolean = false;
  @Input() deleteReplies: any;
  @Input() editable: any;
  showReplies: boolean = false;
  showReplyDiv: boolean = true;
  deleteReplyDisp: boolean = false;
  replies: any;
  replyQuesId: any;
  selQuesReplyId = '';
  selReply = '';
  showReply: boolean = false;
  quesReplyText = '';
  replyFunctionality = 'Show';
  pageNumber = 0;
  rows = 10;
  loadReplyId: any;
  searchText = '';
  sResultsDiv: boolean = false;
  resultsCount = 0;
  contributor: boolean = true;
  dispLoginDialog: boolean = false;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private service: ProcurementService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  procurementDetails: any;
  ngOnInit() {
    this.procurementDetails = {
      procurementId: atob(this.route.snapshot.queryParams['ProcurementId']),
      popUp:
        this.route.snapshot.queryParams['Inaccurate'] == 'True' ? true : false,
      edit: this.route.snapshot.queryParams['edit'] == 'true' ? true : false,
    };
    if (!this.planId) {
      this.planId = parseInt(this.procurementDetails['procurementId']);
    }
    if (!this.comments) {
      this.getComments();
    }
  }

  getComments() {
    if (!this.ParentId) this.ParentId = 0;

    this.spinner.show();
    this.service
      .PlanGetComments(this.planId, this.ParentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;
        this.apiResp.response?.sort((a: any, b: any) => {
          if (a['Date'] < b['Date']) return 1;
          else if (a['Date'] > b['Date']) return -1;
          return 0;
        });
        this.comments = this.formatDate(this.apiResp.response);
        this.resultsCount = this.comments.length;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      });
  }

  formatDate(commentsArray: any) {
    commentsArray.forEach((comment: any) => {
      comment.formattedDate = new Date(comment.Date)
        .toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/ /g, '-');
    });
    return commentsArray;
  }

  displayReply(data: any) {
    if (this.contributor) {
      this.showReply = true;
      this.selQuesReplyId = data.CommentId;
    }
  }

  cancelReply() {
    this.showReply = false;
    this.selQuesReplyId = '';
  }

  viewAnswers(CommentId: any) {
    this.service
      .PlanGetComments(this.planId, CommentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;

        this.replies = this.formatDate(this.apiResp.response);

        this.replyQuesId = this.replies[0].ParentId;

        this.showReplies = true;
        this.replyFunctionality = 'Hide';
      });
  }

  hideReplies() {
    this.showReplies = false;
    this.replyQuesId = '';
    this.replyFunctionality = 'Show';
  }

  saveComment() {
    this.spinner.show();
    let requestObj = {
      ProcurementId: this.planId,
      Inaccuratecomment: this.newParentComment,
      ParentId: 0,
      CommentId: 0,
      EmailId: this.userId,
      CreatedDate: new Date(),
      RepliesCount: 0,
      createdBy: parseInt(this.authenticationService.userObj.userId),
    };

    this.service.PlanCreateComments(requestObj).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.IsError) {
        this.toastr.error('Error');
        return;
      }
      this.getComments();
      this.newParentComment = '';
    });
  }

  submitAnswer(parentComment: any) {
    this.reqBody = {
      ProcurementId: this.planId,
      CommentId: 0,
      Inaccuratecomment: this.quesReplyText,
      ParentId: parentComment.CommentId,
      EmailId: this.userId,
      CreatedDate: new Date(),
      RepliesCount: 0,
      CreatedBy: parseInt(this.authenticationService.userObj.userId),
    };

    this.spinner.show();
    this.service.PlanCreateComments(this.reqBody).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.IsError) {
        this.toastr.error('Error');
        return;
      }
      this.cancelReply();
      this.ToastMsg('Success', 'Reply Sent', 'Success!');
      this.quesReplyText = '';
      this.viewAnswers(parentComment.CommentId);
    });
  }

  ToastMsg(
    ToastType: string,
    FirstMessage: string | undefined,
    UserMessage: string | undefined
  ) {
    if (ToastType == 'Error') {
      this.toastr.error(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Warning') {
      this.toastr.warning(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Success') {
      this.toastr.success(FirstMessage, UserMessage, {
        closeButton: true,
      });
    }
  }
}
