import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';
import { TagsService } from 'src/app/services/dataServices/tags.service';

@Component({
  selector: 'app-upload-tags',
  templateUrl: './upload-tags.component.html',
  styleUrls: ['./upload-tags.component.css'],
})
export class UploadTagsComponent implements OnInit {
  packageId: string = '';
  fileName: string = '';
  exportColumns: any[] = [];
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private tagService: TagsService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.packageId = this.sharedDataService.packageID;
  }

  onCancel() {
    this.route.navigate(['/tags']);
  }

  downloadTemplate() {
    this.exportExcel();
  }

  exportExcel() {
    let Heading = [
      [
        'Tag Number',
        'Budget',
        'Quantity',
        'Equipment Type',
        'Risk',
        'Risq Q Rating',
        'Area',
      ],
    ];
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet([]);
      xlsx.utils.sheet_add_aoa(worksheet, Heading);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'Tag');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      fileName + '_import_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  fileChange(_event: any, file: any) {
    this.tempFileObj = {
      LoginUserId: '1',
      PackageId: parseInt(this.packageId),
      fileName: file.files[0].name.toString(),
      fileType: file.files[0].type.toString(),
      fileSize: file.files[0].size.toString(),
      fileAsBase64: '',
    };
    this.fileName = `(${this.tempFileObj.fileName})`;
    let reader = new FileReader();
    reader.readAsDataURL(file.files[0]);
    reader.onload = () => {
      this.tempFileObj.fileAsBase64 =
        reader.result == null ? '' : reader.result.toString().split(',')[1];
    };
  }
  tempFileObj: any;
  uploadFile() {
    this.tagService.Uploadtagdetails(this.tempFileObj).subscribe({
      next: (_data) => {
        this.toastr.success('File got uploaded.');
        this.route.navigateByUrl('packages');
      },
    });
  }
}
