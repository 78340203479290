import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailLinkServiceService {
  constructor(private location: Location) {}
  private static emailLinkURL = '';

  checkEmailLInkURL() {
    return EmailLinkServiceService.emailLinkURL;
  }
  resetEmailLinkURL() {
    EmailLinkServiceService.emailLinkURL = '';
  }
  setEmailLinkURL() {
    EmailLinkServiceService.emailLinkURL = this.location.path();
  }
}
