import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
})
export class CommentComponent implements OnInit {
  userId = parseInt(this.authenticationService.userObj.userId);
  parentId: any;

  newParentComment: string = '';
  reqBody: any;
  apiResp: any;
  @Input() packageId: any;
  @Input() comments: any;
  @Input() hideNewComment: boolean = false;
  @Input() deleteReplies: any;
  @Input() editable: any;
  showReplies: boolean = false;
  showReplyDiv: boolean = true;
  deleteReplyDisp: boolean = false;
  replies: any;
  replyQuesId: any;
  selQuesReplyId = '';
  selReply = '';
  showReply: boolean = false;
  quesReplyText = '';
  replyFunctionality = 'Show';
  pageNumber = 0;
  rows = 10;
  loadReplyId: any;
  searchText = '';
  sResultsDiv: boolean = false;
  resultsCount = 0;
  contributor: boolean = true;
  dispLoginDialog: boolean = false;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private service: ProcurementService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  packageInfoDetails: any;
  ngOnInit() {
    this.packageInfoDetails = {
      procurementId: this.route.snapshot.params['procurementId'],
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'],
    };
    this.packageId = parseInt(this.packageInfoDetails['packageId']);

    if (!this.comments) {
      this.getComments();
    }
  }

  getComments() {
    if (!this.parentId) this.parentId = 0;

    this.spinner.show();
    this.service
      .GetComments(this.packageId, this.parentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;
        this.apiResp.response?.sort((a: any, b: any) => {
          if (a['ResponseDate'] < b['ResponseDate']) return 1;
          else if (a['ResponseDate'] > b['ResponseDate']) return -1;
          return 0;
        });

        this.comments = this.formatDate(
          this.apiResp.response ? this.apiResp.response : []
        );
        this.resultsCount = this.comments.length;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      });
  }

  formatDate(commentsArray: any) {
    commentsArray?.forEach((comment: any) => {
      comment.formattedDate = new Date(comment.ResponseDate)
        .toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/ /g, '-');
    });
    return commentsArray;
  }

  displayReply(data: any) {
    if (this.contributor) {
      this.showReply = true;
      this.selQuesReplyId = data.ResponseId;
    }
  }

  cancelReply() {
    this.showReply = false;
    this.selQuesReplyId = '';
  }

  viewAnswers(commentId: any) {
    this.service
      .GetComments(this.packageId, commentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;

        this.replies = this.formatDate(
          this.apiResp.response ? this.apiResp.response : []
        );
        this.replyQuesId = this.replies[0].ParentId;
        this.showReplies = true;
        this.replyFunctionality = 'Hide';
      });
  }

  hideReplies() {
    this.showReplies = false;
    this.replyQuesId = '';
    this.replyFunctionality = 'Show';
  }

  saveComment() {
    this.spinner.show();
    let requestObj = {
      PackageId: this.packageId,
      Response: this.newParentComment,
      ParentId: 0,
      ResponseId: 0,
      ResponseDate: new Date(),
      QueryRepliescount: 0,
      ResponseBy: this.authenticationService.userObj.userId,
    };

    this.service.CreateComments(requestObj).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.IsError) {
        this.toastr.error('Error');
        return;
      }
      this.getComments();
      this.newParentComment = '';
    });
  }

  submitAnswer(parentComment: any) {
    this.reqBody = {
      PackageId: this.packageId,
      ResponseId: 0,
      Response: this.quesReplyText,
      ParentId: parentComment.ResponseId,
      ResponseDate: new Date(),
      QueryRepliescount: 0,
      ResponseBy: this.authenticationService.userObj.userId,
    };

    this.spinner.show();
    this.service.CreateComments(this.reqBody).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.IsError) {
        this.toastr.error('Error');
        return;
      }
      this.cancelReply();
      this.ToastMsg('Success', 'Reply Sent', 'Success!');
      this.quesReplyText = '';
      this.viewAnswers(parentComment.ResponseId);
    });
  }

  ToastMsg(
    ToastType: string,
    FirstMessage: string | undefined,
    UserMessage: string | undefined
  ) {
    if (ToastType == 'Error') {
      this.toastr.error(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Warning') {
      this.toastr.warning(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Success') {
      this.toastr.success(FirstMessage, UserMessage, {
        closeButton: true,
      });
    }
  }
}
