import { Component, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PurchasingGroupServices } from 'src/app/services/purchasing-group.service';
import { PurchasingGroupData } from './purchasing-group.data';

@Component({
  selector: 'app-purchasing-group-popup',
  templateUrl: './purchasing-group-popup.component.html',
  styleUrls: ['./purchasing-group-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PurchasingGroupPopupComponent {
  purchasing_group: any;
  purchasing_group_description: any;

  constructor(
    private service: PurchasingGroupServices,
    private toastr: ToastrService,
    public config: DynamicDialogConfig
  ) {}

  onSave() {
    let loginName: any = '';
    loginName = localStorage.getItem('loginId');
    let data: PurchasingGroupData = new PurchasingGroupData();
    data.PurchasingGroupCode = this.purchasing_group;
    data.PurchasingGroupDescription = this.purchasing_group_description;
    data.Id = 0;
    data.IsActive = true;
    data.ModifiedBy = loginName;

    this.service.savePurchasingGroup(data).subscribe({
      next: (_response: any) => {
        this.toastr.success('Record created Successfully');
      },
      error: (error) => {
        this.toastr.error(error.error);
      },
    });
  }

  clearData() {
    this.purchasing_group = '';
    this.purchasing_group_description = '';
  }
}
