import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcurementPlanWorkflowService } from '../../services/dataServices/procurement-plan-workflow.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';


@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html'
})
export class SamlComponent implements OnInit {
  returnUrl: any;
  returnUrlpath: any;
  usertoken: any;
  constructor(private route: ActivatedRoute, private router: Router, private _service: LoggedInUserService, private _procservice: ProcurementPlanWorkflowService) { }

  ngOnInit() {
    this
      .route
      .queryParams
      .subscribe(params => {
        if (params['returnUrl'] != undefined) {
          sessionStorage.setItem('returnUrl', params['returnUrl']);
        }
        this.usertoken = params['Username'];
        if (this.usertoken == undefined || this.usertoken == '') {
          this.SAMLService();
        }
        else {
          if (parseInt(params['userid'] + '') > 0) {
            sessionStorage.setItem('loginuser', atob(params['Username']));

            this.returnUrl = sessionStorage.getItem('returnUrl');
            if (this.returnUrl == undefined || this.returnUrl == '') {
              this.router.navigate(['/home']);
            }
            else {
              this.returnUrl = decodeURIComponent(this.returnUrl);
              this.returnUrlpath = sessionStorage.getItem('returnUrlpath');
              this.router.navigateByUrl(this.returnUrl);
            }
          }
        }
      });
  }

  SAMLService() {
    this._service.getSAMLService().subscribe((result: any) => {
      if (!result['isError']) {
        console.log('route ', result.strResponse.toString());
        window.location.href = result.strResponse.toString();
      } else {
        console.log('res ', result);
        this._procservice.displayToastr(result);
      }
    },
      (error: any) => {
        console.log('error ', error);
      });
  }

}
