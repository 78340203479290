import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { EnterPrNumberComponent } from './enter-pr-number/enter-pr-number.component';

@Component({
  selector: 'app-pr-number',
  templateUrl: './pr-number.component.html',
  styleUrls: ['./pr-number.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class PrNumberComponent implements OnInit {
  packageData: any;
  cols: any;
  selectedRow: any = [];
  dataKey = 'Id';
  @Input() editable: any = false;
  @Input() packageId: any;
  @Output() reload = new EventEmitter();
  @Input() prdetailsflag: any = true;
  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.editable) {
      this.selectedRow = [{ Id: this.packageId }];
    }
    this.generateHeader();
    this.getData();
  }

  generateHeader() {
    this.cols = [
      {
        header_id: 1,
        field: 'WBS',
        header: 'Project Number',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
      {
        header_id: 2,
        field: 'ProjectName',
        header: 'Project Name',
        is_show: true,
        is_editable: false,
        serial_no: 2,
      },
      {
        header_id: 4,
        field: 'PackageDiscipline',
        header: 'Package Discipline',
        is_show: true,
        is_editable: false,
        serial_no: 4,
      },
      {
        header_id: 3,
        field: 'PackageName',
        header: 'Package Name',
        is_show: true,
        is_editable: false,
        serial_no: 3,
      },
      {
        header_id: 20,
        field: 'Status',
        header: 'Status',
        is_show: true,
        is_editable: false,
        serial_no: 20,
      },
      {
        header_id: 21,
        field: 'Id',
        header: 'Id',
        is_show: false,
        is_editable: false,
        serial_no: 21,
      },
    ];
  }

  getData() {
    this.spinner.show();
    this._service
      .getPackageDetails(this.authenticationService.userObj.userId)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.packageData = response.response.filter(
            (x: any) => x.Status == 'Approved' || x.Status == 'Purchased'
          );
        }
      });
  }

  onSelectRow(event: any) {
    this.selectedRow = event;
  }

  addPRNumber() {
    let ref;

    if (!this.selectedRow.length) this.toastr.warning('Please select a row');
    else if (this.selectedRow.length >= 2)
      this.toastr.warning('Please select a single row');
    else {
      ref = this.dialogService.open(EnterPrNumberComponent, {
        header: 'Enter PR Number',
        width: '60%',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        baseZIndex: 10000,
        data: { selectedRow: this.selectedRow },
      });
      ref.onClose.subscribe((item: any) => {
        if (item) {
          this.reload.emit(true);
        }
      });
    }
  }
}
