import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { ApprovalStrategyComponent } from './components/approval-strategy/approval-strategy.component';
import { HomeComponent } from './components/home/home.component';
import { MyPackagesComponent } from './components/my-packages/my-packages.component';
import { PackageCreationComponent } from './components/package-creation/package-creation.component';
import { CreateProcurementComponent } from './components/createprocurement/createprocurement.component';
import { ManualTagsComponent } from './components/tags/manual-tags/manual-tags.component';
import { TagsComponent } from './components/tags/tags.component';
import { ProcurementComponent } from './components/procurement/procurement.component';
import { UploadTagsComponent } from './components/tags/upload-tags/upload-tags.component';
import { RfiComponent } from './components/my-packages/rfi/rfi.component';
import { PackageinfoComponent } from './components/packageinfo/packageinfo.component';
import { ProcurementDetailsComponent } from './components/procurement-details/procurement-details.component';
import { SchedulerGridComponent } from './components/my-packages/scheduler-grid/scheduler-grid.component';
import { InfoTabsComponent } from './components/info-tabs/info-tabs.component';
import { AuthenticationGuard } from './_helpers/guards/authentication/authentication.guard';
import { ProcurementGridComponent } from './components/procurement-grid/procurement-grid.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { SamlComponent } from './components/saml/saml.component';
import { LoginComponent } from './components/login/login.component';
import { LoginTestComponent } from './components/login-test/login-test.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'saml', pathMatch: 'full', component: SamlComponent },
  //{ path: 'login-test', pathMatch: 'full', component: LoginTestComponent },
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'home' },
  },
  {
    path: 'createprocurement',
    pathMatch: 'full',
    component: CreateProcurementComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'createprocurement' },
  },
  {
    path: 'approval',
    pathMatch: 'full',
    component: ApprovalStrategyComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'createprocurement' },
  },
  {
    path: 'procurementgrid',
    pathMatch: 'full',
    component: ProcurementGridComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'procurementgrid' },
  },
  {
    path: 'procurement',
    pathMatch: 'full',
    component: ProcurementComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'procurement' },
  },
  {
    path: 'strategy',
    pathMatch: 'full',
    component: ApprovalStrategyComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'strategy' },
  },
  {
    path: 'tags',
    pathMatch: 'full',
    component: TagsComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'tags' },
  },
  {
    path: 'manual-tag',
    pathMatch: 'full',
    component: ManualTagsComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'manual-tag' },
  },
  {
    path: 'package',
    pathMatch: 'full',
    component: PackageCreationComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'strategy' },
  },
  {
    path: 'packages',
    pathMatch: 'full',
    component: MyPackagesComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'packageLead' },
  },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'admin' },
  },
  {
    path: 'upload-tags',
    pathMatch: 'full',
    component: UploadTagsComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'upload-tags' },
  },
  {
    path: 'rfi',
    pathMatch: 'full',
    component: RfiComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'rfi' },
  },
  {
    path: 'packageInfo',
    pathMatch: 'full',
    component: PackageinfoComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'packageInfo' },
  },
  {
    path: 'schedulers',
    pathMatch: 'full',
    component: SchedulerGridComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'schedulers' },
  },
  {
    path: 'details',
    component: ProcurementDetailsComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'details' },
  },
  {
    path: 'packageDetails',
    component: InfoTabsComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'packageDetails' },
  },
  {
    path: 'logs',
    pathMatch: 'full',
    component: ChangeLogComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'tabs' },
  },
  {
    path: 'emaillink/:url',
    canActivate: [AuthenticationGuard],
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
