import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';

import { UserService } from 'src/app/services/dataServices/user.service';

@Component({
  selector: 'app-info-tabs',
  templateUrl: './info-tabs.component.html',
  styleUrls: ['./info-tabs.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoTabsComponent implements OnInit {
  edit: boolean = false;
  tabIndex = 0;
  @Input() editable: any;
  @Input() packageId: any;
  @Input() packageType: any;
  @Input() procurementId: any;

  packageInfoSectionObj: {
    packageInfo: boolean;
    history: boolean;
    categoryBuyer: boolean;
    schedulers: boolean;
    comments: boolean;
    prDetails: boolean;
  };
  packageInfoDetails: any;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.packageInfoSectionObj = {
      packageInfo: true,
      history: true,
      categoryBuyer: true,
      schedulers: true,
      comments: true,
      prDetails: true,
    };
  }
  ngOnInit(): void {
    this.packageInfoDetails = {
      procurementId: this.route.snapshot.queryParams['procurementId']
        ? atob(this.route.snapshot.queryParams['procurementId'])
        : atob(this.procurementId),
      packageId: this.route.snapshot.queryParams['packageId']
        ? atob(this.route.snapshot.queryParams['packageId'])
        : atob(this.packageId),
      pkgtypeId: this.route.snapshot.queryParams['pkgtypeId']
        ? atob(this.route.snapshot.queryParams['pkgtypeId'])
        : atob(this.packageType),
    };

    let Inaccurate =
      this.route.snapshot.queryParams['Inaccurate'] != undefined
        ? this.route.snapshot.queryParams['Inaccurate']
        : false;
    if (Inaccurate) {
      this.tabIndex = 4;
    }
    this.procurementId = this.packageInfoDetails.procurementId;
    this.packageId = this.packageInfoDetails.packageId;
    this.packageType = this.packageInfoDetails.pkgtypeId;
    this.edit =
      (this.route.snapshot.queryParams['edit'] == 'True' || this.route.snapshot.queryParams['edit'] =='true')? true : false;
    if (this.edit || this.editable) {
      this.RetrievePackageDetailsSection()
    }
  }
  RetrievePackageDetailsSection(){
    this.userService
        .RetrievePackageDetailsSection(
          this.authenticationService.userObj.userId,
          this.packageInfoDetails.packageId,
          5
        )
        .subscribe((_data: any) => {
          _data.response.forEach((_item: any) => {
            if (_item.SectionName == 'Package Info') {
              this.packageInfoSectionObj.packageInfo = _item.IsEnable;
            } else if (_item.SectionName == 'History') {
              this.packageInfoSectionObj.history = _item.IsEnable;
            } else if (_item.SectionName == 'Category Buyer') {
              this.packageInfoSectionObj.categoryBuyer = _item.IsEnable;
            } else if (_item.SectionName == 'Schedulers') {
              this.packageInfoSectionObj.schedulers = _item.IsEnable;
            } else if (_item.SectionName == 'Comments') {
              this.packageInfoSectionObj.comments = _item.IsEnable;
            } else if (_item.SectionName == 'PR Details') {
              this.packageInfoSectionObj.prDetails = _item.IsEnable;
            }
          });
        });
  }}
