import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { ApiResponse } from '../../components/models/api-response';
import * as saveAs from 'file-saver';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root',
})
export class ProcurementService {
  constructor(private _http: HttpClient) {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, fileName + EXCEL_EXTENSION, { autoBom: false });
  }

  getPackageDetails(userid: any,filter:any=2) {
    return this._http
      .get<ApiResponse>(`${environment.getPackageDetails}?userid=${userid}&statusId=${filter}`)
      .pipe(map((response) => response));
  }

  getProcurementGridDetails(userId: any) {
    return this._http
      .get<ApiResponse>(
        `${environment.getProcurementGridDetails}?UserId=${userId}`
      )
      .pipe(map((response) => response));
  }

  getUsers() {
    return this._http
      .get<ApiResponse>(`${environment.getUsers}`)
      .pipe(map((response) => response));
  }

  postUser(userDetails: any) {
    return this._http
      .post<ApiResponse>(`${environment.postUser}`, userDetails)
      .pipe(map((response) => response));
  }

  sendPackageStatusChange(details: any) {
    return this._http
      .post<ApiResponse>(`${environment.sendPackageStatusChange}`, details)
      .pipe(map((response) => response));
  }

  sendEmailforMoreInformation(details: any) {
    const headers = new HttpHeaders().set(
      'PackageId',
      details.PackageId.toString()
    );
    return this._http
      .post<ApiResponse>(
        `${environment.sendEmailforMoreInformation}`,
        details,
        { headers: headers }
      )
      .pipe(map((response) => response));
  }

  updateInaccurateComments(details: any) {
    const headers = new HttpHeaders().set(
      'PPlanId',
      details.ProcurementId.toString()
    );
    return this._http
      .post<ApiResponse>(`${environment.updateInaccurateComments}`, details, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }

  getPackageInfo(packageId: number) {
    const headers = new HttpHeaders().set('PackageId', packageId.toString());
    return this._http
      .get<ApiResponse>(`${environment.getPackageInfo}?pkgid=${packageId}`, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }

  getProcurementDetails(planId: number) {
    const headers = new HttpHeaders().set('PPlanId', planId.toString());
    return this._http
      .get<ApiResponse>(
        `${environment.getProcurementDetails}?planId=${planId}`,
        {
          headers: headers,
        }
      )
      .pipe(map((response) => response));
  }

  getPackageByPlanId(planId: number) {
    const headers = new HttpHeaders().set('PPlanId', planId.toString());
    return this._http
      .get<ApiResponse>(`${environment.getPackageByPlanId}?planId=${planId}`, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }

  GetApprovelHistrory(procurementid: number, pKgId: number) {
    const headers = new HttpHeaders().set('PPlanId', procurementid.toString());
    return this._http
      .get<ApiResponse>(
        `${environment.GetApprovelHistrory}?procurementid=${procurementid}&pKgId=${pKgId}`,
        {
          headers: headers,
        }
      )
      .pipe(map((approvers) => approvers));
  }

  ApproveOrRejectProcurement(obj: any) {
    const headers = new HttpHeaders().set(
      'PPlanId',
      obj.ProcurementplanId.toString()
    );
    return this._http
      .post<ApiResponse>(`${environment.ApproveOrRejectProcurement}`, obj, {
        headers: headers,
      })
      .pipe(map((approvers) => approvers));
  }
  ApproverorRejectMultipleRecords(obj: any) {
    return this._http
      .post<ApiResponse>(`${environment.ApproverorRejectMultipleRecords}`, obj)
      .pipe(map((approvers) => approvers));
  }

  RetriveMasterdataDetails(packageId: any) {
    const headers = new HttpHeaders().set('PackageId', packageId.toString());
    return this._http
      .get<ApiResponse>(`${environment.RetriveMasterdataDetails}`, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }
  RetrievePackageDetailsbyId(packageId: any) {
    const headers = new HttpHeaders().set('PackageId', packageId.toString());
    return this._http
      .get<ApiResponse>(
        `${environment.RetrievePackageDetailsbyId}?PackageId=${packageId}`,
        {
          headers: headers,
        }
      )
      .pipe(map((response) => response));
  }
  insertcategorybuyerinfodetails(obj: any) {
    const headers = new HttpHeaders().set(
      'PackageId',
      obj.CategoryBuyerInfo.PackageId.toString()
    );
    return this._http
      .post<ApiResponse>(`${environment.insertcategorybuyerinfodetails}`, obj, {
        headers: headers,
      })
      .pipe(map((approvers) => approvers));
  }
  RetriveMasterdataDetailsPackage() {
    return this._http
      .get<ApiResponse>(`${environment.RetriveMasterdataDetailsPackage}`)
      .pipe(map((response) => response));
  }
  RetrivePlantCodeDetails() {
    return this._http
      .get<ApiResponse>(`${environment.RetrivePlantCodeDetails}`)
      .pipe(map((response) => response));
  }
  getallusers() {
    return this._http
      .get<ApiResponse>(`${environment.getallusers}`)
      .pipe(map((response) => response));
  }
  insertProcurementDetails(obj: any) {
    const headers = new HttpHeaders().set('PPlanId', obj.Id.toString());
    return this._http
      .post<ApiResponse>(`${environment.insertProcurementDetails}`, obj, {
        headers: headers,
      })
      .pipe(map((approvers) => approvers));
  }

  GetComments(packageId: any, parentId: any, userId: any) {
    const headers = new HttpHeaders().set('PackageId', packageId.toString());
    return this._http
      .get(
        `${environment.GetComments}?packageId=${packageId}&parentId=${parentId}&UserId=${userId}`,
        { headers: headers }
      )
      .pipe(map((response) => response));
  }

  CreateComments(requestObj: any) {
    const headers = new HttpHeaders().set(
      'PackageId',
      requestObj.PackageId.toString()
    );
    return this._http
      .post(`${environment.CreateComments}`, requestObj, { headers: headers })
      .pipe(map((response) => response));
  }

  PlanGetComments(packageId: any, parentId: any, _userId: any) {
    const headers = new HttpHeaders().set('PPlanId', packageId.toString());
    return this._http
      .get(
        `${environment.PlanGetComments}?planId=${packageId}&parentId=${parentId}`,
        { headers: headers }
      )
      .pipe(map((response) => response));
  }

  PlanCreateComments(requestObj: any) {
    const headers = new HttpHeaders().set(
      'PPlanId',
      requestObj.ProcurementId.toString()
    );
    return this._http
      .post(`${environment.PlanCreateComments}`, requestObj, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }

  insertPurchaseNumberDetails(packageId: any, data: any) {
    return this._http
      .post(`${environment.insertPurchaseNumber}/${packageId}`, data)
      .pipe(map((response) => response));
  }

  updatePurchaseNumberDetails(data: any) {
    return this._http
      .post(`${environment.updatePurchaseNumber}`, data)
      .pipe(map((response) => response));
  }

  getPurchaseDetails(prNumber: any, liNumber: any) {
    return this._http
      .get(`${environment.getPurchaseDetails}/${prNumber}/${liNumber}`)
      .pipe(map((response) => response));
  }

  getPurchaseDetailsByPackage(packageId: any) {
    return this._http
      .get(`${environment.getPurchaseNumberByPackage}/${packageId}`)
      .pipe(map((response) => response));
  }
  UpdatePlanstatus(procurementId: any, status: any, modifiedBy: any) {
    const headers = new HttpHeaders().set('PPlanId', procurementId.toString());
    return this._http
      .get(
        `${environment.UpdatePlanstatus}?procurementId=${procurementId}&status=${status}&modifiedBy=${modifiedBy}`,
        { headers: headers }
      )
      .pipe(map((response) => response));
  }
  RetriveContactdataDetails(contractId: any) {
    return this._http
      .get(`${environment.RetriveContactdataDetails}?Id=${contractId}`)
      .pipe(map((response) => response));
  }
  InsertContactdataDetails(contract: any) {
    return this._http
      .post(`${environment.InsertContactdataDetails}`, contract)
      .pipe(map((response) => response));
  }
  RetrivePlantCodeDetailsForAdmin(contractId: any) {
    return this._http
      .get(`${environment.RetrivePlantCodeDetailsForAdmin}?Id=${contractId}`)
      .pipe(map((response) => response));
  }
  InsertPlantCodeDetails(contract: any) {
    return this._http
      .post(`${environment.InsertPlantCodeDetails}`, contract)
      .pipe(map((response) => response));
  }
  EnablePlantCodeDetails(obj: any) {
    return this._http
      .post(`${environment.EnablePlantCodeDetails}`,obj)
      .pipe(map((response) => response));
  }
  
}
