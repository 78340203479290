import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PackageService } from 'src/app/services/dataServices/package.service';

@Component({
  selector: 'app-planner-info-popup',
  templateUrl: './planner-info-popup.component.html',
  styleUrls: ['./planner-info-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PlannerInfoPopupComponent implements OnInit {
  required_onsite_date: any = '';
  planned_delivery_date: any = '';
  po_need_by_date: any = '';
  rfq_need_by_date: any = '';
  estimated_first_drawing_receipt_aro: string = '';
  estimated_basf_approval: string = '';
  estimated_fabrication_time_arad: string = '';
  requestObj: any;

  constructor(
    private service: PackageService,
    private toastr: ToastrService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  selectedRow: any = [];
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnInit(): void {
    this.selectedRow = this.config.data.selectedRow;
  }

  onSave() {
    for (const element of this.selectedRow) {
      let obj: any = {
        Id: 0,
        RequiredOnsiteDate: this.required_onsite_date,
        PlannedDeliveryDate: this.planned_delivery_date,
        PoneedbyDate: this.po_need_by_date,
        RfqneedbyDate: this.rfq_need_by_date,
        EstimatedfirstdrawingreceiptAro:
          this.estimated_first_drawing_receipt_aro,
        EstimatedbasfApproval: this.estimated_basf_approval,
        EstimatedfabricationtimeArad: this.estimated_fabrication_time_arad,
        PackageId: element['Id'],
        ModifiedBy: localStorage.getItem('userId'),
      };

      this.service.saveScheduler(obj).subscribe((response: any) => {
        if (!response['IsError']) {
          this.toastr.success('Scheduler got saved.');
          this.ref.close();
        }
      });
    }
  }
  setPlannerDates() {
    let tempDate = new Date(this.required_onsite_date);

    if (
      tempDate.toString() != 'Invalid Date' &&
      !Number.isNaN(Number(this.estimated_first_drawing_receipt_aro)) &&
      !Number.isNaN(Number(this.estimated_basf_approval)) &&
      !Number.isNaN(Number(this.estimated_fabrication_time_arad))
    ) {
      let numWeeks =
        Number(this.estimated_first_drawing_receipt_aro) +
        Number(this.estimated_basf_approval) +
        Number(this.estimated_fabrication_time_arad);

      let required_onsite_date_now = new Date(this.required_onsite_date);
      required_onsite_date_now.setDate(
        required_onsite_date_now.getDate() - numWeeks * 7
      );
      this.po_need_by_date = required_onsite_date_now
        .toISOString()
        .split('T')[0];

      let required_rfq_need_by_date = new Date(this.po_need_by_date);
      required_rfq_need_by_date.setDate(
        required_rfq_need_by_date.getDate() - 6 * 7
      );
      this.rfq_need_by_date = required_rfq_need_by_date
        .toISOString()
        .split('T')[0];
    }
  }
}
