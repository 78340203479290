export class CategoryData {
  Id: number = 0;
  PurchasingGroup: string = '';
  PurchasingGroupId: number = 0;
  PurchasingGroupDescription: string = '';
  PurchasingGroupCategoryId: number = 0;
  CategoryName: string = '';
  Buyer: string = '';
  Email: string = '';
  BuyerId: number = 0;
  IsActive: boolean = true;
  ModifiedBy: string = '';
  ModifiedDate?: any;
}
