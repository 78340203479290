<div id="category-component">
  <form (ngSubmit)="onSave()">
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="purchasingGroup"
          >Purchasing Group</label
        >
      </div>
      <div class="col-md-6">
        <p-dropdown
          (onChange)="updateDescription()"
          id="purchasingGroupDD"
          styleClass="primeng-dropdown"
          [options]="purchasingGroupsDD"
          name="purchasing_group"
          optionLabel="MasterData"
          [filter]="true"
          filterBy="MasterData"
          [showClear]="true"
          placeholder="Select Purchasing Group"
          [(ngModel)]="purchasing_group"
        ></p-dropdown>
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="purchasingGroupDescription"
          >Purchasing Group Description</label
        >
      </div>
      <div class="col-md-6">
        <input
          readonly="true"
          name="purchasing_group_description"
          [(ngModel)]="purchasing_group_description"
          name="purchasing_group_description"
          class="form-input"
          pInputText
        />
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="category">Category</label>
      </div>
      <div class="col-md-6">
        <p-dropdown
          id="CategoryDD"
          name="category"
          styleClass="primeng-dropdown"
          [options]="categoryDD"
          [(ngModel)]="category"
          optionLabel="MasterData"
          [filter]="true"
          filterBy="MasterData"
          [showClear]="true"
          placeholder="Select Category"
        ></p-dropdown>
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="buyer">Buyer</label>
      </div>
      <div class="col-md-6">
        <p-dropdown
          id="usersDD"
          name="buyer"
          styleClass="primeng-dropdown"
          (onChange)="updateUser()"
          [options]="usersDD"
          [(ngModel)]="buyer"
          optionLabel="MasterData"
          [filter]="true"
          filterBy="MasterData"
          [showClear]="true"
          placeholder="Select user"
        ></p-dropdown>
      </div>
    </div>
    <div class="row form-field">
      <div class="col-md-6">
        <label class="float-right" for="buyerEmail">Buyer Email</label>
      </div>
      <div class="col-md-6">
        <input
          [(ngModel)]="buyer_email"
          name="buyer_email"
          class="form-input"
          pInputText
          readonly="true"
        />
      </div>
    </div>
    <div class="row" style="text-align: center">
      <p-button
        type="submit"
        styleClass="primeng-button accept"
        label="Save"
      ></p-button>
    </div>
  </form>
</div>
