import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { CategoryBuyerService } from 'src/app/services/dataServices/category-buyer.service';
import { PackageService } from 'src/app/services/dataServices/package.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-category-buyer-tab',
  templateUrl: './category-buyer-tab.component.html',
  styleUrls: ['./category-buyer-tab.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoryBuyerTabComponent implements OnInit, AfterViewInit {
  constructor(
    private _service: CategoryBuyerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private packageService: PackageService,
    private procurementService: ProcurementService,
    private authenticationService: AuthenticationService
  ) {}

  categoryBidder: any;

  @Input() packageId: any;
  @Input() packageType: any;
  @Input() procurementId: any;
  @Input() editable: boolean = false;
  packageInfoDetails: any = {};
  packageInfoResponse: any = {};
  packageHistoryResponse: any = [];
  other_Contract_Requirements = '';
  materialGroupsArray: any = [];
  suggested_strategy_List: any = [];
  contractRequirementsList: any = [];
  contract_status: any = [];
  bidder_list: string = '';
  new_bidder: string = '';
  recommended_BidderList: Array<{ name: string }> = [];
  recommended_bidder_list: Array<string> = [];
  blockSpace: RegExp = /[^\s]/;
  showAddField: boolean = false;

  ngOnInit(): void {
    this.packageInfoDetails = {
      procurementId:
        this.route.snapshot.params['procurementId'] || this.procurementId,
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'] || this.packageType,
    };
  }

  ngAfterViewInit(): void {
    let observObj: any = {};
    observObj['getMasterDataDetails'] =
      this.packageService.getMasterDataDetails();
    observObj['RetriveMasterdataDetails'] =
      this.procurementService.RetriveMasterdataDetails(
        this.packageInfoDetails.packageId
      );
    observObj['RetrieveCategorypackageDetailsById'] =
      this._service.RetrieveCategorypackageDetailsById(
        this.packageInfoDetails.packageId
      );
    this.forkCall(observObj);
  }
  forkCall(observObj: any) {
    forkJoin(observObj).subscribe({
      next: (_data: any) => {
        if (_data.getMasterDataDetails !== undefined) {
          let data = _data.getMasterDataDetails;

          for (const element of data.response) {
            this.materialGroupsArray.push({
              name: element.CategoryName,
              code: element.Id?.toString(),
            });
          }
        }
        if (_data.RetriveMasterdataDetails !== undefined) {
          let response = _data.RetriveMasterdataDetails.response;

          for (const element of response.SuggestedStrategies) {
            this.suggested_strategy_List.push({
              Name: element.Name,
              Id: element.Id?.toString(),
            });
          }

          for (const element of response.ContractRequirements) {
            this.contractRequirementsList.push({
              Name: element.Name,
              Id: element.Id?.toString(),
            });
          }
          this.contract_status = response.ContractStatuses;
        }
        if (_data.RetrieveCategorypackageDetailsById !== undefined) {
          let data = _data.RetrieveCategorypackageDetailsById;

          this.packageInfoResponse = data.response?.Item1;
          this.categoryBidder = data.response?.Item2;
          this.categoryBidder.forEach((x: any) => {
            x.ShopAuditRequired = x.ShopAuditRequired?.toString();
            x.ContractStatsus = this.editable
              ? this.contract_status.filter(
                  (item: any) => item.Name == x.ContractStatsus
                )[0]?.Id
              : this.contract_status.filter(
                  (item: any) => item.Name == x.ContractStatsus
                )[0]?.Name;
            // this.recommended_BidderList.push({ name: x.CategoryBuyerBidder })
            this.recommended_bidder_list.push(x.CategoryBuyerBidder);
          });
          this.packageInfoResponse.RecommendedBidderList =
            this.packageInfoResponse.RecommendedBidderList.map((_item: any) => {
              return { name: _item };
            });
          this.packageInfoResponse.RecommendedBidderList?.forEach(
            (item: any) => {
              this.recommended_BidderList.push({ name: item.name });
            }
          );

          this.bidder_list = this.packageInfoResponse.SuggestedBidderList;

          this.packageInfoResponse.ApprovedSuggestedStratgy =
            this.packageInfoResponse.ApprovedSuggestedStratgy?.toString();

          this.packageInfoResponse.category = {
            name: this.packageInfoResponse.CategoryName,
            code: this.materialGroupsArray.filter(
              (x: any) => x.name == this.packageInfoResponse.CategoryName
            )[0]?.code,
          };

          this.packageInfoResponse.strategy = {
            Name: this.packageInfoResponse.StrategyName,
            Id: this.suggested_strategy_List?.filter(
              (x: any) => x.Name == this.packageInfoResponse.StrategyName
            )[0]?.Id,
          };

          this.packageInfoResponse.contract = {
            Name: this.packageInfoResponse.ContractName,
            Id: this.contractRequirementsList?.filter(
              (x: any) => x.Name == this.packageInfoResponse.ContractName
            )[0]?.Id,
          };
        }
      },
    });
  }
  addBidder() {
    let newBidderList = this.new_bidder.split(',').map((item) => {
      return { name: item };
    });
    let dupFlag = false;
    for (const item of newBidderList) {
      if (
        this.packageInfoResponse.RecommendedBidderList?.filter((data: any) => {
          return data.name.toUpperCase() == item.name.toUpperCase();
        }).length > 0
      ) {
        dupFlag = true;
      } else {
        this.packageInfoResponse.RecommendedBidderList?.push(item);
        let temparray = [];
        temparray = [...this.recommended_bidder_list, item.name];
        this.recommended_bidder_list = temparray;

        this.getBidderInfo(item.name);
      }
    }
    if (dupFlag) this.toastr.warning('Duplicate values will not be saved.');
    this.new_bidder = '';
  }

  shop_audit_required: string = 'yes';
  bidderInfo: any = [];
  getBidderInfo(value: any) {
    if (value == undefined) {
      if (this.packageInfoResponse.RecommendedBidderList.length == 0) {
        this.bidderInfo = [];
      } else {
        for (const element of this.packageInfoResponse.RecommendedBidderList) {
          if (
            this.categoryBidder.filter((item: any) => {
              return item.CategoryBuyerBidder == element;
            }).length == 0
          )
            this.categoryBidder.push({
              CategoryBuyerBidder: element,
              contract_status: this.contract_status,
            });
        }
      }
    } else {
      let dupId = this.categoryBidder.filter((item: any) => {
        return item.CategoryBuyerBidder == value;
      }).length;
      if (dupId == 0) {
        let obj = {
          CategoryBuyerBidder: value,
          contract_status: this.contract_status,
        };
        this.categoryBidder.push(obj);
      } else {
        let idIndex = this.categoryBidder.findIndex((obj: any) => {
          return obj.CategoryBuyerBidder == value;
        });
        this.categoryBidder.splice(idIndex, 1);
      }
    }
  }

  save(form: NgForm) {
    let obj = {
      CategoryBuyerInfo: {
        Id: this.packageInfoResponse.Id,
        Category: parseInt(form.value.category?.code),
        PackageId: parseInt(this.packageInfoDetails.packageId),
        ApproveSuggestedStrategy:
          form.value.approve_suggested_strategy == 'true' ? true : false,
        SuggestedStrategy: parseInt(form.value.strategy?.Id),
        SsjStatus: form.value.ssj_status,
        ContractRequirement: parseInt(form.value.contract?.Id),
        Intercoms: form.value.intercoms,
        CreatedBy: this.authenticationService.userObj.userId,
        other_Contract_Requirements:
          form.value.other_Contract_Requirements == null
            ? ''
            : form.value.other_Contract_Requirements,
        comments: form.value.comments,
      },
      CategoryBidderInfo: [{}],
    };
    obj.CategoryBidderInfo = [];

    for (let index = 0; index < this.categoryBidder.length; index++) {
      let tempObj = {
        CategoryBuyerBiddelist: this.categoryBidder[index].CategoryBuyerBidder,
        ContractStatus: this.categoryBidder[index].ContractStatsus,
        ShopLocation: form.value['shop_location_' + index],
        ShopAuditRequired:
          form.value['shop_audit_required_' + index] == 'true' ? true : false,
        SapVendorNumber: form.value['vendor_number_' + index],
        VendorNumber: form.value['sap_vendor_number_' + index],
      };
      obj.CategoryBidderInfo.push(tempObj);
    }

    this.procurementService
      .insertcategorybuyerinfodetails(obj)
      .subscribe((_data) => {
        if (!_data['IsError']) {
          this.toastr.success('Catergory Buyer Details saved successfully');
        }
      });
  }
}
