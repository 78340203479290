<nav
  id="header_component"
  class="navbar navbar-expand-lg navbar-light bg-light navbar-static-top"
>
  <a class="navbar-brand" href="#">
    <div class="header-logo" style="display: inline-flex">
      <div>
        <img
          class="header-Logo-Basf"
          src="../../assets/images/BASFo_wh100tr.png"
          alt="BASF logo"
          loading="lazy"
        />
      </div>
      <div>
        <div style="display: inline-flex">
          <p class="header-Logo-Dash">|</p>
          <img
            class="header-Logo-Hand"
            src="../../assets/images/sales.png"
            alt=""
            loading="lazy"
          />
          <p class="header-logo-text">
            Procurement
            <br />
            Plan Workflow
          </p>
        </div>
      </div>
    </div>
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse header-navbar" id="navbarNav">
    <ul class="navbar-nav ms-auto header-navbar-ul">
      <li class="nav-item">
        <a
          id="mobile-not-home-image"
          [routerLink]="['/home']"
          class="nav-link"
          routerLinkActive="active-img"
          [routerLinkActiveOptions]="{ exact: true }"
          #rla="routerLinkActive"
          ><img
            class="rounded"
            src="{{
              rla.isActive
                ? '../../assets/images/home_white_48dp.png'
                : '../../assets/images/home_white_-1.png'
            }}"
            alt="home icon" /></a
        ><a
          style="font-size: 12px; padding: 8px 0px 8px 0px"
          id="mobile-home-image"
          [routerLink]="['/home']"
          class="nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          >Home</a
        >
      </li>

      <li class="nav-item" *ngFor="let menuItem of menuItems">
        <a
          [routerLink]="[menuItem.route]"
          class="nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="menuItem.IsView"
          >{{ menuItem.MenuName }}</a
        >
      </li>
      <!--<li
        class="nav-item"
        *ngIf="
          this.authenticationService.userObj.userId != null ||
          this.authenticationService.userObj.userId != 0
        "
      >
        <a
          style="font-size: 12px; padding: 8px 0px 8px 0px"
          [routerLink]="['/']"
          class="nav-link"
          >Log Out</a
        >
      </li>-->
      <li class="nav-item" style="text-align: center">
        <img
          src="../../../assets/images/Image-2.png"
          class="img-responsive"
          alt=""
          style="height: 50px"
        />
        <p style="text-align: center">
          {{ this.authenticationService.userObj.userName }}
        </p>
      </li>
    </ul>
  </div>
</nav>
