<div>
    <div *ngIf="showForm">
        <div class="row" style="align-items:center;padding: 1rem 0rem;">
            <label class="col-md-12 header-label">{{formType}} User</label>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-user-registration [formData]="selectedRow" [formType]="formType"
                    (reLoadGrid)="refreshUsers($event)">
                </app-user-registration>
            </div>
        </div>
    </div>
    <div>
        <div class="row" style="align-items:center;padding: 1rem 0rem;">
            <label class="col-md-6 header-label">Available Users</label>
            <div *ngIf="!showForm" class="col-md-6">
                <input type="button" value="Add New User" class="btn add-btn" (click)="addNewUser()" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-dynamic-grid [gridCols]="cols" [gridData]="usersData"  [showFilter]="true" [showEdit]="true"
                    (selectRow)="onSelectRow($event)">
                </app-dynamic-grid>
            </div>
        </div>
    </div>
</div>