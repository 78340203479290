<div class="row">
  <div class="col-md-12">
    <form id="userRegistrationForm" [formGroup]="userRegistrationForm">
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="align-items: inherit">
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="basfUserName"
                  >Basf User Id</label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="basfUserId"
                  style="border-color: black"
                />
              </div>
              <div
                *ngIf="
                  userRegistrationForm.get('basfUserId')?.invalid &&
                  userRegistrationForm.get('basfUserId')?.touched
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    userRegistrationForm.get('basfUserId')?.hasError('required')
                  "
                >
                  BASF User Id is required.
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="firstName"
                  >First Name</label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="firstName"
                  style="border-color: black"
                />
              </div>
              <div
                *ngIf="
                  userRegistrationForm.get('firstName')?.invalid &&
                  userRegistrationForm.get('firstName')?.touched
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    userRegistrationForm.get('firstName')?.hasError('required')
                  "
                >
                  First Name is required.
                </div>
                <div
                  *ngIf="
                    userRegistrationForm.get('firstName')?.hasError('pattern')
                  "
                >
                  First Name should not be a number
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="lastName">Last Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="lastName"
                  style="border-color: black"
                />
              </div>
              <div
                *ngIf="
                  userRegistrationForm.get('lastName')?.invalid &&
                  userRegistrationForm.get('lastName')?.touched
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    userRegistrationForm.get('lastName')?.hasError('required')
                  "
                >
                  Last Name is required.
                </div>
                <div
                  *ngIf="
                    userRegistrationForm.get('lastName')?.hasError('pattern')
                  "
                >
                  Last Name should not be a number
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="email">Email</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="emailAddress"
                  style="border-color: black"
                />
              </div>
              <div
                *ngIf="
                  userRegistrationForm.get('emailAddress')?.invalid &&
                  userRegistrationForm.get('emailAddress')?.touched
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    userRegistrationForm
                      .get('emailAddress')
                      ?.hasError('required')
                  "
                >
                  Email Id is required.
                </div>
                <div
                  *ngIf="
                    userRegistrationForm.get('emailAddress')?.hasError('email')
                  "
                >
                  Email Id not valid.
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country"
                  >Select a country</label
                >
                <p-multiSelect
                  [options]="country"
                  formControlName="country"
                  optionLabel="value"
                  class="form-control-sm"
                  id="multiSelect_UserCountry"
                ></p-multiSelect>
              </div>
              <div
                *ngIf="
                  userRegistrationForm.get('country')?.invalid &&
                  userRegistrationForm.get('country')?.touched
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    userRegistrationForm.get('country')?.hasError('required')
                  "
                >
                  Country is required.
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="isAdmin">Is Admin</label>
                <input
                  type="checkbox"
                  class="form-check-input form-control-sm"
                  formControlName="isAdmin"
                  style="border-color: black; width: 12%; margin-top: 0rem"
                />
              </div>
            </div>

            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="isAdmin">Is PPM</label>
                <input
                  type="checkbox"
                  class="form-check-input form-control-sm"
                  formControlName="isPpm"
                  style="border-color: black; width: 12%; margin-top: 0rem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <div class="p-2">
          <input
            type="button"
            value="Cancel"
            class="btn cancel-btn"
            (click)="cancel()"
          />
          <input
            type="button"
            value="Submit"
            class="btn submit-btn"
            (click)="submitUser()"
            [disabled]="userRegistrationForm.invalid"
            (click)="userRegistrationForm.invalid ? toastrAlert() : ''"
          />
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
