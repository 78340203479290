<div class="container" style="padding-top: 20px; padding-bottom: 50px">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Tags</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6" style="display: flex; margin-top: 10px">
      <div class="col-md-5">
        <button
          (click)="goToManualTags()"
          class="btn btn-outline-primary btn-sm"
        >
          I want to manually add my tags
        </button>
      </div>
      <div class="col-md-5">
        <button
          (click)="goToUploadTags()"
          class="btn btn-outline-primary btn-sm"
        >
          Create and upload file for my tag
        </button>
      </div>
    </div>
  </div>
  <br />
  <a (click)="show()" style="color: blue; text-decoration: underline"
    >Click Here to view tags</a
  ><a
    [routerLink]="['/home']"
    style="color: blue; text-decoration: underline; padding-left: 20px"
    >Add Tags Later</a
  >
</div>
