<div id="purchasing-group-component">
    <form (ngSubmit)="onSave()">
        <div class="row form-field">
            <div class="col-md-6">
                <label class="float-right" for="purchasingGroup">Purchasing Group</label>
            </div>
            <div class="col-md-6">
                <input [(ngModel)]="purchasing_group" name="purchasing_group" class="form-input" pInputText />
            </div>
        </div>
        <div class="row form-field">
            <div class="col-md-6">
                <label class="float-right" for="purchasingGroupDescription">Purchasing Group Description</label>
            </div>
            <div class="col-md-6">
                <input [(ngModel)]="purchasing_group_description" name="purchasing_group_description" class="form-input" pInputText />
            </div>
        </div>
        <div class="row" style="text-align: center">
            <p-button type="submit" styleClass="primeng-button accept" label="Save"></p-button>
        </div>
    </form>
</div>