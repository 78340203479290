<div id="category_buyer" *ngIf="this.packageInfoResponse?.Id != 0">
  <div class="row">
    <form class="form" #categoryForm="ngForm">
      <div class="w-100">
        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label">WBS</label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span>{{ packageInfoResponse?.WBS }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Project Name</label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span>{{ packageInfoResponse?.ProjectName }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Name</label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span> {{ packageInfoResponse?.PackageName }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Category&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse?.CategoryName
              }}</span>

              <p-dropdown
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.category"
                name="category"
                styleClass="primeng-dropdown"
                [options]="materialGroupsArray"
                class="p-dropdown-sm"
                optionLabel="name"
                [placeholder]="
                  packageInfoResponse.category?.name || 'Select Category'
                "
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Do you approve of the suggested strategy?&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Single_Source"
                [(ngModel)]="packageInfoResponse.ApprovedSuggestedStratgy"
              />
              <div
                *ngIf="editable"
                class="form-field"
                style="
                  width: 118px;
                  justify-content: space-between;
                  padding: 0px;
                "
              >
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="packageInfoResponse.ApprovedSuggestedStratgy"
                    name="approve_suggested_strategy"
                    value="true"
                    inputId="Strategy_Y1"
                  ></p-radioButton>
                  <label for="Strategy_Y1">Yes</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="packageInfoResponse.ApprovedSuggestedStratgy"
                    name="approve_suggested_strategy"
                    value="false"
                    inputId="Strategy_N1"
                  >
                  </p-radioButton>
                  <label for="Strategy_N1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >What is your suggested strategy? &nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse?.StrategyName
              }}</span>

              <p-dropdown
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.strategy"
                name="strategy"
                styleClass="primeng-dropdown"
                [options]="suggested_strategy_List"
                class="p-dropdown-sm"
                optionLabel="Name"
                [placeholder]="'Select Strategy'"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Has the SSJ been completed?&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse?.SsjStatus
              }}</span>
              <div
                *ngIf="editable"
                class="form-field"
                style="
                  width: 118px;
                  justify-content: space-between;
                  padding: 0px;
                "
              >
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="packageInfoResponse.SsjStatus"
                    name="ssj_status"
                    value="yes"
                    inputId="ssj_status_Y"
                  ></p-radioButton>
                  <label for="ssj_status_Y">Yes</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="packageInfoResponse.SsjStatus"
                    name="ssj_status"
                    value="no"
                    inputId="ssj_status_N"
                  >
                  </p-radioButton>
                  <label for="ssj_status_N">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Contract Requirements? &nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse?.ContractName
              }}</span>

              <p-dropdown
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.contract"
                name="contract"
                styleClass="primeng-dropdown"
                [options]="contractRequirementsList"
                class="p-dropdown-sm"
                optionLabel="Name"
                [placeholder]="'Select Contract'"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="packageInfoResponse.contract?.Id == '4'">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"></label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse?.otherContractRequirements
              }}</span>
              <input
                *ngIf="editable"
                [required]="true"
                [(ngModel)]="packageInfoResponse.otherContractRequirements"
                name="other_Contract_Requirements"
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Suggested Bidder List&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span>{{
                packageInfoResponse.IsBidderList
                  ? "I don't know the bidder list."
                  : packageInfoResponse.SuggestedBidderList
              }}</span>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="editable">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Category Buyer Recommended Bidder List&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field" *ngIf="editable">
              <p-multiSelect
                [required]="true"
                name="recommended_bidder_list"
                placeholder="Select Bidders"
                [options]="this.packageInfoResponse.RecommendedBidderList"
                [(ngModel)]="recommended_bidder_list"
                (onChange)="getBidderInfo($event.itemValue)"
                styleClass="primeng-dropdown"
                optionLabel="name"
                optionValue="name"
              ></p-multiSelect>
              <span
                class="prime-Ng-Icon-Plus"
                (click)="showAddField = !showAddField"
              >
                <em class="pi pi-plus"></em>
              </span>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="editable">
          <div class="w-50"></div>
          <div class="w-50">
            <div class="form-field" *ngIf="showAddField">
              <input
                [(ngModel)]="new_bidder"
                name="new_bidder"
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
                [pKeyFilter]="blockSpace"
              />
              <span>
                <em
                  class="pi pi-check pi-checkClass"
                  (click)="addBidder()"
                ></em>
                <em
                  class="pi pi-times pi-timesClass"
                  (click)="showAddField = false"
                ></em>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Comments&nbsp;</label
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-field">
              <span *ngIf="!editable">{{ packageInfoResponse?.comments }}</span>

              <input
               [(ngModel)] = 'packageInfoResponse.comments'
                *ngIf="editable"
                name="comments"
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="w-100" style="padding-top: 30px">
            <p-accordion>
              <p-accordionTab
                header="{{ info.CategoryBuyerBidder }} Info"
                *ngFor="let info of categoryBidder; index as i"
              >
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >Contract status&nbsp;<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <span *ngIf="!editable">{{ info.ContractStatsus }}</span>
                      <p-dropdown
                        *ngIf="editable"
                        [required]="true"
                        [(ngModel)]="info.ContractStatsus"
                        name="{{ 'contract_status_' + i }}"
                        [options]="contract_status"
                        styleClass="primeng-dropdown"
                        class="p-dropdown-sm"
                        optionLabel="Name"
                        optionValue="Id"
                        [placeholder]="
                          info.ContractStatsus || 'Select Category'
                        "
                      ></p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >Shop location&nbsp;</label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <input
                        type="text"
                        class="editableInput"
                        [disabled]="!editable"
                        name="{{ 'shop_location_' + i }}"
                        [(ngModel)]="info.ShopLocation"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >Shop Audit Required?&nbsp;<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div
                      class="form-field"
                      style="width: 167px; justify-content: space-between"
                    >
                      <span *ngIf="!editable">{{
                        info.ShopAuditRequired
                      }}</span>

                      <div *ngIf="editable" class="p-field-radiobutton">
                        <p-radioButton
                          [required]="true"
                          [(ngModel)]="info.ShopAuditRequired"
                          name="{{ 'shop_audit_required_' + i }}"
                          value="true"
                        ></p-radioButton>
                        <label for="shop_audit_required">Yes</label>
                      </div>
                      <div *ngIf="editable" class="p-field-radiobutton">
                        <p-radioButton
                          [(ngModel)]="info.ShopAuditRequired"
                          name="{{ 'shop_audit_required_' + i }}"
                          value="false"
                        ></p-radioButton>
                        <label for="shop_audit_required">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >SAP Vendor Number&nbsp;</label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <input
                        type="text"
                        class="editableInput"
                        [disabled]="!editable"
                        name="{{ 'sap_vendor_number_' + i }}"
                        [(ngModel)]="info.VendorNumber"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="w-50">
                    <div class="form-label">
                      <label class="w-20 col-form-label"
                        >Vendor Contact&nbsp;</label
                      >
                    </div>
                  </div>
                  <div class="w-50">
                    <div class="form-field">
                      <input
                        type="text"
                        class="editableInput"
                        [disabled]="!editable"
                        name="{{ 'vendor_number_' + i }}"
                        [(ngModel)]="info.VendorContact"
                      />
                    </div>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>

        <div class="row button-group">
          <div class="w-100">
            <div class="flex-div">
              <p-button
                *ngIf="editable"
                type="button"
                styleClass="primeng-button accept"
                label="Save"
                (click)="save(categoryForm)"
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
