<div
  id="myPackages"
  *ngIf="showPackageGrid"
  class="container-fluid"
  style="margin-top: 30px; margin-bottom: 30px"
>
  <div class="row">
    <div class="col-md-12">
      <h4 style="font-size: 24px">My Packages</h4>
    </div>
  </div>
  <div
    class="row"
    style="padding-top: 20px; display: flex; align-content: center"
  >
    <div class="col-md-3" *ngIf="true">
      <p-dropdown
        ngModel
        [required]="true"
        name="filter"
        [options]="packageFilter"
        styleClass="primeng-dropdown"
        class="p-dropdown-sm"
        [(ngModel)]="filter"
        optionLabel="name"
        optionValue="value"
        placeholder="Select Filter"
        (onChange)="getData(filter)"
      >
      </p-dropdown>
    </div>

    <div
      class="col-md-9 buttonStyle"
      [ngClass]="{ 'offset-md-2': packageLead }"
      style="display: flex; justify-content: end; align-content: center"
    >
      <p-multiSelect
        [options]="selectedCols"
        [(ngModel)]="selectedColsMode"
        name="colsDD"
        ngDefaultControl
        (onChange)="onChangeDDValue($event)"
        optionLabel="header"
        defaultLabel="Show Items in Grid"
        [displaySelectedLabel]="false"
      ></p-multiSelect>
      <div>
        <em
          class="pi pi-file-excel excelIcon customButtonPackages"
          title="Export as Excel"
          (click)="exportAsXLSX()"
        ></em>
      </div>

      <button
        *ngIf="categoryBuyer || scheduler"
        class="customButtonPackages"
        (click)="displayPage('needInfo')"
        [disabled]="!packageButtonObj['Request for Information']"
      >
        Request for Information
      </button>
      <button
        (click)="goToCategoryBuyerInfo(categoryBuyerUrl)"
        *ngIf="scheduler || categoryBuyer"
        class="customButtonPackages"
        [disabled]="!packageButtonObj['Enter Category Buyer Info']"
      >
        Enter Category Buyer Info
      </button>
      <button
        (click)="goToPlannerInfo()"
        *ngIf="scheduler"
        class="customButtonPackages"
        [disabled]="!packageButtonObj['Enter Scheduler/Planner Info']"
      >
        Enter Scheduler/Planner Info
      </button>
      <button *ngIf="packageLead" class="customButtonPackages">
        Add a new package
      </button>
      <button
        *ngIf="false"
        class="customButtonPackages"
        (click)="verifyAndSendPackages()"
        [disabled]="
          !packageButtonObj['Send Selected packages to the next step']
        "
      >
        Send selected packages to the next step
      </button>
      <button
        class="customButtonPackages"
        (click)="approvePackages()"
        [disabled]="!packageButtonObj['Approve Packages']"
      >
        Approve Packages
      </button>
    </div>
  </div>

  <div class="row topSpacing" style="padding-top: 20px">
    <app-dynamic-grid
      [gridCols]="cols"
      [gridData]="selectedColsMode.length == 0 ? [] : packageData"
      [showFilter]="true"
      [showEdit]="true"
      [checkboxShow]="true"
      [dataKey]="dataKey"
      (sendSelectedRows)="receiveSelectedRows($event)"
      [showView]="true"
      (selectRow)="selectedInfo($event)"
    >
    </app-dynamic-grid>
  </div>
</div>
<app-rfi
  *ngIf="needInfo"
  (reLoadGrid)="refreshUsers($event)"
  [gridData]="selectedRow"
></app-rfi>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
