<div id="package_creation" class="container resetFormStyles">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Package Creation</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <form (ngSubmit)="savePackage(f)" class="form" #f="ngForm">
      <div class="w-100">
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >WBS<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ PackageDiscplineResponse.SinglePackages?.WBS }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Project Name<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{
                PackageDiscplineResponse.SinglePackages?.ProjectName
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Discipline
                <sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <p-dropdown
                [required]="true"
                ngModel
                name="package_discipline"
                styleClass="primeng-dropdown"
                [options]="packageDisciplineArray"
                class="p-dropdown-sm"
                optionLabel="Name"
                placeholder="Select Package Discipline"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Name<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="row">
              <div class="flex-div">
                <div class="w-33">
                  <div class="form-field">
                    <input
                      [required]="true"
                      ngModel
                      name="package_name"
                      type="text"
                      pInputText
                      class="p-inputtext-sm prime-ng-inputbox primeng-input"
                      placeholder=""
                      pTooltip="Enter Package name"
                      tooltipPosition="right"
                      tooltipEvent="hover"
                    />
                  </div>
                </div>
                <div class="w-33 form-field">
                  <p-checkbox
                    [(ngModel)]="package_name_checkbox"
                    name="package_name_checkbox"
                    styleClass="primeng-checkbox"
                    [binary]="true"
                  ></p-checkbox>
                </div>
                <div class="w-33 form-field">
                  <label for="binary">Schedule Critical</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Confidentiality Classification</label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <p-dropdown
              ngModel
              name="Confidentiality_class"
              styleClass="primeng-dropdown"
              class="p-dropdown-sm"
              [options]="confidentialityClassArray"
              optionLabel="name"
              optionValue="name"
              placeholder="Select Confidentiality Classification"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Equipment Type</label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <input
              ngModel
              name="equipment_type"
              type="text"
              pInputText
              class="p-inputtext-sm prime-ng-inputbox primeng-input"
              placeholder=""
              pTooltip="Enter Euipment type"
              tooltipPosition="right"
              tooltipEvent="hover"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Description<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <input
              [required]="true"
              ngModel
              name="description"
              type="text"
              pInputText
              class="p-inputtext-sm prime-ng-inputbox primeng-input"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Category<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <p-dropdown
              ngModel
              [required]="true"
              name="material_group"
              [options]="materialGroupsArray"
              (onChange)="materialGroupChange()"
              styleClass="primeng-dropdown"
              class="p-dropdown-sm"
              [(ngModel)]="material_group"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="Select Category"
            >
              <ng-template pTemplate="selectedItem">
                <div
                  class="country-item country-item-value"
                  *ngIf="material_group"
                >
                  <div>{{ material_group.name }}</div>
                </div>
              </ng-template>
              <ng-template let-category pTemplate="item">
                <div class="country-item">
                  <div>{{ category.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Category Buyer<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <span>{{ category_buyer }}</span
            ><span>(Populates per the MG table)</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Material of Construction</label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <input
              ngModel
              name="material_of_construction"
              type="text"
              pInputText
              class="p-inputtext-sm prime-ng-inputbox primeng-input"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Budget Value ($)<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="row">
            <div class="flex-div">
              <div class="w-33">
                <div class="form-field">
                  <p-inputNumber
                    [required]="budget_value_checkbox == false"
                    [(ngModel)]="budget_value"
                    name="budget_value"
                    type="text"
                    class="p-inputtext-sm prime-ng-inputbox primeng-input"
                    placeholder=""
                    [disabled]="budget_value_checkbox"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  ></p-inputNumber>
                </div>
              </div>
              <div class="w-33 form-field">
                <p-checkbox
                  [(ngModel)]="budget_value_checkbox"
                  name="budget_value_checkbox"
                  styleClass="primeng-checkbox"
                  [binary]="true"
                  [disabled]="budget_value > 0"
                ></p-checkbox>
              </div>
              <div class="w-33 form-field">
                <label for="binary">I don't know the budget yet.</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Do you want to single source this package?<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="row">
            <div class="flex-div">
              <div class="w-33 form-field">
                <div
                  pTooltip="Do you want to single source this package?"
                  class="form-field"
                  style="width: 118px; justify-content: space-between"
                >
                  <div class="p-field-radiobutton">
                    <p-radioButton
                      [required]="true"
                      [(ngModel)]="single_source"
                      name="single_source"
                      value="yes"
                      inputId="single_source"
                    ></p-radioButton>
                    <label for="single_source">Yes</label>
                  </div>
                  <div class="p-field-radiobutton">
                    <p-radioButton
                      [(ngModel)]="single_source"
                      name="single_source"
                      value="no"
                      inputId="single_source"
                    >
                    </p-radioButton>
                    <label for="single_source">No</label>
                  </div>
                </div>
              </div>
              <div
                class="w-33 form-field"
                style="padding-left: 150px !important; font-size: 10px"
              >
                <a
                  download
                  href="./assets/images/Single Source Guidance Help Text.pptx"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="single_source == 'yes'">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Reason for single source</label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="form-field">
            <input
              ngModel
              name="single_source_reason"
              type="text"
              pInputText
              class="p-inputtext-sm prime-ng-inputbox primeng-input"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Suggested Bidder List<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div class="flex-div">
            <div class="w-33">
              <div class="form-field">
                <input
                  [required]="bidder_List_checkbox ? false : true"
                  ngModel
                  name="suggested_bidder_list"
                  type="text"
                  pInputText
                  class="p-inputtext-sm prime-ng-inputbox primeng-input"
                  placeholder=""
                  [disabled]="bidder_List_checkbox"
                />
              </div>
            </div>
            <div class="w-33 form-field">
              <p-checkbox
                [(ngModel)]="bidder_List_checkbox"
                name="bidder_List_checkbox"
                styleClass="primeng-checkbox"
                [binary]="true"
              ></p-checkbox>
            </div>
            <div class="w-33 form-field">
              <label for="binary">I don't know the bidder list.</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Who will source this package?<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div
            class="form-field"
            style="width: 224px; justify-content: space-between"
          >
            <div class="p-field-radiobutton">
              <p-radioButton
                [required]="true"
                [(ngModel)]="source_package"
                name="source_package"
                value="1"
                inputId="source_package"
              ></p-radioButton>
              <label for="source_package">BASF</label>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                [(ngModel)]="source_package"
                name="source_package"
                value="2"
                inputId="source_package"
              >
              </p-radioButton>
              <label for="source_package">Engineering Partner</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-50">
          <div class="form-label">
            <label for="inputPassword" class="w-20 col-form-label"
              >Who is responsible for expediting?<sup
                ><strong><span style="color: red">*</span></strong></sup
              ></label
            >
          </div>
        </div>
        <div class="w-50">
          <div
            class="form-field"
            style="width: 312px; justify-content: space-between"
          >
            <div class="p-field-radiobutton">
              <p-radioButton
                [required]="expediting_checkbox ? false : true"
                [(ngModel)]="expediting"
                name="expediting"
                value="1"
                inputId="expediting"
                [disabled]="expediting_checkbox"
              ></p-radioButton>
              <label for="expediting">BASF</label>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                [(ngModel)]="expediting"
                name="expediting"
                value="2"
                inputId="expediting"
                [disabled]="expediting_checkbox"
              >
              </p-radioButton>
              <label for="expediting">Engineering Partner</label>
            </div>
            <div class="form-field">
              <p-checkbox
                name="expediting_checkbox"
                [(ngModel)]="expediting_checkbox"
                styleClass="primeng-checkbox"
                [binary]="true"
                inputId="binary"
                (click)="
                  expediting_checkbox ? (expediting = 0) : (expediting = 1)
                "
              ></p-checkbox>
            </div>
            <div class="form-field">
              <label for="binary">n/a</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row button-group">
        <div class="w-25">
          <div class="button-note">
            <strong><span style="color: red">*</span></strong>
            <span style="font-style: italic">Fields are mandatory</span>
          </div>
        </div>
        <div class="w-75">
          <div class="flex-div">
            <p-button
              styleClass="primeng-button cancel"
              label="Cancel"
            ></p-button>
            <p-button
              [disabled]="f.invalid == true"
              type="submit"
              (click)="buttonStatus = '1'; f.invalid ? toastrAlert() : ''"
              styleClass="primeng-button Save"
              label="Save as Draft"
            ></p-button>
            <p-button
              [disabled]="f.invalid == true"
              type="submit"
              (click)="buttonStatus = '2'; f.invalid ? toastrAlert() : ''"
              styleClass="primeng-button Save"
              label="Submit"
            ></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
