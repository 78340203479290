import { Component, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsComponent {
  comments: string = '';

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private config: DynamicDialogConfig,
    private authenticationService: AuthenticationService
  ) {}

  submit() {
    let apiRequest: any = {};
    apiRequest['ProcurementId'] = parseInt(this.config.data.id);
    apiRequest['EmailId'] =
      this.config.data.creator.PartnerprojectManager.Email;
    apiRequest['InaccurateComment'] = this.comments;
    apiRequest['CreatedBy'] = parseInt(
      this.authenticationService.userObj.userId
    );
    apiRequest['User'] =this.authenticationService.userObj.userName;
    this.spinner.show();
    this._service.updateInaccurateComments(apiRequest).subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.toastr.success('Email send successfully');
      }
    });
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
}
