import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkPackage'
})
export class CheckPackagePipe implements PipeTransform {

  transform(value: number, name: string): string {
    if (value == 1)
      return `Equipment - ${name}`
    else if (value == 2)
      return `Electrical  - ${name}`
    else if (value == 3)
      return `Instrumentation   - ${name}`
    else if (value == 4)
      return `Piping  - ${name}`
    else if (value == 5)
      return `StructuralSteel   - ${name}`
    else if (value == 6)
      return `Service  - ${name}`
    else
      return ` ${name}`
  }

}
