<div id="scheduler" *ngIf="schedulerTab">
  <div class="row">
    <form class="form" #schedulerForm="ngForm">
      <div class="w-100">
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Required Onsite Date&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeDate }}"
                class="editableInput"
                [ngClass]="{ dateDisabled: !editable }"
                name="RequiredOnsiteDate"
                [formControl]="packageInfoResponse?.RequiredOnsiteDate"
                (input)="setPlannerDates()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Estimated First Drawing Receipt ARO&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeNumber }}"
                class="editableInput"
                [disabled]="!editable"
                name="EstimatedfirstdrawingreceiptAro"
                [(ngModel)]="
                  packageInfoResponse.EstimatedfirstdrawingreceiptAro
                "
                (input)="setPlannerDates()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Estimated Basf Approval&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeNumber }}"
                class="editableInput"
                [disabled]="!editable"
                name="EstimatedbasfApproval"
                [(ngModel)]="packageInfoResponse.EstimatedbasfApproval"
                (input)="setPlannerDates()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Estimated Fabrication Time ARAD&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeNumber }}"
                class="editableInput"
                [disabled]="!editable"
                name="EstimatedfabricationtimeArad"
                [(ngModel)]="packageInfoResponse.EstimatedfabricationtimeArad"
                (input)="setPlannerDates()"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >PO need by Date&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeDate }}"
                class="editableInput"
                [ngClass]="{ dateDisabled: true }"
                name="PoneedbyDate"
                [formControl]="packageInfoResponse.PoneedbyDate"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >RFQ need by Date&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeDate }}"
                class="editableInput"
                [ngClass]="{ dateDisabled: true }"
                name="RfqneedbyDate"
                [formControl]="packageInfoResponse.RfqneedbyDate"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Planned Delivery Date &nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="{{ inputTypeDate }}"
                class="editableInput"
                [ngClass]="{ dateDisabled: !editable }"
                name="PlannedDeliveryDate"
                [formControl]="packageInfoResponse.PlannedDeliveryDate"
              />
            </div>
          </div>
        </div>
        <div class="row button-group">
          <div class="w-100">
            <div class="flex-div">
              <p-button
                [disabled]="schedulerForm.invalid == true"
                (click)="schedulerForm.invalid ? toastrAlert() : ''"
                *ngIf="editable"
                type="button"
                styleClass="primeng-button accept"
                label="Save"
                (click)="save()"
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
