import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChangeLogService } from 'src/app/services/dataServices/change-log.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
})
export class ChangeLogComponent implements OnInit {
  cols = [
    {
      header_id: 1,
      field: 'Date',
      header: 'Date',
      is_show: true,
      is_editable: false,
      serial_no: 1,
    },
    {
      header_id: 2,
      field: 'ChangedBy',
      header: 'Change By Whom',
      isHyperlink: true,
      is_show: true,
      is_editable: false,
      serial_no: 2,
    },
    {
      header_id: 3,
      field: 'PackageNumber',
      header: 'Package Number',
      is_show: true,
      is_editable: false,
      serial_no: 3,
    },
    {
      header_id: 4,
      field: 'ChangeType',
      header: 'Change Type',
      is_show: true,
      is_editable: false,
      serial_no: 4,
    },
    {
      header_id: 5,
      field: 'ChangedFieldName',
      header: 'Field Changed',
      is_show: true,
      is_editable: false,
      serial_no: 5,
    },
    {
      header_id: 6,
      field: 'OldValue',
      header: 'Was',
      is_show: true,
      is_editable: false,
      serial_no: 6,
    },
    {
      header_id: 7,
      field: 'NewValue',
      header: 'Now',
      is_show: true,
      is_editable: false,
      serial_no: 7,
    },
    {
      header_id: 8,
      field: 'ActionType',
      header: 'Action',
      is_show: true,
      is_editable: false,
      serial_no: 8,
    },
  ];
  packageData = [];
  dataKey = 'id';
  constructor(
    private _changeLogService: ChangeLogService,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this._changeLogService
      .GetPackageChangeLogByPackageId(0)
      .subscribe((_data) => {
        this.packageData = _data.response.map((item: any) => {
          return {
            ActionType: item.ActionType,
            ChangeType: item.ChangeType,
            ChangedBy: item.ChangedBy,
            ChangedFieldName: item.ChangedFieldName,
            Date: this.datePipe.transform(item.Date),
            Form_type: item.Form_type,
            Modified_By: item.Modified_By,
            NewValue: item.NewValue,
            OldValue: item.OldValue,
            PackageId: item.PackageId,
            PackageNumber: item.PackageNumber,
          };
        });
      });
  }
}
