import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-histroy-tab',
  templateUrl: './histroy-tab.component.html',
  styleUrls: ['./histroy-tab.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HistroyTabComponent implements OnInit {
  constructor(
    private _service: ProcurementService,
    private route: ActivatedRoute
  ) {}
  @Input() packageId: any;
  @Input() packageType: any;
  @Input() procurementId: any;
  packageHistoryResponse: any = [];
  packageInfoDetails: any = {};
  ngOnInit(): void {
    this.packageInfoDetails = {
      procurementId:
        this.route.snapshot.params['procurementId'] || this.procurementId,
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'] || this.packageType,
    };
    this._service
      .GetApprovelHistrory(
        this.packageInfoDetails.procurementId,
        this.packageInfoDetails.packageId
      )
      .subscribe((data: any) => {
        this.packageHistoryResponse = data.response?.reverse();
      });
  }
}
