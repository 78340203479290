import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DropDownMaser } from 'src/app/components/common/dropdown-master.data';
import { CategoryManagementServices } from 'src/app/services/categorymanagement.services';
import { PurchasingGroupServices } from 'src/app/services/purchasing-group.service';
import { UserManagementServices } from 'src/app/services/user-management.services';
import { CategoryData } from '../category.data';
import { CategoryMaster } from './category-master.data';

@Component({
  selector: 'app-category-buyer-popup',
  templateUrl: './category-buyer-popup.component.html',
  styleUrls: ['./category-buyer-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoryBuyerPopupComponent implements OnInit {
  purchasing_group: any;
  purchasing_group_description: any;
  category: any;
  buyer: any;
  buyer_email: any;
  selectedRow: any = [];
  categoryDD: any = [];
  purchasingGroupsDD: any = [];
  usersDD: any = [];
  loginName: any = '';
  data: CategoryData = new CategoryData();
  purchasing_group_category_id: string = '0';
  constructor(
    private service: CategoryManagementServices,
    private toastr: ToastrService,
    public config: DynamicDialogConfig,
    public purchasinggroupService: PurchasingGroupServices,
    public userservice: UserManagementServices
  ) {
    this.purchasing_group_category_id = JSON.stringify(config.data.categoryId);
  }

  ngOnInit(): void {
    this.loginName = localStorage.getItem('loginId');
    this.retrieveCategoryBuyerMaster();
    if (this.purchasing_group_category_id != '0') {
      this.retrieveCategoryBuyer();
    } else {
      this.data = new CategoryData();
    }
  }
  retrieveCategoryBuyer() {
    this.service
      .RetrieveCategory(
        this.loginName,
        Number(this.purchasing_group_category_id)
      )
      .subscribe({
        next: (cateroryBuyerResponse) => {
          let _cateroryBuyerResponse: any = cateroryBuyerResponse;
          this.data = _cateroryBuyerResponse;
          this.category = new DropDownMaser();
          this.category.MasterId =
            _cateroryBuyerResponse.PurchasingGroupCategoryId;
          this.category.MasterData = _cateroryBuyerResponse.CategoryName;
          this.retrieveCategoryMaster(
            _cateroryBuyerResponse.PurchasingGroupId,
            this.category
          );
          this.purchasing_group = new DropDownMaser();
          this.purchasing_group.MasterId =
            _cateroryBuyerResponse.PurchasingGroupId;
          this.purchasing_group.MasterData =
            _cateroryBuyerResponse.PurchasingGroup;
          this.purchasing_group_description =
            _cateroryBuyerResponse.PurchasingGroupDescription;
          this.buyer = new DropDownMaser();
          this.buyer.MasterId = _cateroryBuyerResponse.BuyerId;
          this.buyer.MasterData = _cateroryBuyerResponse.Buyer;
          this.buyer_email = _cateroryBuyerResponse.Email;
        },
      });
  }
  retrieveCategoryBuyerMaster() {
    this.service.RetrieveCategoryMaster(this.loginName).subscribe({
      next: (masterResponse) => {
        let masterData: any = masterResponse;
        let _masterData: CategoryMaster = masterData;
        this.purchasingGroupsDD = _masterData.PurchasingGroupCollection;
        this.usersDD = _masterData.BuyerCollection;
      },
    });
  }
  retrieveCategoryMaster(groupId: number, categortId: DropDownMaser) {
    this.service
      .RetrieveCategoryMasterForPurchasingGroup(this.loginName, groupId)
      .subscribe({
        next: (groupCategoryResponse) => {
          let purchasingGroupCategoryResponse: any = groupCategoryResponse;
          this.categoryDD = purchasingGroupCategoryResponse;
          this.category = categortId;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
  updateDescription() {
    if (this.purchasing_group == null || this.purchasing_group == undefined) {
      this.purchasing_group_description = '';
      this.categoryDD = [];
      this.category = 0;
    } else {
      this.retrieveCategoryMaster(
        this.purchasing_group.MasterId,
        new DropDownMaser()
      );
      this.purchasinggroupService
        .retrieveGroupServices(this.purchasing_group.MasterId, this.loginName)
        .subscribe({
          next: (groupResponse) => {
            let purchasingGroupResponse: any = groupResponse;
            this.purchasing_group_description =
              purchasingGroupResponse.PurchasingGroupDescription;
          },
          error: (error) => {
            this.toastr.error(error);
          },
        });
    }
    // this.purchasinggroupService.retrieveGroupServices())
  }

  updateUser() {
    if (this.buyer == null || this.buyer == undefined) {
      this.buyer_email = '';
    } else {
      this.userservice
        .retrieveUserByLoginName(this.buyer.MasterId, this.loginName)
        .subscribe({
          next: (userResponse) => {
            let userResponseresult: any = userResponse;
            this.buyer_email = userResponseresult.Email;
          },
          error: (error) => {
            this.toastr.error(error);
          },
        });
    }
  }

  onSave() {
    this.data.PurchasingGroupId = this.purchasing_group.MasterId;
    this.data.PurchasingGroup = this.purchasing_group.MasterData;
    this.data.PurchasingGroupDescription = this.purchasing_group_description;
    this.data.PurchasingGroupCategoryId = this.category.MasterId;
    this.data.CategoryName = this.category.MasterData;
    this.data.BuyerId = this.buyer.MasterId;
    this.data.Buyer = this.buyer.MasterData;
    this.data.Email = this.buyer_email;
    this.data.IsActive = true;
    this.data.ModifiedBy = this.loginName;

    this.service.SaveCategory(this.data).subscribe({
      next: (_response: any) => {
        this.toastr.success('Record created Successfully');
      },
      error: (error) => {
        this.toastr.error(error.error);
      },
    });
  }
}
