<div class="container-fluid home-bg">
  <div class="container">
    <div class="row">
      <div class="w-100">
        <div class="home-bg-text">
          <span style="font-size: 20px; padding-left: 5px">Welcome to</span>
          <br /><span style="font-size: 50px">Procurement</span> <br /><span
            style="font-size: 50px"
            >Plan Workflow</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="w-100">
        <div
          class="btn-group shadow-lg-custom rounded bg-white home-button-grp"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            style="color: #373737"
            class="btn btn-secondary btn-group-custom"
            [routerLink]="['/createprocurement']"
            [disabled]="!homePageLinks['Create New Procurement Plan']"
          >
            Create New<br />
            Procurement Plan
          </button>
          <hr />
          <span
            style="
              color: #373737;
              font-size: 54px;
              font-weight: 100;
              padding-top: 14px;
            "
            >|</span
          >
          <button
            type="button"
            style="color: #373737"
            class="btn btn-secondary btn-group-custom"
            [disabled]="!homePageLinks['View Dashboards']"
          >
            View Dashboards
          </button>
          <hr />
          <span
            style="
              color: #373737;
              font-size: 54px;
              font-weight: 100;
              padding-top: 14px;
            "
            >|</span
          >
          <button
            type="button"
            style="color: #373737"
            class="btn btn-secondary btn-group-custom"
            [routerLink]="['/packages']"
            [disabled]="!homePageLinks['View and Edit Package']"
          >
            View & Edit<br />
            My Packages
          </button>
          <hr />
          <span
            style="
              color: #373737;
              font-size: 54px;
              font-weight: 100;
              padding-top: 14px;
            "
            >|</span
          >
          <button
            type="button"
            style="color: #373737"
            class="btn btn-secondary btn-group-custom"
            [routerLink]="['/procurementgrid']"
            [disabled]="!homePageLinks['View and Edit Existing Procuremnt']"
          >
            View & Edit An Existing<br />
            Procurement Plan
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
