<div id="package_detail">
  <div class="row">
    <form class="form" #packageUpdateForm="ngForm">
      <div class="w-100">
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Status&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ packageInfoResponse?.Status }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >WBS&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ packageInfoResponse?.WBS }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Project Name<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ packageInfoResponse?.ProjectName }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Discipline &nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Package_Discipline"
                [(ngModel)]="packageInfoResponse.PackageDiscipline"
                [required]="true"
              />
              <p-dropdown
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.Discipline"
                name="Package_Discipline"
                styleClass="primeng-dropdown"
                [options]="packageDisciplineArray"
                class="p-dropdown-sm"
                optionLabel="Name"
                [required]="true"
                [placeholder]="
                  packageInfoResponse.PackageDiscipline || 'Select Placeholder'
                "
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Name&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Package_Name"
                [(ngModel)]="packageInfoResponse.PackageName"
                [required]="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Confidentiality Classification&nbsp;&nbsp;</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="confidentialy_clasification"
                [(ngModel)]="packageInfoResponse.ConfidentialyClasification"
              />
              <p-dropdown
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.ConfidentialyClasification"
                name="confidentialy_clasification"
                styleClass="primeng-dropdown"
                [options]="confidentialityClassArray"
                class="p-dropdown-sm"
                optionLabel="Name"
                [placeholder]="
                  packageInfoResponse.ConfidentialyClasification ||
                  'Select Clasification'
                "
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Equipment Type&nbsp;&nbsp;</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="equipment_type"
                [(ngModel)]="packageInfoResponse.EquipmentType"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Description&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="description"
                [(ngModel)]="packageInfoResponse.Description"
                [required]="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Category&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Category_Name"
                [(ngModel)]="packageInfoResponse.CategoryName"
              />
              <p-dropdown
                *ngIf="editable"
                ngModel
                [required]="true"
                name="category"
                [options]="materialGroupsArray"
                (onChange)="materialGroupChange()"
                styleClass="primeng-dropdown"
                class="p-dropdown-sm"
                [(ngModel)]="packageInfoResponse.Category"
                optionLabel="Name"
                [filter]="true"
                filterBy="Name"
                [showClear]="true"
                [placeholder]="
                  packageInfoResponse.Category?.Name
                    ? packageInfoResponse.Category.Name
                    : 'Select Category'
                "
              >
                <ng-template pTemplate="selectedItem">
                  <div
                    class="country-item country-item-value"
                    *ngIf="packageInfoResponse.Category"
                  >
                    <div>{{ packageInfoResponse.Category.Name }}</div>
                  </div>
                </ng-template>
                <ng-template let-category pTemplate="item">
                  <div class="country-item">
                    <div>{{ category.Name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Category Buyer&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ packageInfoResponse.CategoryBuyer }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Material of Construction&nbsp;</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Material_Construction"
                [(ngModel)]="packageInfoResponse.MaterialConstruction"
                [required]="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Budget Value ($)&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse.IsBudget
                  ? "I don't know the budget yet."
                  : (packageInfoResponse.BudgetValue | currency)
              }}</span>
              <p-inputNumber
                [required]="budget_value_checkbox == false"
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.BudgetValue"
                name="Budget_Value"
                inputId="locale-us"
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                [disabled]="packageInfoResponse.IsBudget"
              >
              </p-inputNumber>
              <p-checkbox
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.IsBudget"
                name="budget_value_checkbox"
                styleClass="primeng-checkbox"
                [binary]="true"
                [disabled]="packageInfoResponse.BudgetValue > 0"
              ></p-checkbox>
              <label *ngIf="editable" for="binary"
                >I don't know the budget yet.</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Do you want to single source this package?&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="row">
              <div class="flex-div" style="justify-content: inherit">
                <div class="w-33 form-field">
                  <input
                    *ngIf="!editable"
                    type="text"
                    class="editableInput"
                    [disabled]="!editable"
                    name="Single_Source"
                    [(ngModel)]="packageInfoResponse.SingleSource"
                  />
                  <div
                    *ngIf="editable"
                    class="form-field"
                    style="
                      width: 118px;
                      justify-content: space-between;
                      padding: 0px;
                    "
                  >
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        [required]="true"
                        [(ngModel)]="packageInfoResponse.SingleSource"
                        name="Single_Source"
                        value="true"
                        inputId="Single_Source_Y"
                      ></p-radioButton>
                      <label for="Single_Source_Y">Yes</label>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        [(ngModel)]="packageInfoResponse.SingleSource"
                        name="Single_Source"
                        value="false"
                        inputId="Single_Source_N"
                      >
                      </p-radioButton>
                      <label for="Single_Source_N">No</label>
                    </div>
                  </div>
                </div>
                <div
                  class="w-33 form-field"
                  style="padding-left: 65px !important; font-size: 10px"
                >
                  <a
                    download
                    href="./assets/images/Single Source Guidance Help Text.pptx"
                  >
                    Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Reason for single source&nbsp;&nbsp;</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="Sourcing"
                [(ngModel)]="packageInfoResponse.ResonforSingleSoure"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Suggested Bidder List&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span *ngIf="!editable">{{
                packageInfoResponse.IsBidderList
                  ? "I don't know the bidder list."
                  : packageInfoResponse.BiddersList
              }}</span>
              <input
                *ngIf="editable"
                type="text"
                [disabled]="packageInfoResponse.IsBidderList"
                name="Bidders_List"
                [(ngModel)]="packageInfoResponse.BiddersList"
                [required]="!packageInfoResponse.IsBidderList"
              />
              <p-checkbox
                *ngIf="editable"
                [(ngModel)]="packageInfoResponse.IsBidderList"
                name="bidder_List_checkbox"
                styleClass="primeng-checkbox"
                [binary]="true"
                [disabled]="packageInfoResponse.BiddersList !== ''"
              ></p-checkbox>
              <label *ngIf="editable" for="binary"
                >I don't know the bidder list.</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Who will source this package?<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="WhoWillSourceThisPackage"
                value="{{
                  packageInfoResponse.WhoWillSourceThisPackage === 1
                    ? 'BASF'
                    : 'Engineering Partner'
                }}"
              />

              <div
                *ngIf="editable"
                class="form-field"
                style="width: 224px; justify-content: space-between"
              >
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [required]="true"
                    [(ngModel)]="packageInfoResponse.WhoWillSourceThisPackage"
                    name="WhoWillSourceThisPackage"
                    value="1"
                    inputId="WhoWillSourceThisPackage"
                  ></p-radioButton>
                  <label for="WhoWillSourceThisPackage">BASF</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="packageInfoResponse.WhoWillSourceThisPackage"
                    name="WhoWillSourceThisPackage"
                    value="2"
                    inputId="WhoWillSourceThisPackage"
                  >
                  </p-radioButton>
                  <label for="WhoWillSourceThisPackage"
                    >Engineering Partner</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Who is responsible for expediting?<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                *ngIf="!editable"
                type="text"
                class="editableInput"
                [disabled]="!editable"
                name="WhoIsResponsibleForExpediting"
                value="{{
                  packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                    ? 'n/a'
                    : packageInfoResponse.WhoIsResponsibleForExpediting === 1
                    ? 'BASF'
                    : 'Engineering Partner'
                }}"
              />

              <div
                *ngIf="editable"
                class="form-field"
                style="width: 286px; justify-content: space-between"
              >
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [disabled]="
                      packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                    "
                    [required]="
                      !packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                    "
                    [(ngModel)]="
                      packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                        ? 0
                        : packageInfoResponse.WhoIsResponsibleForExpediting
                    "
                    name="WhoIsResponsibleForExpediting"
                    value="1"
                    inputId="WhoIsResponsibleForExpediting"
                  ></p-radioButton>
                  <label for="WhoIsResponsibleForExpediting">BASF</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [disabled]="
                      packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                    "
                    [(ngModel)]="
                      packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                        ? 0
                        : packageInfoResponse.WhoIsResponsibleForExpediting
                    "
                    name="WhoIsResponsibleForExpediting"
                    value="2"
                    inputId="WhoIsResponsibleForExpediting"
                  >
                  </p-radioButton>
                  <label for="WhoIsResponsibleForExpediting"
                    >Engineering Partner</label
                  >
                  <p-checkbox
                    name="expediting_checkbox"
                    [(ngModel)]="
                      packageInfoResponse.WhoIsResponsibleForExpediting_checkbox
                    "
                    styleClass="primeng-checkbox"
                    [binary]="true"
                    inputId="binary"
                  ></p-checkbox>

                  <label for="binary">n/a</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="enableComments || comments != ''">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"
                >Comments&nbsp;<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input type="text" name="Comments" [(ngModel)]="comments" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editable">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"> View Tags</label>
            </div>
          </div>
          <div class="w-50">
            <a
              (click)="viewTags()"
              style="text-decoration: underline; color: blue; cursor: pointer"
              >Click here</a
            >
          </div>
        </div>
        <div class="row" *ngIf="editable">
          <div class="w-50">
            <div class="form-label">
              <label class="w-20 col-form-label"> Add Tags</label>
            </div>
          </div>
          <div class="w-50">
            <a
              (click)="addTags()"
              style="text-decoration: underline; color: blue; cursor: pointer"
              >Click here</a
            >
          </div>
        </div>
        <div class="row button-group">
          <div class="w-100">
            <div class="flex-div">
              <p-button
                *ngIf="editable"
                type="button"
                styleClass="primeng-button accept"
                label="Save"
                (click)="savePackage(packageUpdateForm)"
                [disabled]="packageUpdateForm.invalid == true"
              ></p-button>
              <p-button
                type="button"
                styleClass="primeng-button cancel"
                label="Reject"
                (click)="ApproveOrRejectProcurement('reject')"
                *ngIf="approvalAllowed.flag && !editable"
              ></p-button>
              <p-button
                type="button"
                styleClass="primeng-button accept"
                label="Approve"
                (click)="ApproveOrRejectProcurement('approve')"
                *ngIf="approvalAllowed.flag && !editable"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
