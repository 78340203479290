<div
  class="comments-screen"
  style="margin-bottom: 80px"
  [ngClass]="{ leftMargin: hideNewComment }"
>
  <div class="row col-md-12" *ngIf="!hideNewComment || editable">
    <div class="col-md-11">
      <span class="p-float-label">
        <textarea
          id="comments-input"
          [rows]="4"
          pInputTextarea
          [(ngModel)]="newParentComment"
          autoResize="true"
          style="padding: 10px; width: 100%"
        ></textarea>
        <label for="comments-input">Add Comments</label>
      </span>
    </div>
    <div class="col-md-1">
      <button
        pButton
        pRipple
        type="button"
        label="Post"
        class="btn btn-primary p-button-raised p-button-primary marginV"
        style="padding: 5px; width: 100%; margin: 2px"
        (click)="saveComment()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Cancel"
        class="btn p-button-raised p-button-secondary marginV"
        style="padding: 5px; width: 100%; margin: 2px"
        (click)="newParentComment = ''"
      ></button>
    </div>
  </div>
  <div>
    <div
      *ngFor="let ques of comments"
      class="row"
      [ngClass]="{ 'q-div-class': !hideNewComment }"
    >
      <div
        class="col-md-2"
        style="text-align: center; display: block; justify-content: center"
      >
        <img
          src="../../../assets/images/Image-2.png"
          class="img-responsive"
          alt=""
          style="height: 50px"
        />
        <p>{{ ques.ResponseBy }}</p>
      </div>
      <div class="col-md-10">
        <p
          class="q-p-text-align"
          style="font-size: 18px; font-weight: 500; padding-top: 10px"
        >
          {{ ques.Response }}
        </p>
      </div>
      <div class="col-md-12" style="display: flex">
        <div class="col-md-2"></div>
        <div class="row col-md-10" style="padding-bottom: 10px">
          <p
            style="
              width: fit-content;
              padding-top: 10px;
              margin: 0;
              font-size: 14px;
              font-style: italic;
            "
          >
            {{ ques.formattedDate }}
          </p>
          <button
            class="btn btn-sm q-div-btn-class"
            *ngIf="editable"
            (click)="displayReply(ques)"
          >
            <img
              src="../../../assets/images/reply_icon.png"
              alt=""
              style="margin: 5px; height: 20px"
            />Reply
          </button>
          <button
            *ngIf="ques.QueryRepliescount > 0 && ques.ResponseId != replyQuesId"
            class="btn btn-sm q-div-btn-class"
            (click)="viewAnswers(ques.ResponseId)"
          >
            <img
              src="../../../assets/images/replies_icon.png"
              alt=""
              style="margin: 5px; height: 20px"
            />{{ ques.QueryRepliescount }} - Replies
          </button>
          <button
            *ngIf="
              replyFunctionality == 'Hide' && ques.ResponseId == replyQuesId
            "
            class="btn btn-sm q-div-btn-class"
            (click)="hideReplies()"
          >
            <img
              src="../../../assets/images/replies_icon.png"
              alt=""
              style="margin: 5px; height: 20px"
            />Hide Replies
          </button>
        </div>
      </div>
      <div class="col-md-12 marginV" style="display: flex">
        <div class="col-md-2"></div>
        <div
          *ngIf="showReply && ques.ResponseId == selQuesReplyId && editable"
          class="col-md-9 col-md-offset-1"
          style="margin-bottom: 10px"
        >
          <form>
            <textarea
              class="form-control"
              name="QuesreplyText"
              [(ngModel)]="quesReplyText"
              style="margin-bottom: 10px"
            ></textarea>
            <button
              class="btn btn-info"
              (click)="cancelReply()"
              style="margin-right: 15px"
            >
              Cancel
            </button>
            <button
              class="btn btn-outline-primary"
              (click)="submitAnswer(ques)"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div *ngIf="showReplies && ques.ResponseId == replyQuesId">
        <app-comment
          [comments]="replies"
          [hideNewComment]="true"
          [deleteReplies]="deleteReplyDisp"
        >
        </app-comment>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  type="line-scale"
  bdColor="rgba(0, 0, 0, 0.8)"
  size="small"
  template="<img src='../../assets/images/spinner.gif' />"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div *ngIf="dispLoginDialog">
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
</div>
