import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PackageService } from 'src/app/services/dataServices/package.service';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';

@Component({
  selector: 'app-package-creation',
  templateUrl: './package-creation.component.html',
  styleUrls: ['./package-creation.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PackageCreationComponent implements OnInit {
  expediting_checkbox = false;
  bidder_List_checkbox = false;
  budget_value = 0;
  ProcurementPlanID = '0';
  wbs: string = '';
  project_name: string = '';
  category_buyer: string = '';
  category_name: string = '';
  material_group: any;
  buttonStatus: string = '';
  expediting = 1;
  source_package = 1;

  single_source: string = 'yes';

  package_name_checkbox: boolean = false;
  budget_value_checkbox: boolean = false;

  packageDisciplineArray: any = [];
  materialGroupArray: any = [];
  risqRatingsArray: any = [];
  areasArray: any = [];
  scopesArray: any = [];
  materialGroupsArray: any = [];
  confidentialityClassArray: any = [
    { name: 'Unclassified', code: 1 },
    { name: 'Confidential', code: 2 },
    { name: 'Strictly Confidential', code: 3 },
    { name: 'Confidential', code: 4 },
  ];

  PackageDiscplineResponse: any = {};
  MaterialGroupResponse: any = {};
  MasterDataDetailResponse: any = {};

  constructor(
    private router: Router,
    private packageService: PackageService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) {}
  ngOnInit() {
    this.ProcurementPlanID =
      this.activatedRoute.snapshot.queryParams['ProcurementId'] != undefined
        ? atob(this.activatedRoute.snapshot.queryParams['ProcurementId'])
        : '0';
    let Userid =
      localStorage.getItem('userId') != null
        ? localStorage.getItem('userId')
        : '0';
    this.packageService
      .getPackageDiscplineDetails(this.ProcurementPlanID, Userid)
      .subscribe((data) => {
        this.PackageDiscplineResponse = data.response;
        for (const element of this.PackageDiscplineResponse.PackageDisciplne) {
          this.packageDisciplineArray.push(element);
        }
      });
    this.packageService.getMasterDataDetails().subscribe((data) => {
      this.MasterDataDetailResponse = data.response;

      for (const element of this.MasterDataDetailResponse) {
        this.materialGroupsArray.push({
          name: element.CategoryName,
          code: element.Id,
        });
      }
    });
  }
  category_buyer_email = '';
  materialGroupChange() {
    this.packageService
      .getMaterialGroup(this.material_group.code)
      .subscribe((data) => {
        this.MaterialGroupResponse = data.response;
        this.category_buyer = this.MaterialGroupResponse.Buyer;
        this.category_buyer_email = this.MaterialGroupResponse.Email;
      });
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  savePackage(form: NgForm) {
    let formObject = {
      PackageId: 0,
      ProcurementplanId: this.ProcurementPlanID,
      PackageDiscipline: form.value.package_discipline,
      PackageName: form.value.package_name,
      PackageNameCheckbox: form.value.package_name_checkbox,
      ConfidentialityClass: form.value.Confidentiality_class,
      EquipmentType: form.value.equipment_type,
      Description: form.value.description,
      MaterialGroup: form.value.material_group.code,
      MaterialOfConstruction: form.value.material_of_construction,
      BudgetValue: form.value.budget_value
        ? parseInt(form.value.budget_value)
        : 0,
      BudgetValueCheckbox: form.value.budget_value_checkbox,
      SingleSource: form.value.single_source == 'yes' ? true : false,
      SingleSourceReason:
        form.value.single_source_reason == null
          ? ''
          : form.value.single_source_reason,
      SuggestedBidderlist: this.bidder_List_checkbox
        ? ''
        : form.value.suggested_bidder_list,
      CreatedBy: localStorage.getItem('userId'),
      status: this.buttonStatus,
      WhoWillSourceThisPackage: parseInt(form.value.source_package),
      WhoIsResponsibleForExpediting: this.expediting_checkbox
        ? 0
        : parseInt(form.value.expediting),
      is_bidderlist: form.value.bidder_List_checkbox,
    };

    this.packageService.savePackageDetails(formObject).subscribe({
      next: (_data) => {
        if (isNaN(_data.response)) {
          this.toastr.warning(_data.response);
        } else {
          this.toastr.success('Package Saved');
          this.sharedDataService.packageID = _data.response;
          this.goToTags();
        }
      },
    });
  }

  goToTags() {
    this.router.navigateByUrl(`/tags`);
  }
}
