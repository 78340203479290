<div class="container" style="padding-top: 20px; padding-bottom: 50px">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Upload Tags</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6" style="margin-top: 10px">
      <div class="col-md-5">
        <button
          (click)="downloadTemplate()"
          class="btn btn-outline-primary btn-sm"
        >
          Download Tag Template.xlsx
        </button>
      </div>
      <br />
      <div>
        Click
        <input
          type="file"
          style="display: none"
          #file
          (change)="fileChange($event, file)"
        />
        <a
          (click)="file.click()"
          style="color: blue; text-decoration: underline"
        >
          "Browse"</a
        >
        {{ fileName }} to attach tag template
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="display: flex; margin-top: 2%">
      <div class="col-md-6">
        <button
          (click)="onCancel()"
          class="btn btn-secondary btn-sm"
          style="float: right; margin: 1%"
        >
          Cancel
        </button>
      </div>
      <div class="col-md-6">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="float: left; margin: 1%"
          (click)="uploadFile()"
        >
          Upload My Tags
        </button>
      </div>
    </div>
  </div>
</div>
