<div class="container resetFormStyles" id="rfi">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Create RFI</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <form
        class="form"
        style="padding-bottom: 20px"
        #rfiForm="ngForm"
        (ngSubmit)="submitForm()"
      >
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label">WBS</label>
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ gridData[0]?.WBS }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Project Name</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <span>{{ gridData[0]?.ProjectName }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Name</label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <a href="#" (click)="selectedInfo()">{{
                gridData[0]?.PackageName
              }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >What is your question
                <sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                name="question_Details"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                [(ngModel)]="question"
                [required]="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Who needs to get this information
                <sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <p-multiSelect
                styleClass="primeng-dropdown"
                [options]="usersData"
                [(ngModel)]="selectedUser"
                name="toEmails"
                class="p-dropdown-sm"
                optionLabel="LoginName"
                [required]="true"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="w-50">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >When do you need this info by
                <sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="w-50">
            <div class="form-field">
              <input
                class="primeng-dropdown p-dropdown-sm"
                name="reqeired_date"
                [(ngModel)]="date"
                style="border: 1px solid #a6a6a6; width: 150px"
                type="date"
                required
                [min]="getDate()"
              />
            </div>
          </div>
        </div>
        <div class="row button-group">
          <div class="w-25">
            <div class="button-note">
              <strong><span style="color: red">*</span></strong>
              <span style="font-style: italic">Fields are mandatory</span>
            </div>
          </div>
          <div class="w-75">
            <div class="flex-div">
              <p-button
                type="button"
                styleClass="primeng-button cancel"
                label="Cancel"
                (click)="cancel()"
              >
              </p-button>
              <p-button
                type="submit"
                [disabled]="rfiForm['form'].invalid"
                (click)="rfiForm['form'].invalid ? toastrAlert() : ''"
                styleClass="primeng-button save"
                label="Send Email"
              >
              </p-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="default"
    color="#fff"
    type="line-scale"
  ></ngx-spinner>
</div>
