<div class="container" style="padding-top: 20px; padding-bottom: 50px">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Create Tags</h4>
      </div>
      <br />
      <div>
        <h4># ONE TAG PER FIELD</h4>
      </div>
      <br />
    </div>
  </div>
  <form [formGroup]="tagsForm" (ngSubmit)="onSubmit()">
    <div class="row" formArrayName="tags">
      <div *ngFor="let tag of tags.controls; let i = index">
        <div [formGroupName]="i" class="row">
          <div class="col-md-2">
            <label for="tagNumber" class="w-20 col-form-label"
              >Tag Number<sup><span style="color: red">*</span></sup></label
            >
            <input
              required="true"
              type="text"
              pInputText
              class="primeng-input-2"
              formControlName="tagNumber"
              placeholder=""
            />
          </div>
          <div class="col-md-1">
            <label for="budget" class="w-20 col-form-label"
              >Budget<sup><span style="color: red">*</span></sup></label
            >
            <input
              required="true"
              type="text"
              pInputText
              class="primeng-input-2"
              formControlName="budget"
              placeholder=""
            />
          </div>
          <div class="col-md-1">
            <label for="quantity" class="w-20 col-form-label"
              >Quantity<sup><span style="color: red">*</span></sup></label
            >
            <input
              required="true"
              type="text"
              pInputText
              class="primeng-input"
              formControlName="quantity"
              placeholder=""
            />
          </div>
          <div class="col-md-2">
            <label for="equipmentType" class="w-20 col-form-label"
              >Equipment Type<sup><span style="color: red">*</span></sup></label
            >
            <input
              required="true"
              type="text"
              pInputText
              class="primeng-input-2"
              formControlName="equipmentType"
              placeholder=""
            />
          </div>
          <div class="col-md-1">
            <label for="area" class="w-20 col-form-label"
              >Area<sup><span style="color: red">*</span></sup></label
            >
            <input
              required="true"
              type="text"
              pInputText
              class="primeng-input"
              formControlName="area"
              placeholder=""
            />
          </div>
          <div class="col-md-2">
            <label
              for="risqRating"
              class="w-20 col-form-label"
              style="line-height: 10px; padding-right: 5px"
              >Risk Register Rating<sup><span style="color: red">*</span></sup>
              <a
                style="font-size: 10px; display: inline-flex"
                download
                href="./assets/images/Risk Register Criteria Help Text.pdf"
              >
                Know more
              </a>
            </label>

            <p-dropdown
              [required]="true"
              [options]="risqRatingArray"
              formControlName="risqRating"
              [editable]="false"
              optionLabel="Name"
              optionValue="Id"
              placeholder="Select Risk Register Rating"
            ></p-dropdown>
          </div>
          <div class="col-md-2">
            <button
              (click)="addTag()"
              type="button"
              style="margin-top: 17%"
              class="btn btn-outline-primary btn-sm"
            >
              <em class="pi pi-plus"></em>
            </button>
            <button
              (click)="minusTag(i)"
              type="button"
              style="margin-top: 17%"
              class="btn btn-outline-primary btn-sm"
            >
              <em class="pi pi-minus"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="display: flex; margin-top: 2%">
        <div class="col-md-6">
          <button
            (click)="onCancel()"
            class="btn btn-secondary btn-sm"
            style="float: right; margin: 1%"
          >
            Cancel
          </button>
        </div>
        <div class="col-md-6">
          <button
            [disabled]="tagsForm.invalid == true"
            (click)="tagsForm.invalid ? toastrAlert() : ''"
            type="submit"
            class="btn btn-primary btn-sm"
            style="float: left; margin: 1%"
          >
            Save and Return to Package
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
