import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'ClientApp_2';
  userAccess = false;
  cities: string[] = [];
  selectedCity: string = '';
  constructor(public router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.userAccess = false;
        if (
          event.url == '/' ||
          event.url == '/login-test' ||
          event.url == '/login' ||
          event.url == '/logout' ||
          event.url == '/registration' ||
          event.url == '/saml' ||
          event.url == '/error'
        ) {
          this.userAccess = false;
        } else {
          this.userAccess = true;
        }
      });
  }
}
