<p-table [value]="tags" [paginator]="true" [rows]="10" [responsive]="true">
  <ng-template pTemplate="header">
    <tr>
      <th id="tagNumber">Tag Number</th>
      <th id="budget">Budget($)</th>
      <th id="quantity">Quantity</th>
      <th id="equipmentType">Equipment Type</th>
      <th id="rrr">Risk Register Rating</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tag>
    <tr>
      <td>{{ tag.TagNumber }}</td>
      <td>{{ tag.Budget }}</td>
      <td>{{ tag.Quantity }}</td>
      <td>{{ tag.EquipmentType }}</td>
      <td>{{ tag.RisqRating }}</td>
    </tr>
  </ng-template>
</p-table>
