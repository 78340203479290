<div>
    <div>
        <div class="row" style="align-items:center;padding: 1rem 0rem;" *ngIf="prdetailsflag">
            <label class="col-md-6 header-label">Package related PR Details</label>
            <div class="col-md-6">
                <input type="button" value="Enter PR Details" class="btn add-btn" (click)="addPRNumber()" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-dynamic-grid *ngIf="!editable" [gridCols]="cols" [gridData]="packageData" [showFilter]="true"
                    [checkboxShow]="true" [dataKey]="dataKey" (sendSelectedRows)="onSelectRow($event)">
                </app-dynamic-grid>
            </div>
        </div>
    </div>
</div>