<div id="procurement_component" class="container resetFormStyles">
  <div class="row">
    <div class="w-50">
      <div>
        <h4>Category Buyer</h4>
      </div>
    </div>
    <div class="w-50">
      <div class="flex-div">
        <p-button
          [routerLink]="['/packages']"
          styleClass="primeng-button header-button"
          label="Go to My Packages"
        ></p-button>
      </div>
    </div>
  </div>
  <div class="row">
    <form (ngSubmit)="savePackage(f)" class="form" #f="ngForm">
      <div class="w-100">
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label">WBS</label>
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <span>{{ wbs }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Budget Value($)</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <span>{{
                isBudget
                  ? "I don't know the budget yet."
                  : (budgetValue | currency)
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Project Name</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <span>{{ project_name }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Package Name</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <a>{{ package_name }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Category<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <p-dropdown
                ngModel
                [required]="true"
                name="category"
                [options]="categoryList"
                styleClass="primeng-dropdown"
                class="p-dropdown-sm"
                [(ngModel)]="category"
                optionLabel="CategoryName"
                [filter]="true"
                filterBy="CategoryName"
                [showClear]="true"
                placeholder="Select Category"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="country-item country-item-value" *ngIf="category">
                    <div>{{ category.CategoryName }}</div>
                  </div>
                </ng-template>
                <ng-template let-category pTemplate="item">
                  <div class="country-item">
                    <div>{{ category.CategoryName }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Do you approve of the suggested strategy?<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-8">
            <div
              class="form-field"
              style="width: 130px; justify-content: space-between"
            >
              <div class="p-field-radiobutton">
                <p-radioButton
                  [required]="true"
                  [(ngModel)]="approve_suggested_strategy"
                  name="approve_suggested_strategy"
                  value="yes"
                ></p-radioButton>
                <label for="approve_suggested_strategy">Yes</label>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  [(ngModel)]="approve_suggested_strategy"
                  name="approve_suggested_strategy"
                  value="no"
                ></p-radioButton>
                <label for="approve_suggested_strategy">No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >What is your suggested strategy?</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <p-dropdown
                [required]="true"
                ngModel
                name="suggested_strategy"
                [options]="suggested_strategy_List"
                placeholder="Select strategy"
                styleClass="primeng-dropdown"
                class="p-dropdown-sm"
                optionLabel="Name"
                optionValue="Id"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Has the SSJ been completed?<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-8">
            <div
              class="form-field"
              style="width: 226px; justify-content: space-between"
            >
              <div class="p-field-radiobutton">
                <p-radioButton
                  [required]="ssj_completed_checkbox == true ? false : true"
                  [(ngModel)]="ssj_completed"
                  name="ssj_completed"
                  value="yes"
                ></p-radioButton>
                <label for="ssj_completed">Yes</label>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  [(ngModel)]="ssj_completed"
                  name="ssj_completed"
                  value="no"
                ></p-radioButton>
                <label for="ssj_completed">No</label>
              </div>
              <div class="form-field">
                <p-checkbox
                  name="ssj_completed_checkbox"
                  [(ngModel)]="ssj_completed_checkbox"
                  styleClass="primeng-checkbox"
                  [binary]="true"
                  inputId="binary"
                ></p-checkbox>
                <label for="binary" style="margin-left: 2px">n/a</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Contract Requirements<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <p-dropdown
                [required]="true"
                [(ngModel)]="contract_requirement"
                name="contract_requirement"
                placeholder="Select Contract"
                [options]="contractRequirementsList"
                styleClass="primeng-dropdown"
                class="p-dropdown-sm"
                optionLabel="Name"
                optionValue="Id"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="contract_requirement == '4'">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"></label>
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <input
                [required]="contract_requirement == '4' ? true : false"
                ngModel
                name="other_Contract_Requirements"
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Discipline Lead suggested Bidders List</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              {{ bidder_list }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Category Buyer Recommended Bidder List<sup
                  ><strong><span style="color: red">*</span></strong></sup
                ></label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <p-multiSelect
                [required]="true"
                name="recommended_bidder_list"
                placeholder="Select Bidders"
                [options]="recommended_BidderList"
                [(ngModel)]="recommended_bidder_list"
                (onChange)="getBidderInfo($event.itemValue)"
                styleClass="primeng-dropdown"
                optionLabel="name"
                optionValue="name"
                [id]="'bidderList'"
              ></p-multiSelect>
              <span class="prime-Ng-Icon-Plus" (click)="showAddField = true">
                <em class="pi pi-plus"></em>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"></div>
          <div class="col-8">
            <div class="form-field" *ngIf="showAddField">
              <input
                [(ngModel)]="new_bidder"
                name="new_bidder"
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
                [pKeyFilter]="blockSpace"
              />
              <span>
                <em
                  class="pi pi-check pi-checkClass"
                  (click)="addBidder()"
                ></em>
                <em
                  class="pi pi-times pi-timesClass"
                  (click)="showAddField = false"
                ></em>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-label">
              <label for="inputPassword" class="w-20 col-form-label"
                >Comments</label
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-field">
              <input
                name="comments"
                ngModel
                type="text"
                pInputText
                class="p-inputtext-sm prime-ng-inputbox primeng-input"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-100">
            <p-accordion [multiple]="true">
              <p-accordionTab
                header="{{ info.name }} Info"
                *ngFor="let info of bidderInfo; index as i"
                [selected]="true"
              >
                <div class="row">
                  <div class="col-4">
                    <div class="form-label">
                      <label for="inputPassword" class="w-20 col-form-label"
                        >Contract status<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-field">
                      <p-dropdown
                        [required]="true"
                        ngModel
                        name="{{ 'contract_status_' + i }}"
                        [options]="info.contract_status"
                        styleClass="primeng-dropdown"
                        class="p-dropdown-sm"
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder="Select Contract"
                      ></p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-label">
                      <label for="inputPassword" class="w-20 col-form-label"
                        >Shop location</label
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-field">
                      <input
                        ngModel
                        name="{{ 'shop_location_' + i }}"
                        type="text"
                        pInputText
                        class="p-inputtext-sm prime-ng-inputbox primeng-input"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-label">
                      <label for="inputPassword" class="w-20 col-form-label"
                        >Shop Audit Required?<sup
                          ><strong
                            ><span style="color: red">*</span></strong
                          ></sup
                        ></label
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div
                      class="form-field"
                      style="width: 126px; justify-content: space-between"
                    >
                      <div class="p-field-radiobutton">
                        <p-radioButton
                          [required]="true"
                          [(ngModel)]="shop_audit_required[i]"
                          name="{{ 'shop_audit_required_' + i }}"
                          value="yes"
                        ></p-radioButton>
                        <label for="shop_audit_required">Yes</label>
                      </div>
                      <div class="p-field-radiobutton">
                        <p-radioButton
                          [(ngModel)]="shop_audit_required[i]"
                          name="{{ 'shop_audit_required_' + i }}"
                          value="no"
                        ></p-radioButton>
                        <label for="shop_audit_required">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-label">
                      <label for="inputPassword" class="w-20 col-form-label"
                        >SAP Vendor Number</label
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-field">
                      <input
                        ngModel
                        name="{{ 'vendor_number_' + i }}"
                        type="text"
                        pInputText
                        class="p-inputtext-sm prime-ng-inputbox primeng-input"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-label">
                      <label for="inputPassword" class="w-20 col-form-label"
                        >Vendor Contact</label
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-field">
                      <input
                        ngModel
                        name="{{ 'vendor_contact_' + i }}"
                        type="text"
                        pInputText
                        class="p-inputtext-sm prime-ng-inputbox primeng-input"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="row button-group">
          <div class="col-4"></div>
          <div class="col-8">
            <div class="flex-div">
              <p-button
                type="submit"
                styleClass="primeng-button Save"
                label="Save & Close"
                [disabled]="f.invalid == true"
                (click)="f.invalid ? toastrAlert() : ''"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
