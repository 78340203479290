import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-enter-pr-number',
  templateUrl: './enter-pr-number.component.html',
  styleUrls: ['./enter-pr-number.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EnterPrNumberComponent implements OnInit {
  prDetails!: FormArray;
  prDetailsForm!: FormGroup;
  fb: FormBuilder = new FormBuilder();
  noDetails: boolean = false;
  selectedRow: any = [];
  showDetails = false;
  constructor(
    private _service: ProcurementService,

    private toastr: ToastrService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.selectedRow = this.config.data.selectedRow;
    this.generateForm('', '');
  }

  generateForm(value: string, value2: string) {
    this.prDetailsForm = this.fb.group({
      details: this.fb.array([]),
      prNumber: new FormControl(value),
      liNumber: new FormControl(value2),
    });
  }

  get details() {
    return this.prDetailsForm.get('details') as FormArray;
  }

  createDetails(): FormGroup {
    return this.fb.group({
      vendorName: new FormControl('', Validators.required),

      poValue: new FormControl('', Validators.required),
      poNumber: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      id: new FormControl(0),
      isEditable: new FormControl(true),
      showUpdate: new FormControl(false),
    });
  }

  buildDetails(x: any): FormGroup {
    return this.fb.group({
      vendorName: new FormControl(
        { disabled: !x.IsEditable, value: x.VendorName },
        Validators.required
      ),
      poValue: new FormControl(
        { disabled: !x.IsEditable, value: x.POValue },
        Validators.required
      ),
      poNumber: new FormControl(
        { disabled: !x.IsEditable, value: x.PONumber },
        Validators.required
      ),
      date: new FormControl(
        {
          disabled: !x.IsEditable,
          value: formatDate(new Date(x.Date), 'yyyy-MM-dd', 'en-US'),
        },
        Validators.required
      ),
      id: new FormControl(x.ID),
      isEditable: new FormControl(x.IsEditable),
      showUpdate: new FormControl(x.IsEditable),
    });
  }

  delete(_data: any, i: number) {
    this.details.removeAt(i);
  }

  addDetails() {
    this.noDetails = false;
    this.details.push(this.createDetails());
  }

  update(value: any, number: any, liNumber: any) {
    let apiRequest: any = {};
    apiRequest['Date'] = value.date;
    apiRequest['POValue'] = value.poValue;

    apiRequest['VendorName'] = value.vendorName;
    apiRequest['PONumber'] = value.poNumber;
    apiRequest['PurchaseNumber'] = number.toString();
    apiRequest['ID'] = value.id;
    apiRequest['IsEditable'] = value.isEditable;

    this._service
      .updatePurchaseNumberDetails(apiRequest)
      .subscribe((res: any) => {
        if (!res['IsError']) {
          this.toastr.success('PR details updated successfully');
        }
      });
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  onSubmit() {
    this.details.controls?.forEach((control:any, _i:any) => {
      control.enable();
    });
    let apiRequest = [];
    let prNumber =
      this.prDetailsForm.value.prNumber.toString() +
      this.prDetailsForm.value.liNumber.toString();
    for (let data of this.prDetailsForm.value.details) {
      let dataObject: any = {};
      dataObject.PurchaseNumber = prNumber;
      dataObject.PR_Vendor_Name = data.vendorName;
      dataObject.PO_Value = data.poValue;
      dataObject.PO_Number = data.poNumber;
      dataObject.Delivery_Date = this.datePipe.transform(
        data.date,
        'yyyy-M-d h:mm:ss'
      );

      dataObject.ID = data.id;
      dataObject.IsEditable = data.isEditable;
      apiRequest.push(dataObject);
    }

    this._service
      .insertPurchaseNumberDetails(this.selectedRow[0].Id, apiRequest)
      .subscribe((res: any) => {
        if (!res['IsError']) {
          this.toastr.success('PR details added successfully');
          this.ref.close(true);
        }
      });
  }

  searchPRNumber() {
    if (
      this.prDetailsForm.value.prNumber == '' ||
      this.prDetailsForm.value.liNumber == ''
    ) {
      this.toastr.warning('Please enter required fields.');
      this.showDetails = false;
      this.noDetails = false;
      return;
    }
    this._service
      .getPurchaseDetails(
        this.prDetailsForm.value.prNumber.toString() +
          this.prDetailsForm.value.liNumber.toString(),
        this.prDetailsForm.value.liNumber
      )
      .subscribe((response: any) => {
        if (!response['IsError'] && response.response?.length) {
          this.prDetailsForm = this.fb.group({
            details: this.fb.array(
              response.response?.map((x: any) => this.buildDetails(x))
            ),
            prNumber: new FormControl(this.prDetailsForm.value.prNumber),
            liNumber: new FormControl(this.prDetailsForm.value.liNumber),
          });

          this.noDetails = false;
        } else {
          this.generateForm(
            this.prDetailsForm.value.prNumber,
            this.prDetailsForm.value.liNumber
          );
          this.noDetails = true;
        }
      });
    this.showDetails = true;
  }

  reset() {
    this.generateForm('', '');
    this.showDetails = false;
    this.noDetails = false;
  }
}
