import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkApprovalType'
})
export class CheckApprovalTypePipe implements PipeTransform {

  transform(value: number): string {
    if (value == 1)
      return 'Sequential'
    else if (value == 2)
      return 'Parallel'
    else return ''
  }

}
