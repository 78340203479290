<div id="create_procurement" class="container resetFormStyles">
  <div class="row">
    <div class="w-100">
      <div>
        <h4>Change Logs</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <app-dynamic-grid
      [gridCols]="cols"
      [gridData]="packageData"
      [showFilter]="true"
      [dataKey]="dataKey"
    >
    </app-dynamic-grid>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
