import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { UserService } from 'src/app/services/dataServices/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  menuItems: any;
  constructor(
    private userService: UserService,
    public authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.userObj.userId > 0) {
      this.userService
        .MenuDetailsByUserId(this.authenticationService.userObj.userId)
        .subscribe((_data: any) => {
          let tempMenuItems = _data.response.menuDetails;
          this.menuItems = tempMenuItems.map((item: any) => {
            let route = '';
            if (item.MenuName == 'Admin') {
              route = 'admin';
            } else if (item.MenuName == 'My Packages') {
              route = 'packages';
            } else if (item.MenuName == 'New Procurement') {
              item.MenuName = 'Create New Procurement Plan';
              route = 'createprocurement';
            }
            return { ...item, route: route };
          });
        });
    }
  }
  logout() {
    this.router.navigate(['/']);
  }
}
