import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../components/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ProcurementPlanWorkflowService {
  constructor(private _http: HttpClient, private toastr: ToastrService) {}
  getUserDetailsById(UserId: any) {
    return this._http
      .get<ApiResponse>(`${environment.getUserByEmailId}?id=${UserId}`)
      .pipe(map((response) => response.response));
  }
  displayToastr(response: ApiResponse) {
    if (
      response['authorize'] &&
      !response.IsError &&
      response['response'] != ''
    ) {
      this.toastr.success(response['response'], 'Success!');
    }
    if (
      !response['authorize'] &&
      response.IsError &&
      response['ErrorMessage'] != ''
    ) {
      this.toastr.error(response['ErrorMessage'], 'Error!');
    }
  }
}
