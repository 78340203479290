import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementServices {
  constructor(private _http: HttpClient) {}

  retrieveUserByLoginName(data: string, loginId: string) {
    let link: string = environment.retrieveUserbyLoginName
      .replace('{userName}', loginId)
      .replace('{loginId}', data);
    return this._http.get(link).pipe(map((response) => response));
  }
}
