import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { UserService } from 'src/app/services/dataServices/user.service';
import { PackageDetails } from '../models/package-details';
import { ProcurementPackages } from '../models/procurement-packages';
import { CommentsComponent } from './comments/comments.component';

@Component({
  selector: 'app-procurement-details',
  templateUrl: './procurement-details.component.html',
  styleUrls: ['./procurement-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class ProcurementDetailsComponent implements OnInit {
  labelData: any;
  formData: PackageDetails[] = [];
  approverData: any = [];
  accordionHeader: ProcurementPackages[] = [];
  procurementDetails: any;
  data: any;
  planInfoSectionObj: {
    planInfo: boolean;
    approvers: boolean;
    packages: boolean;

    comments: boolean;
  };
  closePlanButton = false;
  reopenPlanButton = false;
  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.planInfoSectionObj = {
      planInfo: true,
      approvers: true,
      packages: true,

      comments: true,
    };
  }

  ngOnInit(): void {
    this.procurementDetails = {
      procurementId: atob(this.route.snapshot.queryParams['ProcurementId']),
      popUp:
        this.route.snapshot.queryParams['Inaccurate'] == 'True' ? true : false,
      edit: this.route.snapshot.queryParams['edit'] == 'true' ? true : false,
    };
    this.generateLabelData();
    this.spinner.show();
    this.GetApprovelHistrory();

    this.getProcurementDetails();
    this.getPackageByPlanId();
    if (this.procurementDetails.edit) {
      this.RetrieveProcurementSectionDetails();
    }
  }
  GetApprovelHistrory() {
    this._service
      .GetApprovelHistrory(this.procurementDetails['procurementId'], 0)
      .subscribe((data: any) => {
        if (!data['IsError'])
          this.approverData = data['response'][0]?.GetHistories;
      });
  }
  getProcurementDetails() {
    this._service
      .getProcurementDetails(this.procurementDetails['procurementId'])
      .subscribe((data: any) => {
        this.spinner.hide();
        if (!data['IsError']) {
          this.formData = data.response;

          this.data = this.formData;
          if (this.procurementDetails['popUp']) this.goToComments();
          this.userService
            .RetrieveProcurementSectionDetails(
              this.authenticationService.userObj.userId,
              this.procurementDetails.procurementId,
              2
            )
            .subscribe((_data: any) => {
              _data.response.map((_item: any) => {
                if (
                  _item.SectionName == 'Close' &&
                  (this.data.Status == 'Created' ||
                    this.data.Status == 'ReOpen')
                ) {
                  this.closePlanButton = _item.IsEnable;
                }
                if (
                  _item.SectionName == 'Reopen' &&
                  this.data.Status == 'Closed'
                ) {
                  this.reopenPlanButton = _item.IsEnable;
                }
              });
            });
        }
      });
  }
  getPackageByPlanId() {
    this._service
      .getPackageByPlanId(this.procurementDetails['procurementId'])
      .subscribe((data: any) => {
        if (!data['IsError']) this.accordionHeader = data.response;
      });
  }
  RetrieveProcurementSectionDetails() {
    this.userService
      .RetrieveProcurementSectionDetails(
        this.authenticationService.userObj.userId,
        this.procurementDetails.procurementId,
        3
      )
      .subscribe((_data: any) => {
        _data.response.forEach((_item: any) => {
          if (_item.SectionName == 'Plan Info') {
            this.planInfoSectionObj.planInfo = _item.IsEnable;
          } else if (_item.SectionName == 'Approvers') {
            this.planInfoSectionObj.approvers = _item.IsEnable;
          } else if (_item.SectionName == 'Packages') {
            this.planInfoSectionObj.packages = _item.IsEnable;
          } else if (_item.SectionName == 'Comments') {
            this.planInfoSectionObj.comments = _item.IsEnable;
          }
        });
      });
  }
  generateLabelData() {
    this.labelData = [
      {
        header: 'Is This a Large Capital Project (>$25M)?',
        field: 'IsLargeCapital',
      },
      { header: 'Status', field: 'IsActive' },
      { header: 'Project Number', field: 'ProjectNumber' },
      { header: 'Plant Code', field: 'PlantCodeId' },
      { header: 'Project Name', field: 'ProjectName' },
      { header: 'TIC ($)', field: 'Tics' },
      { header: 'Project Manager', field: 'ProjectManager' },
      {
        header: 'Enigneering Partner Project Manager',
        field: 'PartnerprojectManager',
      },
      { header: 'Man Rep', field: 'Manrepo' },
      { header: 'PPM', field: 'Ppm' },
      { header: 'Planner/Scheduler', field: 'Planner' },

      { header: "1000's Equipment", field: 'Equipment' },
      { header: "2000's Electrical", field: 'Electrical' },
      {
        header: "3000's Process Control & Instrumentation",
        field: 'Instrumentation',
      },
      { header: "4000's Piping", field: 'Piping' },
      { header: "5000's Structural Steel", field: 'StructuralSteel' },
      { header: "6000's Services", field: 'Services' },
    ];
  }

  goToComments() {
    this.dialogService.open(CommentsComponent, {
      header: 'Comments',
      width: '60%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        id: this.procurementDetails['procurementId'],
        creator: this.data,
      },
    });
  }
  updatePlan() {
    if (
      this.data != undefined &&
      (this.data.Status == 'Closed' ||
        this.data.Status == 'ReOpen' ||
        this.data.Status == 'Created')
    ) {
      this._service
        .UpdatePlanstatus(
          this.data.Id,
          this.data.Status == 'Closed' ? 3 : 2,
          parseInt(this.authenticationService.userObj.userId)
        )
        .subscribe({
          next: () => {
            this.toastr.success('Status Updated.');
            this.closePlanButton = false;
            this.reopenPlanButton = false;
          },
        });
    } else {
      this.toastr.warning('can not continue, plan not in correct status.');
    }
  }
  convertBtoA(value: any) {
    return btoa(value);
  }
}
