import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-rfi',
  templateUrl: './rfi.component.html',
  styleUrls: ['./rfi.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RfiComponent implements OnInit {
  selectedUser: any = null;
  question: any = null;
  date: any = null;
  @Input() gridData: any = [];
  @Output() reLoadGrid = new EventEmitter();
  usersData: any;
  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {}
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnInit(): void {
    this.spinner.show();
    this._service.getUsers().subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.usersData = response.response;
      }
    });
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }

  packageType(value: string) {
    if (value == 'Equipment') return 1;
    else if (value == 'Electrical') return 2;
    else if (value == 'Instrumentation') return 3;
    else if (value == 'Piping') return 4;
    else if (value == 'StructuralSteel') return 5;
    else if (value == 'Service') return 6;
    else return 0;
  }

  selectedInfo() {
    window.open(
      `/#/packages/${this.gridData[0].ProcurementplanId}/${
        this.gridData[0].Id
      }/${this.packageType(this.gridData[0].PackageDiscipline)}`,
      '_blank'
    );
  }

  submitForm() {
    let apiRequest: any = {};
    apiRequest['WBS'] = this.gridData[0].WBS;
    apiRequest['ProjectNumber'] = this.gridData[0].WBS;
    apiRequest['ProcurementId'] = this.gridData[0].ProcurementplanId;
    apiRequest['ProjectName'] = this.gridData[0].ProjectName;
    apiRequest['ProjectId'] = this.gridData[0].WBS;
    apiRequest['PackageId'] = this.gridData[0].Id;
    apiRequest['QuestionDetails'] = this.question;
    apiRequest['ToEmails'] = this.selectedUser.map((item: any) => {
      return {
        Name: item.LoginName,
        PckgType: 0,
        Id: item.Id,
        Email: item.Email,
      };
    });
    apiRequest['RequiredDate'] = this.date;
    apiRequest['CreatedBy'] = parseInt(
      this.authenticationService.userObj.userId
    );
    apiRequest['PackageName'] = this.gridData[0].PackageName;
    apiRequest['PackageDisciplene'] = this.gridData[0].PackageDiscipline;
    apiRequest['PackageDisciplineId'] = this.gridData[0].PackageDisciplineId;
    this.spinner.show();
    this._service
      .sendEmailforMoreInformation(apiRequest)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.toastr.success('Email sent successfully');
          this.reLoadGrid.emit(true);
        }
      });
  }
  getDate() {
    return new Date().toISOString().split('T')[0];
  }
}
