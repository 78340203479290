import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-plant-code-tab',
  templateUrl: './plant-code-tab.component.html',
  styleUrls: ['./plant-code-tab.component.css'],
})
export class PlantCodeTabComponent implements OnInit {
  cols: any;
  plantCodeData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  cityName='';siteName=''

  constructor(
    private _service: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.generateHeaders();
    this.loadData(0);
  }
  generateHeaders() {
    this.cols = [
      {
        header_id: 1,
        field: 'Name',
        header: 'Plant Code Name',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },  {
        header_id: 1,
        field: 'city',
        header: 'Plant Code City',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      }, {
        header_id: 1,
        field: 'site',
        header: 'Plant Code Site',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
      {
        header_id: 1,
        field: 'Is_active',
        header: 'Is Active',
        is_show: true,
        is_editable: false,
        serial_no: 1,
      },
    ];
  }

  loadData(contractId: any) {
    this.spinner.show();
    this._service
      .RetrivePlantCodeDetailsForAdmin(contractId)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.plantCodeData = response.response;
        }
      });
  }

  onSelectRow(rowObject: any) {
    if (rowObject.type == 'status') {
      this.setPlantCodeStatus(rowObject);
    } else {
      let { rowData } = rowObject;
      this.selectedRow = JSON.parse(JSON.stringify(rowData));
      this.plantCodeName = this.selectedRow.Name;
      this.plantCodeId = this.selectedRow.Id;
      this.cityName = this.selectedRow.city;
      this.siteName = this.selectedRow.site;
      this.formType = 'Edit';
      this.showForm = true;
    }
  }
  setPlantCodeStatus(rowObject: any) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    let ApiRequest = {
      Id: this.selectedRow.Id,
     
      Name: this.selectedRow.Name,
      city:this.selectedRow.city,
      site: this.selectedRow.site,
      Created_by: this.auenticationService.userObj.userId.toString(),
      Is_active:this.selectedRow.Is_active,
    };
    this._service.EnablePlantCodeDetails(ApiRequest).subscribe((response: any) => {
      if (!response['IsError']) {
        if(response.response==0){
          this.toastr.warning('Plant Code Status Cannot Be Updated');
        }else{
          this.toastr.success('Plant Code Status Updated Successfully');
        }
        this.loadData(0);
      }
    });
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  addNewPlantCode() {
    this.selectedRow = {
      Name: '',
    };
    this.formType = 'New';
    this.showForm = true;
  }
  plantCodeName = '';
  plantCodeId = 0;
  refreshPlantCodes(eventvalue: any) {
    if (eventvalue) {
      this.loadData(0);
    }
    this.showForm = false;
  }
  submitPlantCode() {
    let ApiRequest = {};
    if (this.formType == 'New') {
      ApiRequest = {
        Id: 0,
        Name: this.plantCodeName,
        city: this.cityName,
        site: this.siteName,
        Created_by: this.auenticationService.userObj.userId.toString(),
        Is_active: true,
      };

      
  
    } else {
      let plant = this.plantCodeData.filter((item: any) => {
        return item.Id == this.plantCodeId;
      });
      ApiRequest = {
        Id: plant[0].Id,
       
        Name: this.plantCodeName,
        city: this.cityName,
        site: this.siteName,
        Created_by: this.auenticationService.userObj.userId.toString(),
        Is_active: plant[0].Is_active,
      };
    }

    this._service
      .InsertPlantCodeDetails(ApiRequest)
      .subscribe((response: any) => {
        if (!response['IsError']) {
          if(response.response==0){
            this.toastr.warning('Plant Code Cannot Be Updated');
          }else{
            this.toastr.success('Plant Code added successfully');
          }
          
          this.loadData(0);
        }
      });
    return true;
  }

  cancel() {
    this.loadData(0);
    this.showForm = false;
    this.plantCodeName = '';
    this.plantCodeId = 0;
  }
}
