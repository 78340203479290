<div id="history-tab">
  <p-fieldset
    legend="{{ 'Version ' + (packageHistoryResponse.length - i) }}"
    [toggleable]="true"
    *ngFor="let history of packageHistoryResponse; index as i"
  >
    <p-table
      [value]="history.GetHistories"
      styleClass="p-datatable-sm"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="">Approver Name</th>
          <th id="">Status</th>
          <th id="">Comments</th>
          <th id="">Updated Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.Name }}</td>
          <td>{{ product.Status }}</td>
          <td>{{ product.Comments }}</td>
          <td>{{ product.Date | date: "shortDate" }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-fieldset>
</div>
