<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>

<div>
  <div *ngIf="showForm">
    <div class="row" style="align-items: center; padding: 1rem 0rem">
      <label class="col-md-12 header-label">{{ formType }} Contract</label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <form
              id="contractRegistrationForm"
              #contractForm="ngForm"
              (ngSubmit)="submitUser()"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="align-items: inherit">
                    <div class="col-md-3 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label" for="basfUserName"
                          >Contract Name</label
                        >
                        <input
                          [required]="true"
                          name="contractName"
                          type="text"
                          class="form-control form-control-sm"
                          style="border-color: black"
                          [(ngModel)]="contractName"
                          [id]="contractId"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center">
                <div class="p-2">
                  <p-button
                    styleClass="primeng-button cancel"
                    label="Cancel"
                    (click)="cancel()"
                  ></p-button>
                  <p-button
                    style="padding-left: 20px"
                    type="submit"
                    styleClass="primeng-button accept"
                    label="Submit"
                    [disabled]="contractForm.invalid == true"
                    (click)="contractForm.invalid ? toastrAlert() : ''"
                  ></p-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row" style="align-items: center; padding: 1rem 0rem">
      <label class="col-md-6 header-label">Available Contract</label>
      <div *ngIf="!showForm" class="col-md-6">
        <input
          type="button"
          value="Add New Contract"
          class="btn add-btn"
          (click)="addNewContract()"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-dynamic-grid
          [gridCols]="cols"
          [gridData]="contractData"
          [showFilter]="true"
          [showEdit]="true"
          (selectRow)="onSelectRow($event)"
        >
        </app-dynamic-grid>
      </div>
    </div>
  </div>
</div>
