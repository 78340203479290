<div>
  <div class="row" style="align-items: center; padding: 1rem 0rem">
    <label class="col-md-6 header-label">Available Users</label>
    <div class="col-md-6">
      <input
        type="button"
        value="Add Category-Buyer"
        class="btn add-btn"
        (click)="addCategoryBuyer(0)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-dynamic-grid
        [gridCols]="cols"
        [gridData]="categoryData"
        [showFilter]="true"
        [showEdit]="true"
        (selectRow)="onSelectRow($event)"
      >
      </app-dynamic-grid>
    </div>
  </div>
</div>
