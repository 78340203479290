import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userObj: {
    userId: any;
    userName: any;
    userEmail: any;
    loginUser: any;
    UserToken: any;
    isAdmin: any;
    isPPM: any;
    jwtToken: any;
    loginuseremail: any;
    BASFUserId: any;
    FirstName: any;
    LastName: any;
    loginId: any;
  } = {
      userId: 0,
      userName: '',
      userEmail: '',
      loginUser: '',
      UserToken: '',
      isAdmin: '',
      isPPM: '',
      jwtToken: '',
      loginuseremail: '',
      BASFUserId: '',
      FirstName: '',
      LastName: '',
      loginId: ''
    };
  constructor() {
    this.setUserObject();
  }
  setUserObject() {
    this.userObj = {
      userId: localStorage.getItem('userId'),
      userName: localStorage.getItem('FirstName'),
      userEmail: localStorage.getItem('loginuseremail'),
      loginUser: localStorage.getItem('loginUser'),
      UserToken: localStorage.getItem('UserToken'),
      isAdmin: localStorage.getItem('isAdmin'),
      isPPM: localStorage.getItem('isPPM'),
      jwtToken: localStorage.getItem('jwtToken'),
      loginuseremail: localStorage.getItem('loginuseremail'),
      BASFUserId: localStorage.getItem('BASFUserId'),
      FirstName: localStorage.getItem('FirstName'),
      LastName: localStorage.getItem('LastName'),
      loginId: localStorage.getItem('loginId')
    };
  }
  resetUserObj() {
    localStorage.clear();
    this.userObj = {
      userId: 0,
      userName: '',
      userEmail: '',
      loginUser: '',
      UserToken: '',
      isAdmin: '',
      isPPM: '',
      jwtToken: '',
      loginuseremail: '',
      BASFUserId: '',
      FirstName: '',
      LastName: '',
      loginId: ''
    };
  }
}
