import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../components/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ChangeLogService {
  constructor(private _http: HttpClient) {}
  GetPackageChangeLogByPackageId(packageId: any) {
    return this._http
      .get<ApiResponse>(
        `${environment.GetPackageChangeLogByPackageId}?packageId=${packageId}`
      )
      .pipe(map((response) => response));
  }
}
