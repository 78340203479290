import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private _http: HttpClient) {}

  getPackageDiscplineDetails(
    procurementId: string,
    created_by: any
  ): Observable<any> {
    const headers = new HttpHeaders().set('PPlanId', procurementId);

    return this._http
      .get(
        `${environment.getPackageDiscplineDetails}?procurementId=${procurementId}&created_by=${created_by}`,
        { headers: headers }
      )
      .pipe(map((response) => response));
  }
  getMaterialGroup(id: string): Observable<any> {
    return this._http
      .get(`${environment.getMaterialGroup}?id=${id}`)
      .pipe(map((response) => response));
  }
  getMasterDataDetails(): Observable<any> {
    return this._http
      .get(`${environment.getMasterDataDetails}`)
      .pipe(map((response) => response));
  }

  savePackageDetails(obj: any): Observable<any> {
    const headers = new HttpHeaders().set('PPlanId', obj.ProcurementplanId.toString());
    return this._http
      .post(`${environment.savePackageDetails}`, obj,{headers: headers})
      .pipe(map((response) => response));
  }
  saveScheduler(obj: any): Observable<any> {
    const headers = new HttpHeaders().set('PackageId', obj.PackageId.toString())
    return this._http
      .post(`${environment.postScheduler}`, obj,{headers: headers})
      .pipe(map((response) => response));
  }
  getAllSchedulers(): Observable<any> {
    return this._http
      .get(`${environment.getallschedulers}`)
      .pipe(map((response) => response));
  }
  GetSchedulersByPackageId(packageId: string): Observable<any> {
    const headers = new HttpHeaders().set('PackageId', packageId.toString());
    return this._http
      .get(`${environment.GetSchedulersByPackageId}?packageID=${packageId}`, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }
  SendEmailtoApprovers(packageIdList: any, userId: number): Observable<any> {
    return this._http
      .post(`${environment.SendEmailtoApprovers}`, {
        pkagId: packageIdList,
        userId: userId,
      })
      .pipe(map((response) => response));
  }
}
