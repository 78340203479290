<div class="card mx-auto" style="width: 22rem;margin-top: 50px;">
  <div class="card-body">
    <form>
      <div class="form-group row">
        <label for="user" class="col-sm-4 col-form-label cust-label">User</label>
        <div class="col-sm-6">
          <select class="form-control" id="user" name="users" [(ngModel)]="user">
            <option value="" selected>Select User</option>
            <option *ngFor="let user of usersData" [value]="user.Id">
              {{user.FirstName}}
            </option>
          </select>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="loginbtn()">Login</button>
    </form>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>
</div>
