import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { ApprovalService } from 'src/app/services/dataServices/approval.service';
import { PackageService } from 'src/app/services/dataServices/package.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { SharedDataService } from 'src/app/services/sharedService/shared-data.service';
import { TagsGridPopupComponent } from '../tags/tags-grid-popup/tags-grid-popup.component';
import { DialogService } from 'primeng/dynamicdialog';
import { CategoryBuyerService } from 'src/app/services/dataServices/category-buyer.service';

@Component({
  selector: 'app-packageinfo',
  templateUrl: './packageinfo.component.html',
  styleUrls: ['./packageinfo.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class PackageinfoComponent implements OnInit {
  _serviceApproval: any;
  _service: any;
  route: any;
  toastr: any;
  packageService: any;
  sharedService: any;
  spinner: any;
  authenticationService: any;
  router: any;
  dialogService: any;
  categoryBuyerService: any;
  constructor(private injector: Injector) {
    this._serviceApproval = injector.get<ApprovalService>(ApprovalService);
    this._service = injector.get<ProcurementService>(ProcurementService);
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
    this.toastr = injector.get<ToastrService>(ToastrService);
    this.packageService = injector.get<PackageService>(PackageService);
    this.sharedService = injector.get<SharedDataService>(SharedDataService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.authenticationService = injector.get<AuthenticationService>(
      AuthenticationService
    );
    this.router = injector.get<Router>(Router);
    this.dialogService = injector.get<DialogService>(DialogService);
    this.categoryBuyerService =
      injector.get<CategoryBuyerService>(CategoryBuyerService);
  }

  @Input() packageId: any;
  @Input() packageType: any;
  @Input() procurementId: any;
  @Input() editable: any;

  approvalAllowedDisable = false;
  bidder_List_checkbox = false;
  comments: any = '';
  approvalAllowed = { flag: false };
  enableComments: any = false;
  packageInfoDetails: any = {};
  packageInfoResponse: any = {
    WhoIsResponsibleForExpediting: 1,
    WhoWillSourceThisPackage: 1,
    PackageName: '',
    CategoryName: '',
    Category: { Name: '', Code: '' },
    Clasification: {},
    Discipline: {},
    PackageDiscipline: '',
    SingleSource: '',
    BudgetValue: '',
    CategoryBuyer: '',
    ConfidentialyClasification: '',
    CategoryBuyerId: '',
    BiddersList: {},
    ResonforSingleSoure: '',
    MaterialConstruction: '',
    Description: '',
    EquipmentType: '',
    ProjectName: '',
    WBS: '',
    IsBudget: false,
  };
  packageHistoryResponse: any = [];
  confidentialityClassArray: any = [
    { Name: 'Unclassified', Code: 1 },
    { Name: 'Confidential', Code: 2 },
    { Name: 'Strictly Confidential', Code: 3 },
    { Name: 'Confidential', Code: 4 },
  ];
  package_name_checkbox: boolean = false;
  budget_value_checkbox: boolean = false;
  Approver: any;
  ngOnInit(): void {
    this.packageInfoDetails = {
      procurementId:
        this.route.snapshot.params['procurementId'] || this.procurementId,
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'] || this.packageType,
    };
    let observObj: any = {};
    observObj['getPackageDiscplineDetails'] = this.getPackageDiscplineDetails();
    observObj['getPackageInfo'] = this.getPackageInfo();
    observObj['GetApprovers'] = this.GetApprovers();
    observObj['getMasterDataDetails'] = this.getMasterDataDetails();
    observObj['getPackageCategoryDetails'] = this.getPackageCategoryDetails();
    this.forkCall(observObj);
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  forkCall(observObj: any) {
    forkJoin(observObj).subscribe({
      next: (_data: any) => {
        if (_data.getPackageDiscplineDetails !== undefined) {
          let response = _data.getPackageDiscplineDetails;
          this.getPackageDiscplineDetailsResponse(response);
        }
        if (_data.getMasterDataDetails !== undefined) {
          let response = _data.getMasterDataDetails;
          this.getMasterDataDetailsResponse(response);
        }
        if (_data.getPackageInfo !== undefined) {
          let response = _data.getPackageInfo;

          if (response != null) {
            this.getPackageInfoResponse(response);
          }
        }
        if (_data.GetApprovers !== undefined) {
          let response = _data.GetApprovers;

          this.GetApproversResponse(response);
        }
        if (_data.getPackageCategoryDetails !== undefined) {
          let response = _data.getPackageCategoryDetails;

          this.GetCategoryBuyerResponse(response);
        }
      },
    });
  }
  getPackageDiscplineDetailsResponse(response: any) {
    for (const element of response.response.PackageDisciplne) {
      this.packageDisciplineArray.push({
        Name: element.Name,
        Value: element.Value,
        Id: element.Id,
      });
    }
  }
  getMasterDataDetailsResponse(response: any) {
    for (const element of response.response) {
      this.materialGroupsArray.push({
        Name: element.CategoryName,
        Code: element.Id,
      });
    }
  }
  getPackageInfoResponse(response: any) {
    this.packageInfoResponse = response.response;
    this.packageInfoResponse.Category = {
      Name: this.packageInfoResponse.CategoryName,
      Code: this.packageInfoResponse.CategoryBuyerId,
    };
    this.packageInfoResponse.Clasification = {
      Name: this.packageInfoResponse.ConfidentialyClasification,
      Code: this.confidentialityClassArray.filter(
        (x: any) =>
          x.Name == this.packageInfoResponse.ConfidentialyClasification
      )[0]?.Code,
    };
    this.packageInfoResponse.Discipline = {
      Id: this.packageDisciplineArray.filter(
        (x: any) => x.Name == this.packageInfoResponse.PackageDiscipline
      )[0]?.Id,
      Name: this.packageInfoResponse.PackageDiscipline,
      Value: this.packageDisciplineArray.filter(
        (x: any) => x.Name == this.packageInfoResponse.PackageDiscipline
      )[0]?.Value,
    };

    this.packageInfoResponse.SingleSource = JSON.stringify(
      this.packageInfoResponse.SingleSource
    );
    this.packageInfoResponse.BudgetValue = this.packageInfoResponse.BudgetValue
      ? parseInt(this.packageInfoResponse.BudgetValue)
      : '';
    this.packageInfoResponse['WhoIsResponsibleForExpediting_checkbox'] =
      this.packageInfoResponse.WhoIsResponsibleForExpediting == '0'
        ? true
        : false;
  }
  GetApproversResponse(response: any) {
    if (
      response.response != undefined &&
      response.response.length != 0 &&
      this.packageInfoResponse != undefined &&
      this.packageInfoResponse.StatusId == 5
    ) {
      response.response.sort((a: any, b: any) => {
        return a.level - b.level;
      });

      this.Approver = this.getapprover(response.response);
      this.approvalAllowed.flag = false;
      let needApprovalFlag = { flag: true };

      this.getApprovalFlags(needApprovalFlag, response);
    } else {
      this.Approver = [];
      this.approvalAllowed.flag = false;
    }
  }
  ssjOrssFlag = false;
  GetCategoryBuyerResponse(response: any) {
    if (response.response != undefined && response.response.length != 0) {

      if (
        response.response.Item1.SsjStatus == 'yes' &&
        response.response.Item1.ApprovedSuggestedStratgy
      )
        this.ssjOrssFlag = true;
    }
  }
  getApprovalFlags(needApprovalFlag: any, response: any) {
    if (this.Approver.length > 0) {
      if (this.Approver[0].ApprovalType == 1) {
        if (this.checkForApprovers(this.Approver)) {
          //do nothing
        } else if (this.Approver[0].level > 1) {
          let index = response.response.findIndex((item: any) => {
            return item.Approvers == this.authenticationService.userObj.userId;
          });

          if (this.checkForPreviousApprover(response, index)) {
            needApprovalFlag.flag = false;
          }
        }
      }

      if (this.checkForApproversFlag(needApprovalFlag)) {
        this.approvalAllowed.flag = false;
      } else {
        this.approvalAllowed.flag = true;
      }
    }
  }
  getapprover(approvers: any) {
    return approvers.filter((item: any) => {
      return item.Approvers == this.authenticationService.userObj.userId;
    });
  }
  checkForApprovers(Approver: any) {
    return (
      Approver[0].level == 1 &&
      (Approver[0].Status == 0 || Approver[0].Status == 3)
    );
  }
  checkForPreviousApprover(response: any, index: any) {
    return (
      index > 0 &&
      (response.response[index - 1].Status == 0 ||
        response.response[index - 1].Status == 3)
    );
  }
  checkForApproversFlag(needApprovalFlag: any) {
    return (
      this.Approver[0].Status == 1 ||
      this.Approver[0].Status == 2 ||
      !needApprovalFlag.flag
    );
  }
  getMasterDataDetails() {
    return this.packageService.getMasterDataDetails();
  }
  getPackageCategoryDetails() {
    return this._service.RetrievePackageDetailsbyId(
      this.packageInfoDetails.packageId
    );
  }
  getPackageDiscplineDetails() {
    return this.packageService.getPackageDiscplineDetails(
      this.packageInfoDetails.procurementId,
      this.authenticationService.userObj.userId
    );
  }
  getPackageInfo() {
    return this._service.getPackageInfo(this.packageInfoDetails.packageId);
  }
  GetApprovers() {
    return this._serviceApproval.GetApprovers(
      this.packageInfoDetails.procurementId,
      this.packageInfoDetails.packageId
    );
  }
  materialGroupsArray: any = [];
  packageDisciplineArray: any = [];
  materialGroupChange() {
    this.packageService.getMaterialGroup('1').subscribe((data: any) => {
      this.packageInfoResponse.CategoryBuyer = data.response.Buyer;
    });
  }

  ApproveOrRejectProcurement(flag: string) {
    if (this.comments == '') {
      this.enableComments = true;
      this.toastr.warning('Please enter comments');
      return;
    }
    if (!this.ssjOrssFlag) {
      this.toastr.warning('SSJ Or Sugested Strategy needs to be updated.');
      return;
    }
    let obj = {
      Id: this.Approver[0].Id,
      ProcurementplanId: parseInt(this.packageInfoDetails.procurementId),
      PackageId: parseInt(this.packageInfoDetails.packageId),
      level: this.Approver[0].level,
      Approvers: parseInt(this.authenticationService.userObj.userId),
      ApprovalType: this.Approver[0].ApprovalType,
      Status: flag == 'reject' ? 2 : 1,
      Comments: this.comments,
      ModifiedBy: this.authenticationService.userObj.userId,
      IsActive: true,
      PackageType: this.packageInfoResponse.PackageDisciplineId,
      IsExternalUser: true,
    };
    this._service.ApproveOrRejectProcurement(obj).subscribe((_data: any) => {
      if (flag == 'reject') {
        this.toastr.warning('Package Rejected');
        this.approvalAllowed.flag = false;
      } else {
        this.toastr.success('Package Approved');
        this.approvalAllowed.flag = false;
      }
    });
  }

  savePackage(form: NgForm) {
    let formObject = {
      ProcurementplanId: this.packageInfoDetails.procurementId,
      PackageDiscipline: form.value.Package_Discipline,
      PackageName: form.value.Package_Name,
      PackageNameCheckbox: form.value.package_name_checkbox,
      ConfidentialityClass:
        form.value.confidentialy_clasification?.Name ||
        this.packageInfoResponse.ConfidentialyClasification,
      EquipmentType: form.value.equipment_type,
      Description: form.value.description,
      MaterialGroup:
        form.value.Category?.Code ||
        this.materialGroupsArray.filter(
          (x: any) => x.Name == this.packageInfoResponse.Category?.Name
        )[0].Code,
      MaterialOfConstruction: form.value.Material_Construction,
      BudgetValue: form.value.Budget_Value,
      BudgetValueCheckbox: form.value.budget_value_checkbox,
      SingleSource: form.value.Single_Source == 'true' ? true : false,
      SingleSourceReason:
        form.value.Sourcing == null ? '' : form.value.Sourcing,
      SuggestedBidderlist:this.packageInfoResponse.IsBidderList?'' :form.value.Bidders_List,
      CreatedBy: this.authenticationService.userObj.userId,
      Status: '2',
      PackageId: parseInt(this.packageInfoDetails.packageId),
      WhoWillSourceThisPackage: parseInt(form.value.WhoWillSourceThisPackage),
      WhoIsResponsibleForExpediting:this.packageInfoResponse.WhoIsResponsibleForExpediting_checkbox?0: parseInt(
        form.value.WhoIsResponsibleForExpediting
      ),
      is_bidderlist:form.value.bidder_List_checkbox,
    };
    this.spinner.show();
    this.packageService
      .savePackageDetails(formObject)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.toastr.success('Package saved successfully');
        }
      });
  }
  addTags() {
    this.sharedService.packageID = this.packageInfoDetails.packageId;
    this.router.navigateByUrl(`tags`);
  }
  viewTags() {
    this.sharedService.packageID = this.packageInfoDetails.packageId;
    this.dialogService.open(TagsGridPopupComponent, {
      header: 'Tags',
      width: '80%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
    });
  }
}
