import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { LoggedInUserService } from '../services/logged-in-user.service';
import { LoggedInUserData } from '../../app/components/models/LoggedInUserData';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private client: LoggedInUserService) {}
  RetrieveLoggedInUserInfo() {
    let loggedInUserDetails = new LoggedInUserData();
    let userName = localStorage.getItem('BASFUserId');

    if (
      userName != undefined &&
      userName != null &&
      userName != '' &&
      userName != ' '
    ) {
      loggedInUserDetails.LoginName = localStorage.getItem('BASFUserId');
      loggedInUserDetails.Email = localStorage.getItem('loginuseremail');
      loggedInUserDetails.FirstName = localStorage.getItem('FirstName');
      loggedInUserDetails.LastName = localStorage.getItem('LastName');
    }
    return loggedInUserDetails;
  }

  RetrieveUserInfoFromParams(
    params: any,
    loggenUserInfo: LoggedInUserData
  ): Promise<LoggedInUserData> {
    return new Promise<LoggedInUserData>((resolve, _reject) => {
      loggenUserInfo = this.RetrieveEncryptedUserInfo(loggenUserInfo, params);
      if (
        loggenUserInfo.LoginName != undefined &&
        loggenUserInfo.LoginName != ''
      ) {
        let reqData = {
          useLoginNamer_name: loggenUserInfo.LoginName,
          FirstName: loggenUserInfo.FirstName,
          LastName: loggenUserInfo.LastName,
          Email: loggenUserInfo.Email,
        };

        this.client.getDecryptedUserDetails(reqData).subscribe((response) => {
          let apiResp: any = response;

          localStorage.setItem('FirstName', apiResp.first_name);
          localStorage.setItem('LastName', apiResp.last_name);
          localStorage.setItem('loginuseremail', apiResp.email);

          loggenUserInfo.Email = apiResp.email;
          loggenUserInfo.FirstName = apiResp.first_name;
          loggenUserInfo.LastName = apiResp.last_name;
          loggenUserInfo.LoginName = apiResp.user_name;
          resolve(loggenUserInfo);
        });
      }
      if (
        loggenUserInfo.LoginName == undefined ||
        loggenUserInfo.LoginName == ''
      ) {
        loggenUserInfo = new LoggedInUserData();
        resolve(loggenUserInfo);
      }
    });
  }

  retrieveJWT(loggenUserInfo: LoggedInUserData): Observable<string> {
    let subject = new Subject<string>();
    let reqData = {
      LoginName: loggenUserInfo.LoginName,
      FirstName: loggenUserInfo.FirstName,
      LastName: loggenUserInfo.LastName,
      Email: loggenUserInfo.Email,
    };
    this.client.retrieveJwtService(reqData).subscribe({
      next: (result) => {
        let responseReult: any = result;
        subject.next(responseReult.token.toString());
      },
      error: (error) => {
        subject.error(error);
      },
    });
    return subject.asObservable();
  }
  private DecryptUserInfo(
    loggenUserInfo: LoggedInUserData,
    subject: Subject<LoggedInUserData>
  ) {
    let reqData = { username: '', firstname: '', lastname: '', email: '' };
    reqData.email = loggenUserInfo.Email ? loggenUserInfo.Email : '';
    reqData.firstname = loggenUserInfo.FirstName
      ? loggenUserInfo.FirstName
      : '';
    reqData.lastname = loggenUserInfo.LastName ? loggenUserInfo.LastName : '';
    reqData.username = loggenUserInfo.LoginName ? loggenUserInfo.LoginName : '';

    this.client.getDecryptedUserDetails(reqData).subscribe((response) => {
      let apiResp: any = response;
      localStorage.setItem('firstName', apiResp.result.firstname);
      localStorage.setItem('lastName', apiResp.result.lastname);
      localStorage.setItem('email', apiResp.result.email);

      loggenUserInfo.Email = apiResp.result.email;
      loggenUserInfo.FirstName = apiResp.result.firstname;
      loggenUserInfo.LastName = apiResp.result.lastname;
      loggenUserInfo.LoginName = apiResp.result.username;
      subject.next(loggenUserInfo);
    });
  }

  private RetrieveEncryptedUserInfo(
    loggenUserInfo: LoggedInUserData,
    params: any
  ) {
    loggenUserInfo.LoginName = params.username;

    if (
      loggenUserInfo.LoginName == undefined ||
      loggenUserInfo.LoginName == ''
    ) {
      loggenUserInfo.LoginName = params.Username;
    }
    loggenUserInfo.FirstName = params['firstname'];
    loggenUserInfo.LastName = params['lastname'];
    loggenUserInfo.Email = params['email'];

    return loggenUserInfo;
  }
}
