import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';

@Component({
  selector: 'app-procurement',
  templateUrl: './procurement.component.html',
  styleUrls: ['./procurement.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcurementComponent implements OnInit {
  wbs: string = '';
  budgetValue: string = '';
  isBudget = false;
  contract_requirement = null;
  project_name: string = '';
  category_buyer: string = '';
  category_name: string = '';
  package_name: string = '';
  bidder_list: string = '';
  recommended_BidderList: Array<{ name: string }> = [];
  suggested_strategy_List: Array<any> = [];
  contractRequirementsList: Array<any> = [];
  approve_suggested_strategy: string = 'yes';
  who_resp_exped: string = 'yes';
  ssj_completed: string = 'yes';
  shop_audit_required: any = [];
  new_bidder: string = '';
  showAddField: boolean = false;
  contract_status: any[] = [];
  recommended_bidder_list: any = [];
  ssj_completed_checkbox: boolean = false;
  packageId: any = '0';
  blockSpace: RegExp = /[^\s]/;

  city: string[] = [];
  cp: string[] = [];
  status: string[] = [];
  cities: string[] = [];
  selectedCity = '';
  categoryList = [];
  category: any;

  constructor(
    private router: Router,
    private _service: ProcurementService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnInit() {
    this.packageId =
      this.route.snapshot.queryParams['packageId'] != undefined
        ? atob(this.route.snapshot.queryParams['packageId'])
        : '0';
    this._service.RetriveMasterdataDetailsPackage().subscribe((data) => {
      this.categoryList = data.response;
      this._service
        .RetrievePackageDetailsbyId(this.packageId)
        .subscribe((data) => {
          let response = data.response;
          this.wbs = response.Item1.WBS;
          this.budgetValue = response.Item1.BudgetValue;
          this.isBudget = response.Item1.IsBudget;
          this.project_name = response.Item1.ProjectName;
          this.package_name = response.Item1.PackageName;
          this.recommended_BidderList = (
            response.Item1.RecommendedBidderList
              ? response.Item1.RecommendedBidderList
              : []
          ).map((item: any) => {
            return { name: item };
          });
          this.bidder_list = response.Item1.SuggestedBidderList;
          this.category = this.categoryList.filter(function (v: any) {
            return v.CategoryName == response.Item1.CategoryName;
          })[0];
        });
    });
    this._service.RetriveMasterdataDetails(this.packageId).subscribe((data) => {
      let response = data.response;
      this.suggested_strategy_List = response.SuggestedStrategies;
      this.contractRequirementsList = response.ContractRequirements;
      this.contract_status = response.ContractStatuses;
    });
  }

  savePackage(form: NgForm) {
    let obj = {
      CategoryBuyerInfo: {
        Id: 0,
        Category: form.value.category.Id,
        PackageId: parseInt(this.packageId),
        ApproveSuggestedStrategy:
          form.value.approve_suggested_strategy == 'yes' ? true : false,
        SuggestedStrategy: form.value.suggested_strategy,
        SsjStatus: form.value.ssj_completed,
        ContractRequirement: form.value.contract_requirement,
        Intercoms: '',
        CreatedBy: localStorage.getItem('userId'),
        other_Contract_Requirements:
          form.value.other_Contract_Requirements == null
            ? ''
            : form.value.other_Contract_Requirements,
        comments: form.value.comments,
      },
      CategoryBidderInfo: [{}],
    };
    obj.CategoryBidderInfo = [];
    for (let index = 0; index < this.bidderInfo.length; index++) {
      let tempObj = {
        CategoryBuyerBiddelist: this.bidderInfo[index].name,
        ContractStatus: form.value['contract_status_' + index],
        ShopLocation: form.value['shop_location_' + index],
        ShopAuditRequired:
          form.value['shop_audit_required_' + index] == 'yes' ? true : false,
        SapVendorNumber: form.value['vendor_number_' + index],
        VendorNumber: form.value['vendor_contact_' + index],
      };
      obj.CategoryBidderInfo.push(tempObj);
    }

    this._service.insertcategorybuyerinfodetails(obj).subscribe((_data) => {
      this.toastr.success('Data Inserted');
      this.router.navigateByUrl('/packages');
    });
  }
  addBidder() {
    let newBidderList = this.new_bidder.split(',').map((item) => {
      return { name: item };
    });
    let dupFlag = false;
    newBidderList.forEach((item, index) => {
      if (
        this.recommended_BidderList.filter((data: any) => {
          return data.name.toUpperCase() == item.name.toUpperCase();
        }).length > 0
      ) {
        dupFlag = true;
      } else {
        this.recommended_BidderList.push(item);
        let temparray = [];
        temparray = [...this.recommended_bidder_list, item.name];
        this.recommended_bidder_list = temparray;

        this.getBidderInfo(item.name);
        this.shop_audit_required[index] = 'yes';
      }
    });
    if (dupFlag) this.toastr.warning('Duplicate values will not be saved.');
    this.new_bidder = '';
  }
  bidderInfo: any = [];
  getBidderInfo(value: any) {
    if (value == undefined) {
      if (this.recommended_bidder_list.length == 0) {
        this.bidderInfo = [];
      } else {
        for (const element of this.recommended_bidder_list) {
          if (
            this.bidderInfo.filter((item: any) => {
              return item.name == element;
            }).length == 0
          )
            this.bidderInfo.push({
              name: element,
              contract_status: this.contract_status,
            });
        }
      }
    } else {
      let dupId = this.bidderInfo.filter((item: any) => {
        return item.name == value;
      }).length;
      if (dupId == 0) {
        let obj = {
          name: value,
          contract_status: this.contract_status,
        };
        this.bidderInfo.push(obj);
      } else {
        let idIndex = this.bidderInfo.findIndex((obj: any) => {
          return obj.name == value;
        });
        this.bidderInfo.splice(idIndex, 1);
      }
    }
  }
}
