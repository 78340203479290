import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { EmailLinkServiceService } from 'src/app/services/emailLinkService/email-link-service.service';
import { UserInfo } from '../../../components/common/userinfo.data';
import { AuthguardserviceService } from '../../../services/authgaurdService/authguardservice.service';
import { JWTTokenService } from '../../../services/jwtToken.services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard  {
  loginvalue: any;
  expireDate: any;
  authService: any;
  childactive = false;
  routparams: any;
  constructor(
    private _jwtToken: JWTTokenService,
    private actroute: ActivatedRoute,
    private toastr: ToastrService,
    private Authguardservice: AuthguardserviceService,
    public router: Router,
    private emailLinkService: EmailLinkServiceService,
    private authenticationService: AuthenticationService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedurl = route.data['expectedurl'];
    this.routparams = route.queryParams;

    if (this.emailLinkService.checkEmailLInkURL() == '' && route.url.length > 0 && route.url[0].path == 'emaillink') {
      return this.checkEmailLink();
    }

    if (this.routparams && this.routparams.loginUser) {
      if (!this.routparams.userid || this.routparams.userid.length < 1) {
        this.toastr.error("User not registered for this application", "Login Error");
        this.router.navigate(['/'], { queryParams: { returnUrl: expectedurl } });
        return false;
      } else {
        this.updateSession();
      }
    }

    if (!this.Authguardservice.gettoken()) {
      this.redirectToSaml(state, expectedurl);
      return false;
    } else {
      return this.ifTokenAvailable(expectedurl);
    }
  }

  updateSession() {
    localStorage.setItem('jwtToken', this.routparams['Email']);
    localStorage.setItem('userId', this.routparams['userid']);
    localStorage.setItem('loginUser', atob(this.routparams['loginUser']));
    localStorage.setItem('loginuseremail', atob(this.routparams['Email']));
    localStorage.setItem('UserToken', JSON.stringify(this.routparams));
    localStorage.setItem('isAdmin', String(this.routparams['IsAdmin']).toLowerCase());
    localStorage.setItem('isPPM', String(this.routparams['IsPPM']).toLowerCase());
    localStorage.setItem('BASFUserId', atob(this.routparams['BASFUserId']));
    localStorage.setItem('FirstName', atob(this.routparams['FirstName']));
    localStorage.setItem('LastName', atob(this.routparams['LastName']));
    localStorage.setItem('loginId', atob(this.routparams['BASFUserId']));
    this.authenticationService.setUserObject();
    let data: UserInfo = new UserInfo();
    data.LoginName = this.routparams.BASFUserId;
    data.Email = this.routparams.Email;
    data.FirstName = this.routparams.FirstName;
    data.LastName = this.routparams.LastName;
    this._jwtToken.RetrieveJWtToken(data).subscribe({
      next: (result: any) => {
        localStorage.setItem('TokenInfo', JSON.stringify(result));
        if (this.emailLinkService.checkEmailLInkURL() != '') {
          this.router.navigateByUrl(
            this.emailLinkService
              .checkEmailLInkURL()
              .split('emaillink')[1]
          );
          this.emailLinkService.resetEmailLinkURL();
        } else {
          this.router.navigate(['/home']);
        }
      },
      error: (error: any) => {
        this.router.navigate(['/login']);
        console.error(error);
      },
    });    
  }

  redirectToSaml(state: any, expectedurl: any) {
    if (this.routparams != null) {
      sessionStorage.setItem('returnUrlparams', JSON.stringify(this.routparams));
      if (JSON.stringify(this.routparams).includes('RequestId')) {
        sessionStorage.setItem('paramsExist', 'true');
      }
      sessionStorage.setItem('returnUrlpath', state.url);
    }
    else {
      sessionStorage.setItem('paramsExist', 'false');
    }
    sessionStorage.setItem('returnUrl', expectedurl);
    this.router.navigate(['/saml'], { queryParams: { returnUrl: expectedurl } });
  }

  ifTokenAvailable(expectedurl: any) {
    if (expectedurl && expectedurl.includes('admin')) {
      if (JSON.parse(localStorage.getItem('isAdmin') + '')) return true;
      else {
        this.toastr.error('Unauthorized');
        this.router.navigate(['/home']);
        return false;
      }
    } else if (expectedurl && expectedurl.includes('createprocurement')) {
      if (JSON.parse(localStorage.getItem('isPPM') + '')) return true;
      else {
        this.toastr.error('Unauthorized');
        this.router.navigate(['/home']);
        return false;
      }
    } else {
      let returnUrlpath = localStorage.getItem('returnUrlpath');
      if (returnUrlpath) {
        localStorage.removeItem('returnUrlpath');
        this.router.navigate([returnUrlpath]);
      }
      return true;
    }
  }

  checkEmailLink() {
    if (this.authenticationService.userObj.userId == null) {
      this.emailLinkService.setEmailLinkURL();
      this.router.navigate(['/']);
      return false;
    } else {
      this.emailLinkService.setEmailLinkURL();
      this.router.navigateByUrl(
        this.emailLinkService.checkEmailLInkURL().split('emaillink')[1]
      );
      return true;
    }
  }

  canActivateChild(): boolean {
    return true;
  }
}
