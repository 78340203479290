import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient) {}
  getUserInfo() {
    return {
      loginUser: localStorage.getItem('loginUser'),
      loginuseremail: localStorage.getItem('loginuseremail'),
      UserToken: localStorage.getItem('UserToken'),
      userId: localStorage.getItem('userId'),
      isAdmin: localStorage.getItem('isAdmin'),
      loginId: localStorage.getItem('loginId'),
      isPPM: localStorage.getItem('isPPM'),
    };
  }

  MenuDetailsByUserId(userId: any): Observable<any> {
    return this._http
      .get(`${environment.MenuDetailsByUserId}?Id=${userId}`)
      .pipe(map((response) => response));
  }
  RetrievePackageDetailsSection(
    userId: any,
    PackageId: any,
    PageId: any
  ): Observable<any> {
    const headers = new HttpHeaders().set('PackageId', PackageId.toString());
    return this._http
      .get(
        `${environment.RetrievePackageDetailsSection}?UserId=${userId}&PackageId=${PackageId}&PageId=${PageId}`,{headers: headers}
      )
      .pipe(map((response) => response));
  }
  RetrieveProcurementSectionDetails(
    userId: any,
    ProcurementId: any,
    PageId: any
  ): Observable<any> {
    const headers = new HttpHeaders().set('PPlanId', ProcurementId.toString());
    return this._http
      .get(
        `${environment.RetrieveProcurementSectionDetails}?UserId=${userId}&ProcurementId=${ProcurementId}&PageId=${PageId}`,{headers: headers}
      )
      .pipe(map((response) => response));
  }
}
