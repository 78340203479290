import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApprovalService } from 'src/app/services/dataServices/approval.service';
import { ProcurementService } from 'src/app/services/dataServices/procurement.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-approval-strategy',
  templateUrl: './approval-strategy.component.html',
  styleUrls: ['./approval-strategy.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ApprovalStrategyComponent implements OnInit {
  packageBudgetValue: number = 40000;
  @Input() ProcurementPlanId: any;
  products = [{ name: 'nipun', status: 'Approved', date: '16 jun 2022' }];

  PackageDiscplineResponse = {
    wbs: '',
    project_name: '',
    package_discpline: '',
  };
  single_source = 'NO';
  approversArray: Array<{ id: number }> = [{ id: 1 }, { id: 2 }, { id: 3 }]; //Array for approvers list;
  approversListArray: any = [];
  approval_type: number = 1; //Default value for approval type;

  constructor(
    private approvalService: ApprovalService,
    private _service: ProcurementService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}
  approve: any = {};
  approverData: any;
  @Input() editable: any;

  ngOnInit() {
    this.getAllUsers();

    if (this.editable) {
      this.getApproversHistory();
    }
  }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  getApproversHistory() {
    this._service
      .GetApprovelHistrory(parseInt(this.ProcurementPlanId), 0)
      .subscribe((data: any) => {
        if (!data['IsError']) {
          this.approverData = data['response'][0]?.GetHistories
            ? data['response'][0]?.GetHistories
            : [];

          if (this.approverData.length > 0) {
            this.approval_type =
              this.approverData.length > 0
                ? this.approverData[0].Approvaltype
                : 0;
            let index = 0;
            this.approversArray = [];
            for (let value of this.approverData) {
              index = index + 1;
              this.approve['approval' + index] = value.Approver;
              this.approversArray.push({ id: index });
            }
          }
        }
      });
  }
  getAllUsers() {
    this._service.getallusers().subscribe((data: any) => {
      this.approversListArray = data.response;
    });
  }
  addApprover() {
    if (this.approversArray.length < 10) {
      let approversCount = this.approversArray.length;
      this.approversArray.push({ id: approversCount + 1 });
    }
  }
  saveApproval(form: NgForm, event: any) {
    let listOfObj = [];
    for (const item of this.approversArray) {
      if (
        form.value['approval' + item.id] != null &&
        form.value['approval' + item.id] != '' &&
        form.value['approval' + item.id] != undefined
      ) {
        listOfObj.push({
          ProcurementplanId: parseInt(this.ProcurementPlanId),
          level: item.id,
          Approvers: parseInt(form.value['approval' + item.id]),
          ApprovalType: form.value.approval_type == '1' ? 1 : 2,
          ModifiedBy: localStorage.getItem('userId'),
        });
      }
    }

    this.approvalService.CreateApprovers(listOfObj).subscribe({
      next: (_data: any) => {
        if (listOfObj.length > 1) {
          this.toastr.success('Approvers Saved');
        } else {
          this.toastr.success('Approver Saved');
        }
      },
      complete: () => {
        this.confirmationService.confirm({
          target: event.target,
          message: 'Do you want to exit back to home?',
          accept: () => {
            this.router.navigateByUrl('home');
          },
          reject: () => {
            //reject action
          },
        });
      },
    });
  }
}
