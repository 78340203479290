import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { PackageService } from 'src/app/services/dataServices/package.service';

@Component({
  selector: 'app-scheduler-grid',
  templateUrl: './scheduler-grid.component.html',
  styleUrls: ['./scheduler-grid.component.css'],
})
export class SchedulerGridComponent implements OnInit {
  constructor(
    private _service: PackageService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {}
  @Input() packageId: any;
  @Input() packageType: any;
  @Input() procurementId: any;
  @Input() editable: boolean = false;
  packageInfoDetails: any = {};
  packageInfoResponse: any = {
    RequiredOnsiteDate: new FormControl(),
    PlannedDeliveryDate: new FormControl(),
    PoneedbyDate: new FormControl(),
    RfqneedbyDate: new FormControl(),
  };
  packageHistoryResponse: any = [];
  inputTypeDate = 'text';
  inputTypeNumber = 'text';
  schedulerTab = false;
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnInit(): void {
    let format = 'MM/dd/yyyy';
    if (this.editable) {
      this.inputTypeDate = 'date';
      this.inputTypeNumber = 'number';
      format = 'yyyy-MM-dd';
    }
    this.packageInfoDetails = {
      procurementId:
        this.route.snapshot.params['procurementId'] || this.procurementId,
      packageId: this.route.snapshot.params['packageId'] || this.packageId,
      pkgtypeId: this.route.snapshot.params['pkgtypeId'] || this.packageType,
    };

    this._service
      .GetSchedulersByPackageId(this.packageInfoDetails.packageId)
      .subscribe((data:any) => {
        if (data.response.length == 1) {
          this.schedulerTab = true;
          this.packageInfoResponse =
            data.response != undefined ? data.response[0] : [];

          this.packageInfoResponse.RequiredOnsiteDate = new FormControl(
            formatDate(
              this.packageInfoResponse.RequiredOnsiteDate,
              format,
              'en-US'
            )
          );
          this.packageInfoResponse.PlannedDeliveryDate = new FormControl(
            formatDate(
              this.packageInfoResponse.PlannedDeliveryDate,
              format,
              'en-US'
            )
          );
          this.packageInfoResponse.PoneedbyDate = new FormControl(
            formatDate(this.packageInfoResponse.PoneedbyDate, format, 'en-US')
          );
          this.packageInfoResponse.RfqneedbyDate = new FormControl(
            formatDate(this.packageInfoResponse.RfqneedbyDate, format, 'en-US')
          );
        } else {
          this.schedulerTab = false;
        }
      });
  }

  save() {
    let apiResponse = {
      RequiredOnsiteDate: this.packageInfoResponse.RequiredOnsiteDate.value,
      PlannedDeliveryDate: this.packageInfoResponse.PlannedDeliveryDate.value,
      PoneedbyDate: this.packageInfoResponse.PoneedbyDate.value,
      RfqneedbyDate: this.packageInfoResponse.RfqneedbyDate.value,
      EstimatedfirstdrawingreceiptAro: parseInt(
        this.packageInfoResponse.EstimatedfirstdrawingreceiptAro
      ),
      EstimatedbasfApproval: parseInt(
        this.packageInfoResponse.EstimatedbasfApproval
      ),
      EstimatedfabricationtimeArad: parseInt(
        this.packageInfoResponse.EstimatedfabricationtimeArad
      ),
      PackageId: parseInt(this.packageInfoDetails.packageId),
      Id: this.packageInfoResponse.Id,
      CreatedBy: this.packageInfoResponse.CreatedBy,
      ModifiedBy: this.authenticationService.userObj.userId,
    };
    this._service.saveScheduler(apiResponse).subscribe((response: any) => {
      if (!response['IsError']) {
        this.toastr.success('Scheduler details updated successfully');
      }
    });
  }
  setPlannerDates() {
    let tempDate = new Date(this.packageInfoResponse.RequiredOnsiteDate.value);
    let format = 'MM/dd/yyyy';
    if (this.editable) {
      this.inputTypeDate = 'date';
      this.inputTypeNumber = 'number';
      format = 'yyyy-MM-dd';
    }
    if (
      tempDate.toString() != 'Invalid Date' &&
      !Number.isNaN(
        Number(this.packageInfoResponse.EstimatedfirstdrawingreceiptAro)
      ) &&
      !Number.isNaN(Number(this.packageInfoResponse.EstimatedbasfApproval)) &&
      !Number.isNaN(
        Number(this.packageInfoResponse.EstimatedfabricationtimeArad)
      )
    ) {
      let numWeeks =
        Number(this.packageInfoResponse.EstimatedfirstdrawingreceiptAro) +
        Number(this.packageInfoResponse.EstimatedbasfApproval) +
        Number(this.packageInfoResponse.EstimatedfabricationtimeArad);

      let required_onsite_date_now = new Date(
        this.packageInfoResponse.RequiredOnsiteDate.value
      );
      required_onsite_date_now.setDate(
        required_onsite_date_now.getDate() - numWeeks * 7
      );
      this.packageInfoResponse.PoneedbyDate = new FormControl(
        formatDate(
          required_onsite_date_now.toISOString().split('T')[0],
          format,
          'en-US'
        )
      );
      

      let required_rfq_need_by_date = new Date(
        this.packageInfoResponse.PoneedbyDate.value
      );
      required_rfq_need_by_date.setDate(
        required_rfq_need_by_date.getDate() - 6 * 7
      );
      this.packageInfoResponse.RfqneedbyDate = new FormControl(
        formatDate(
          required_rfq_need_by_date.toISOString().split('T')[0],
          format,
          'en-US'
        )
      );
      
    }
  }
}
